import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(public snackBar: MatSnackBar) { }

  showNegativeBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['error-snackbar'];
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    const snackBarRef = this.snackBar.open(message, 'X', config);
    snackBarRef.onAction().subscribe(() => this.snackBar.dismiss());
  }

  showInfoBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['info-snackbar'];
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    const snackBarRef = this.snackBar.open(message, 'X', config);
    snackBarRef.onAction().subscribe(() => this.snackBar.dismiss());
  }
}
