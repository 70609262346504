import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.scss']
})
export class EditProductDialogComponent implements OnInit {

  product!: Product;
  savedProduct!: Product;
  public productLines: any[] = [];
  public form!: UntypedFormGroup;
  public existingProducts: Product[] = [];


  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditProductDialogComponent>) { }

  ngOnInit(): void {
    if (this.data.product) {
      this.product = Object.assign({}, JSON.parse(JSON.stringify(this.data.product)))
      this.savedProduct = this.data.product;
    }
    this.productLines = this.data.productLines;
    this.existingProducts = this.data.existingProducts;
    this.createForm();
  }

  onProductLineChange(event: any) {
    this.product.productLineId = event.value;
    this.form.get('productName')?.updateValueAndValidity();
  }

  private isForbidden(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && this.product.productLineId && (this.existingProducts.find(p => p.displayName.toUpperCase() === c.value.toUpperCase() && p.productLineId === this.product.productLineId))) {
        return { 'forbiddenValues': true };
      }
      return null;
    };
  }

  createForm(): void {
    this.form = this.fb.group({
      productName: [this.product.displayName, [Validators.required, Validators.pattern(/\S/), this.isForbidden()]],
      productLine: [this.product.productLineId, [Validators.required, Validators.pattern(/\S/)]]
    });
  }

  auditableChange(event: any) {
    this.product.auditable = event.checked;
  }

  deletedChange(event: any) {
    this.product.deleted = !event.checked;
  }

  public update(saveAndAdd: boolean): void {
    this.form.markAllAsTouched();
    if (this.form.status === 'VALID') {
      this.savedProduct.displayName = this.product.displayName.toUpperCase();
      this.savedProduct.auditable = this.product.auditable;
      this.savedProduct.deleted = this.product.deleted;
      this.savedProduct.displayOrder = this.product.displayOrder;
      this.savedProduct.productLineId = this.product.productLineId;
      this.dialogRef.close({ 'product': this.savedProduct, 'saveAndAdd': saveAndAdd });
    }
  }

  public cancel(): void {
    this.product = this.savedProduct;
    this.dialogRef.close();
  }

}
