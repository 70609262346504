import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FrameworkRoutingModule } from './framework-routing.module';
import { FrameworkComponent } from './framework.component';
import { ExecuteComponent } from './dashboard/execute/execute.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { LoadComponent } from './dashboard/load/load.component';
import { ViewComponent } from './dashboard/view/view.component';
import { WayfindingComponent } from './dashboard/load/wayfinding/wayfinding.component';
import { DataStagingComponent } from './dashboard/load/data-staging/data-staging.component';
import { ContentValidationComponent } from './dashboard/load/content-validation/content-validation.component';
import { ErrorLogComponent } from './error-log/error-log.component';
import { WayfindingPathComponent } from './dashboard/load/wayfinding/wayfinding-path/wayfinding-path.component';
import { FhirValidationComponent } from './dashboard/load/fhir-validation/fhir-validation.component';
import { LoadFhirComponent } from './dashboard/load/load-fhir/load-fhir.component';
import { CreateConfigurationComponent } from './dashboard/execute/create-configuration/create-configuration.component';
import { ExecuteRunComponent } from './dashboard/execute/execute-run/execute-run.component';
import { ManageMeasurePackageComponent } from './manage-measure-package/manage-measure-package.component';
import { ManageOrganizationInformationComponent } from './manage-organization-information/manage-organization-information.component';
import { RunResultsComponent } from './dashboard/view/run-results/run-results.component';
import { GenerateNcqaOutputDialogComponent } from './dashboard/generate-ncqa-output-dialog/generate-ncqa-output-dialog.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageUserCheatSheetComponent } from './manage-user-cheat-sheet/manage-user-cheat-sheet.component';
import { ManageUserDialogComponent } from './manage-user-dialog/manage-user-dialog.component';
import { NameAbbreviationPipe } from '../shared/pipes/name-abbreviation.pipe';
import { RunResultsMemberLevelComponent } from './dashboard/view/run-results/run-results-member-level/run-results-member-level.component';
import { RunResultsSummaryComponent } from './dashboard/view/run-results/run-results-summary/run-results-summary.component';
import {  FhirDatePipe } from '../shared/pipes/fhir-date.pipe';
import {  BoolToBitPipe } from '../shared/pipes/bool-to-bit.pipe';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FrameworkRoutingModule
    ],
    declarations: [
        FrameworkComponent,
        HeaderComponent,
        LoadComponent,
        ExecuteComponent,
        ViewComponent,
        DashboardComponent,
        WayfindingComponent,
        DataStagingComponent,
        ContentValidationComponent,
        ErrorLogComponent,
        WayfindingPathComponent,
        FhirValidationComponent,
        LoadFhirComponent,
        CreateConfigurationComponent,
        ExecuteRunComponent,
        ManageMeasurePackageComponent,
        ManageOrganizationInformationComponent,
        RunResultsComponent,
        GenerateNcqaOutputDialogComponent,
        ManageUsersComponent,
        ManageUserCheatSheetComponent,
        ManageUserDialogComponent,
        NameAbbreviationPipe,
        RunResultsMemberLevelComponent,
        RunResultsSummaryComponent,
        FhirDatePipe,
        BoolToBitPipe,
    ],
    providers: [DatePipe]
})
export class FrameworkModule {
}
