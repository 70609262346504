<div id="modal" class="custom-dialog {{modalType}}">
	<h2 mat-dialog-title id="modal-title" class="title">{{modalTitle}}</h2>
	<mat-dialog-content>
		<p id="modal-message" class="message" [innerHtml]="modalMessage">
		</p>
	</mat-dialog-content>
	<mat-dialog-actions class="actions">
		<div *ngIf="secondaryBtnText && primaryBtnText && thirdBtnText">
			<button mat-button id="third-btn" class="third-btn" [mat-dialog-close]="3">{{thirdBtnText}}</button>
            <button mat-button id="secondary-btn" class="secondary-btn" [mat-dialog-close]="2">{{secondaryBtnText}}</button>
            <button mat-stroked-button id="primary-btn" class="primary-btn" [mat-dialog-close]="1">{{primaryBtnText}}</button>
        </div>
        <div *ngIf="secondaryBtnText && primaryBtnText && !thirdBtnText">
            <button mat-button id="secondary-btn" class="secondary-btn" [mat-dialog-close]="2">{{secondaryBtnText}}</button>
            <button mat-stroked-button id="primary-btn" class="primary-btn" [mat-dialog-close]="1">{{primaryBtnText}}</button>
        </div>
        <div *ngIf="!secondaryBtnText && !thirdBtnText && primaryBtnText">
            <button mat-stroked-button id="primary-btn" class="primary-btn" [mat-dialog-close]="1">{{primaryBtnText}}</button>
        </div>
	</mat-dialog-actions>
</div>