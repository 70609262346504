<div class="background">
    <h3>
        Measure Run in Progress
        <button class="close" (click)="snackBarRef.dismiss()"><img id="closeIcon"
            src="assets/images/close.svg"></button>
    </h3>
    <p class="body">
        You can navigate away from this screen while measures are running and return any time for progress feedback.
        <!-- TODO: NOT FOR MVP -->
        <!-- When the measure run is complete, an email will be sent to email@email.com. -->
    </p>
</div>