import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { RunConfigService } from 'src/app/core/run-config.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { ComparatorService } from 'src/app/shared/comparator.service';


@Component({
  selector: 'app-run-results',
  templateUrl: './run-results.component.html',
  styleUrls: ['./run-results.component.scss'],
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0
        })
      ),
      state(
        'show',
        style({
          height: '*'
        })
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
    ])
  ]
})
export class RunResultsComponent {
  @Input() selectedRunLog: any;
  @Input() selectedRunConfig: any;
  @Output() exportMemberLevelClicked = new EventEmitter<any>();

  exportClicked = new EventEmitter<any>();
  selectedTabIndex: number = 0;


  summaryFilename: string = "SummaryLevelOutput";

  constructor(public dataService: DataService, public runConfigService: RunConfigService, public dateService: DateService, public comparatorService: ComparatorService, public snackbarService: SnackbarService, public datePipe: DatePipe) { }

  tabChange(selectedIndex: number) {
    this.selectedTabIndex = selectedIndex;
  }

  ngOnInit() {
  }

  exportData() {
    if (this.selectedTabIndex === 0) {
      this.dataService.getRunResultSummaryCSV(this.selectedRunLog.id)
        .subscribe((data) => this.downloadCsvBlob(`${this.summaryFilename}_${this.selectedRunConfig.runName}_${this.datePipe.transform(this.selectedRunLog.startTime, 'yyyy-MM-dd-HH:mm')}.csv`, data));
    } else {
      this.exportMemberLevelClicked.emit();
    }


  }

  downloadCsvBlob(fileName: string, data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }





}
