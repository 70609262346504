import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../core/data.service';
import { SnackbarService } from '../../core/snackbar.service';
import { UserService } from '../../core/user.service';
import { User } from '../../models/user';

@Component({
  selector: 'ag-manage-user-dialog',
  templateUrl: './manage-user-dialog.component.html',
  styleUrls: ['./manage-user-dialog.component.scss']
})
export class ManageUserDialogComponent implements OnInit {

  public title!: String;
  public user!: User;
  public emails: string[] = [];
  public userIds: string[] = [];
  selection = new SelectionModel<User>(false, []);
  manageUsersForm: FormGroup = new FormGroup({
  });
  inputErrors = false;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<ManageUserDialogComponent>, public userService: UserService) { }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  buildFormGroup() {
    this.manageUsersForm.addControl('first-name', new FormControl(this.user.first_name));
    this.manageUsersForm.addControl('last-name', new FormControl(this.user.last_name));
    this.manageUsersForm.addControl('email-id', new FormControl(this.user.email_id));
    this.manageUsersForm.addControl('network-id', new FormControl(this.user.user_id));
    this.manageUsersForm.addControl('role', new FormControl(this.user.user_id));
    this.manageUsersForm.controls['first-name'].setValidators(Validators.required);
    this.manageUsersForm.controls['last-name'].setValidators(Validators.required);
    this.manageUsersForm.controls['email-id'].setValidators([Validators.required, Validators.email, this.uniqueEmailValidator.bind(this)]);
    this.manageUsersForm.controls['network-id'].setValidators([Validators.required, this.uniqueUserIdValidator.bind(this)]);
    this.manageUsersForm.controls['role'].setValidators(Validators.required);
  }

  getEnableSave() {
    return this.user.email_id && this.user.first_name && this.user.last_name && this.user.user_id;
  }

  getForm(identifier: string) {
    if (!this.manageUsersForm.get(identifier)) {
      this.buildFormGroup();
    }
    return this.manageUsersForm.get(identifier);
  }

  getUserIdErrorMessage(): string {
    let form = this.getForm('network-id');
    if (form!.value) {
      return 'Network ID already taken';
    }
    return '* Required';
  }

  getEmailErrorMessage(): string {
    let form = this.getForm('email-id');
    if (form!.value) {
      if (form!.hasError('duplicate')) {
        return 'Email already taken';
      } else {
        return 'Not a valid email address';
      }
    }
    return '* Required';
  }

  uniqueUserIdValidator(control: FormControl) {
    if (control.value && this.userIds.find(userId => userId !== null && userId.toUpperCase() === control.value.toUpperCase())) {
      return { duplicate: true };
    } else {
      return null;
    }
  }

  uniqueEmailValidator(control: FormControl) {
    if (control.value && this.emails.find(email => email !== null && email.toUpperCase() === control.value.toUpperCase())) {
      return { duplicate: true };
    } else {
      return null;
    }
  }

  isControlValid(identifier: string): boolean {
    if (!this.submitted) {
      this.manageUsersForm.markAsUntouched();
    }
    let form = this.getForm(identifier);
    if (form && form.invalid) {
      return !this.submitted;
    }
    return true;
  }

  isChecked(user: any) {
    return this.selection.isSelected(user.active);
  }

  save() {
    this.submitted = true;
    this.manageUsersForm.markAllAsTouched();
    if (this.manageUsersForm.invalid) {
      return;
    }
    this.user.full_name = this.user.last_name + ', ' + this.user.first_name;
    this.dialogRef.close(this.user);
  }

}
