 
import { MeasureGroupMapping } from 'src/app/models/measure-group-mapping';
import { DefaultProductLine } from './default-product-line';
export class Measure {
    id!: number;
    resourceId!: string;
    displayName!: string;
    measureAbbr!: string;
    certification!: string;
    title!: string;
    version!: string;
    measurementYear!: string;
    certificationDate!: Date;
    displayOrder!: number;
    measureId!: string;
    subMeasureId!: string;
    deleted!: boolean;
    uploadDate!: Date;
    defaultProductLines!: DefaultProductLine[];
    selectedProductLines!: any[];
    isInAnActiveRun: boolean = false;
    bundle!: string;
    bundleFileIndex!: number;
    filterAge!:string;
    outputAge!:string;
    outputGender!:string;
    hasOutputEvent!: boolean;
    measureGroupMappings:MeasureGroupMapping[] = [];
}