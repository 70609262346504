import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { DialogService } from 'src/app/core/dialog.service';
import { RunConfigService } from 'src/app/core/run-config.service';
import { UserService } from 'src/app/core/user.service';
import { ModalType } from 'src/app/models/enums/modal-type-enum';
import { RunConfiguration } from 'src/app/models/run-configuration';
import { RunLog } from 'src/app/models/run-log';
import { EditRunNameDialogComponent } from 'src/app/shared/dialogs/edit-run-name-dialog/edit-run-name-dialog.component';
import { ErrorLogComponent } from '../../error-log/error-log.component';
import { RefreshService } from 'src/app/core/refresh.service';

@Component({
  selector: 'app-execute',
  templateUrl: './execute.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./execute.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class ExecuteComponent implements OnInit {

  public editRunNameDialogRef!: MatDialogRef<EditRunNameDialogComponent>;
  public configToRun!: RunConfiguration;
  configListLoadedSubscription: Subscription;
  public errorLogRef!: MatDialogRef<ErrorLogComponent>;
  public refreshAnnounced!: Observable<void>;
  public refreshSubscription!: Subscription;

  constructor(public dateService: DateService, public userService: UserService, public dialogService: DialogService,
    public runConfigService: RunConfigService, public dialog: MatDialog, public dataService: DataService,
    public configurationService: ConfigurationService, private changeDetectorRef: ChangeDetectorRef, public refreshService: RefreshService) {
    this.configListLoadedSubscription = this.runConfigService.configListLoadedEventEmitter.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.configListLoadedSubscription.unsubscribe();
    this.refreshSubscription?.unsubscribe();
    this.refreshService.stopInterval();
  }

  ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe(() => {
      this.runConfigService.loadRunConfigurations();
      this.refreshService.startEmitting(this.configurationService.executeRunRefreshSeconds);
      this.refreshAnnounced = this.refreshService.getObservable();
      this.subscribeToRefresh();
    });
  }

  subscribeToRefresh(){
    this.refreshSubscription = this.refreshAnnounced.subscribe(async () => {
      await this.configurationService.getConfiguration().subscribe();
      if(this.runConfigService.runConfigurations.some(runConfig => this.isConfigurationRunning(runConfig))){
        this.runConfigService.loadRunConfigurations();
      } else {
        this.refreshSubscription.unsubscribe();
      }

    });
  }

  run(runConfiguration: RunConfiguration) {
    this.configToRun = runConfiguration;
    this.runConfigService.executeRun = true;
    this.refreshSubscription?.unsubscribe();
  }

  edit(runConfiguration: RunConfiguration) {
    this.runConfigService.runConfig = runConfiguration;
    this.runConfigService.selectedFilterOptions = Object.assign({}, JSON.parse(runConfiguration.configuration));
    this.runConfigService.editing = true;
    this.runConfigService.showCreateConfiguration = true;
  }

  viewLogs(runLog: RunLog) {
    this.errorLogRef = this.dialog.open(ErrorLogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'error-log-view'
    });
    this.errorLogRef.componentInstance.executeRunJobLogId = runLog.id;
  }


  shouldDisableCreateConfigButton() {
    return !(this.userService.isAdministrator() || this.userService.isDeveloper() || this.userService.isApprover());
  }

  isConfigurationRunning(runConfig: RunConfiguration) {
    if (runConfig.executeRunJobLogEntityList.filter(f => f.id === this.configurationService.executingRunJobLogId)[0]) {
      return true;
    }
    return false;
  }

  backFromCreate() {
    let modalTitle = 'You\'re about to lose your work';
    let modalMessage = 'Data you\'ve entered will be lost if you navigate away from this page without creating a run configuration first. You can create a configuration now and continue to edit it up until your first run.'
    let primaryBtnText = 'Stay on this page';
    let secondaryBtnText = 'Leave and lose my work';
    this.dialogService.openModal(ModalType.WARN, modalTitle, modalMessage, primaryBtnText, secondaryBtnText, () => {
    }, () => {
      this.runConfigService.showCreateConfiguration = false;
      this.runConfigService.loadRunConfigurations();
      this.configurationService.getConfiguration().subscribe();
    });
  }

  backFromRun() {
    this.runConfigService.executeRun = false;
    this.runConfigService.loadRunConfigurations();
    this.configurationService.getConfiguration().subscribe();
    this.refreshSubscription?.unsubscribe();
    this.subscribeToRefresh();
  }

  shouldHideEdit() {
    return !(this.userService.isAdministrator() || this.userService.isDeveloper() || this.userService.isApprover());
  }

  editName(config: RunConfiguration) {
    let forbiddenNames = new Set([...this.runConfigService.savedRunConfigNames].map(name => name.toUpperCase()).filter(n => n !== config.runName.toUpperCase()));
    this.editRunNameDialogRef = this.dialog.open(EditRunNameDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        runName: config.runName,
        savedRunNames: forbiddenNames
      }
    });
    this.editRunNameDialogRef.afterClosed().subscribe(response => {
      if (response.result) {
        config.runName = response.runName;
        this.dataService.saveRunConfig(config).subscribe(() => {
          this.runConfigService.loadRunConfigurations();
        }, () => {
          this.runConfigService.loadRunConfigurations();
        });
      }
    });
  }

  //trackById is used to avoid loss of state when refreshing the run configurations (expanded state)
  trackById(index: number, item: RunConfiguration) {
    return item.id;
  }
}
