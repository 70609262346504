import { Injectable } from '@angular/core';
import { ApplicationStateEnum } from '../models/enums/application-state-enum';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  constructor(public configurationService: ConfigurationService) { }

  get applicationState(): string {
    return this.configurationService.applicationState;
  }

  public isRunningState() {
    return this.applicationState === ApplicationStateEnum.RUNNING;
  }

}
