import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
declare var require: any

@Component({
  selector: 'app-upload-measure-package-dialog',
  templateUrl: './upload-measure-package-dialog.component.html',
  styleUrls: ['./upload-measure-package-dialog.component.scss']
})
export class UploadMeasurePackageDialogComponent implements OnInit {

  public uploading = false;
  public failedValidation = false;
  public validFiles: Map<String, String> = new Map()
  public selectedFile: any;
  public validationErrors: string[] = [];

  public title: string = '';
  public version: string = '';
  public resourceId: string = '';

  constructor(public dialogRef: MatDialogRef<UploadMeasurePackageDialogComponent>) { }

  ngOnInit(): void {
  }

  public onChange(event: any): void {
    this.selectedFile = event.files[0];
  }

  public cancel(): void {
    if (this.uploading) {
      this.uploading = !this.uploading;
    } else {
      this.dialogRef.close();
    }
  }

  public uploadFile(): void {
    let filesInZip: Map<String, String> = new Map()
    this.uploading = true;
    const jsZip = require('jszip');
    jsZip.loadAsync(this.selectedFile).then((zip: any) => {
      let i = 0;
      let len = Object.keys(zip.files).length;
      Object.keys(zip.files).forEach((filename) => {
        zip.files[filename].async('string').then((fileData: any) => {
          i++;
          if (filename.endsWith('.json')) {
            filesInZip.set(filename, fileData);
          }
          if (i >= len) {
            this.validateMeasurePackage(filesInZip);
          }
        });
      });
    });
  }

  public validateMeasurePackage(filesInZip: Map<String, String>) {
    this.validFiles = new Map();
    this.validationErrors = [];
    let libraryResourceTypePresent = false;
    let valueSetResourceTypePresent = false;
    let onlyOneBundleMeasureResourcePresent = false;
    let atLeastOneMeasurePresent = false;

    filesInZip.forEach((fileContent, fileName) => {
      if (this.validateJSON(fileContent)) {
        let parsedContent = JSON.parse(fileContent.toString());
        try {
          let resourceType = parsedContent['resourceType'];
          if (resourceType.toUpperCase() === 'BUNDLE') {
            parsedContent['entry'].forEach((entry: any) => {
              let entryResource = entry['resource'];
              if (entryResource['resourceType'].toUpperCase() === 'MEASURE') {
                this.title = entryResource['title'];
                this.version = entryResource['version'];
                this.resourceId = entryResource['id'];
                if (onlyOneBundleMeasureResourcePresent) {
                  onlyOneBundleMeasureResourcePresent = false;
                } else {
                  onlyOneBundleMeasureResourcePresent = true;
                  atLeastOneMeasurePresent = true;
                  this.validFiles.set(fileName, fileContent);
                }
              }
              if (entryResource['resourceType'].toUpperCase() === 'LIBRARY') {
                libraryResourceTypePresent = true;
                this.validFiles.set(fileName, fileContent);
              }
              if (entryResource['resourceType'].toUpperCase() === 'VALUESET') {
                valueSetResourceTypePresent = true;
                this.validFiles.set(fileName, fileContent);
              }
            });
          }
          if (resourceType.toUpperCase() === 'MEASURE') {
            this.title = parsedContent['title'];
            this.version = parsedContent['version'];
            this.resourceId = parsedContent['id'];
            if (onlyOneBundleMeasureResourcePresent) {
              onlyOneBundleMeasureResourcePresent = false;
            } else {
              onlyOneBundleMeasureResourcePresent = true;
              atLeastOneMeasurePresent = true;
              this.validFiles.set(fileName, fileContent);
            }
          }
          if (resourceType.toUpperCase() === 'LIBRARY') {
            libraryResourceTypePresent = true;
            this.validFiles.set(fileName, fileContent);
          }
          if (resourceType.toUpperCase() === 'VALUESET') {
            valueSetResourceTypePresent = true;
            this.validFiles.set(fileName, fileContent);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    if (libraryResourceTypePresent && valueSetResourceTypePresent && onlyOneBundleMeasureResourcePresent) {
      this.uploading = false;
      this.dialogRef.close({'title':this.title, 'version':this.version, 'resourceId':this.resourceId, 'bundles': Array.from(this.validFiles.values())});
    } else {
      if (!atLeastOneMeasurePresent) {
        this.validationErrors.push('No FHIR Measure Resource present');
      }
      if (!libraryResourceTypePresent) {
        this.validationErrors.push('No FHIR Library Resource present');
      }
      if (!valueSetResourceTypePresent) {
        this.validationErrors.push('No FHIR Valueset Resource present');
      }
      if (!onlyOneBundleMeasureResourcePresent && atLeastOneMeasurePresent) {
        this.validationErrors.push('More than one FHIR Measure Resource present');
      }
      if (this.uploading) {
        this.uploading = false;
        this.failedValidation = true;
      }
    }
  }

  private validateJSON(str: String) {
    try {
      JSON.parse(str.toString());
    } catch (e) {
      return false;
    }
    return true;
  }

}
