import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'viewer'
})
export class ViewerPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value === "View Only" ? "Viewer" : value;
  }

}
