<section id="run-results-table-member-level-container">

  <table id="table-member-level" mat-table matSort (matSortChange)="sortMemberLevelData($event)"
    [dataSource]="memberLevelDataSource">
    <!-- memberId -->
    <ng-container matColumnDef="memberId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 110px;">Member ID</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.memberId}}
        </div>

      </td>
    </ng-container>

    <!-- age -->
    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align" style="min-width: 70px;">Age</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.age}}
        </div>
      </td>
    </ng-container>

    <!-- gender -->
    <ng-container matColumnDef="gender">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 100px;">Gender</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.gender}}
        </div>
      </td>

    </ng-container>

    <!-- productLine -->
    <ng-container matColumnDef="productLine">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 115px;">Product Line</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.productLine}}
        </div>
      </td>

    </ng-container>

    <!-- product -->
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Product</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.product}}
        </div>
      </td>

    </ng-container>

    <!-- measure -->
    <ng-container matColumnDef="measure">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 150px;">Display Name</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.measure}}
        </div>
      </td>

    </ng-container>

    <!-- subMeasureId -->
    <ng-container matColumnDef="subMeasureId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 128px;">Submeasure ID
      </th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.subMeasureId}}
        </div>
      </td>

    </ng-container>

    <!-- measureStratification -->
    <ng-container matColumnDef="measureStratification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Stratification</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.measureStratification}}
        </div>
      </td>

    </ng-container>

    <!-- race -->
    <ng-container matColumnDef="race">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 200px;">Race</th>
      <td mat-cell *matCellDef="let row" class="mat-standard-cell">
        <div *ngIf="loadingRunResults" class="skeleton-label">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div *ngIf="!loadingRunResults">
          {{row.race}}
        </div>
      </td>

      <!-- ethnicity -->
      <ng-container matColumnDef="ethnicity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 150px;">Ethnicity</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.ethnicity}}
          </div>
        </td>

      </ng-container>

      <!-- recordIdentifier -->
      <ng-container matColumnDef="recordIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align" style="min-width: 200px;">Record Identifier</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.recordIdentifier}}
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="runId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Run ID</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.runId}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="birthDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Birth Date</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell ">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.birthDate| fhirDate}}
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="payerStratification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Payer Stratification</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.payerStratification}}
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Event</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.event}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="eventDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Event Date</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell ">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.eventDate| fhirDate}}
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="eligiblePopulation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Eligible Population
        </th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.eligiblePopulation}}
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="spokenLanguage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="padding-left:0;">Spoken Language</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell " style="padding-left:2em;">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.spokenLanguage}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="numerator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Numerator</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.numerator}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="denominator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Denominator</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.denominator}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="requiredExclusion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Required Exclusion
        </th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.requiredExclusion}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="optionalExclusion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Optional Exclusion
        </th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.optionalExclusion|boolToBitPipe}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="initialPopulation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Initial Population
        </th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.initialPopulation}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="continuousEnrollment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Continuous Enrollment
        </th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.continuousEnrollment}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="participationPeriodStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Participation Period
          Start</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.participationPeriodStart| fhirDate}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="participationPeriodEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Participation Period
          End</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell ">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.participationPeriodEnd | fhirDate}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="hedisYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">HEDIS Year</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.hedisYear}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="numeratorInclusionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Numerator Inclusion
          Date</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell ">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{ row.numeratorInclusionDate | fhirDate}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="numeratorInclusionFhirId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align" style="min-width:200px">Numerator Inclusion
          FHIR ID</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.numeratorInclusionFhirId}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="requiredExclusionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell ">Required Exclusion
          Date</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.requiredExclusionDate| fhirDate}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="requiredExclusionFhirId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align" style="min-width:200px">Required Exclusion
          FHIR ID</th>
        <td mat-cell *matCellDef="let row" class="mat-standard-cell right-align">
          <div *ngIf="loadingRunResults" class="skeleton-label">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div *ngIf="!loadingRunResults">
            {{row.requiredExclusionFhirId}}
          </div>

        </td>

      </ng-container>


    </ng-container>

    <tr mat-header-row *matHeaderRowDef="memberLevelDisplayedColumns sticky: true" class="mat-header-cell"></tr>
    <tr mat-row  *matRowDef="let row; columns: memberLevelDisplayedColumns;"></tr>
  </table>
</section>
<div class="footer">
  <mat-paginator #memberLevelPaginator [pageSize]="memberLevelSelectedPageSize" [length]="memberLevelTotalRows"
    (page)="onMemberLevelDataPaginateChange($event)">
  </mat-paginator>
</div>