import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { RunResultSummary } from 'src/app/models/run-result-summary';
import { ComparatorService } from 'src/app/shared/comparator.service';

@Component({
  selector: 'app-run-results-summary',
  templateUrl: './run-results-summary.component.html',
  styleUrls: ['./run-results-summary.component.scss']
})
export class RunResultsSummaryComponent implements OnInit {
  @ViewChild("summaryPaginator") summaryPaginator!: MatPaginator;
  

  @Input() selectedRunLog: any;
  @Input() exportClicked!: EventEmitter<any>;

  @Output() exportData = new EventEmitter<any>();

  sort?: Sort;
  skeletonList: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  dataSource: MatTableDataSource<RunResultSummary> = new MatTableDataSource(this.skeletonList);
  displayedColumns: string[] = ["productLine", "product", "measure", "subMeasureId", "stratification", "reportedRate", "initialPopulation", "numerator", "denominator", "denomExcl"];
  totalRows?: number;
  selectedPageNumber!: number;
  selectedPageSize!: number;
  selectedTabIndex: number = 0;
  loadingRunResults: boolean = true;
  exportSubscription?: Subscription;

  constructor(public dataService: DataService,  public comparatorService: ComparatorService, public snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.selectedPageNumber = 1;
    this.selectedPageSize = 100;
    this.dataService.getRunResultSummary(this.selectedRunLog.id, this.selectedPageNumber, this.selectedPageSize).subscribe({
      next: this.onGetRunResultDetails(),
      error: this.onGetRunResultDetailsError()
    });

    this.exportSubscription = this.exportClicked.subscribe(() => this.onExportData());
  }

  ngOnDestroy() {
    this.exportSubscription?.unsubscribe();
  }
  onGetRunResultDetails() {
    return (data: any) => {
      this.dataSource = new MatTableDataSource(data?.runResultSummaries);
      this.totalRows = data!.totalRows;
      this.loadingRunResults = false;
    }
    

   
  }

  onGetRunResultDetailsError() {
    return (_: any) => {
      this.loadingRunResults = false;
      this.dataSource = new MatTableDataSource();
      this.snackbarService.showNegativeBar("There was a problem getting summary results. Please try again.");
    };
  }

  onPaginateChange(event: PageEvent): void {
    this.dataSource = new MatTableDataSource(this.skeletonList);
    if (event) {
      this.selectedPageNumber = event.pageIndex + 1;
      this.selectedPageSize = event.pageSize;
    }
    this.summaryPaginator.pageIndex = this.selectedPageNumber - 1;
    this.loadingRunResults = true;
    this.getSummaryData(this.selectedRunLog.id, this.selectedPageNumber, this.selectedPageSize);

  }

  getSummaryData(jobId: number, pageNumber: number, pageSize: number) {
    this.dataService.getRunResultSummary(jobId, pageNumber, pageSize).subscribe({
      next: this.onGetRunResultDetails(),
      error: this.onGetRunResultDetailsError()
    });
  }

  sortData(sort: Sort) {    
    this.sort = sort;
    this.dataSource = new MatTableDataSource(this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.comparatorService.compare(a[sort.active as keyof RunResultSummary], b[sort.active as keyof RunResultSummary], isAsc);
    }));
  }

  onExportData(): any {
    return this.exportData.emit({ headers: this.displayedColumns, rows: this.dataSource.data });
  }

}
