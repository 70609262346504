import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { forkJoin } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { ContentValidationService } from 'src/app/core/content-validation.service';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ExportService } from 'src/app/core/export.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { LogTypeEnum } from 'src/app/models/enums/log-type-enum';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';
import { ErrorCategoryEntry } from 'src/app/models/error-category-entry';
import { ErrorLogEntry } from 'src/app/models/error-log-entry';
import { ErrorLogResponse } from 'src/app/models/error-log-response';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.scss']
})
export class ErrorLogComponent implements OnInit {

  public logType: LogTypeEnum = LogTypeEnum.HEADER_VALIDATION;
  public loadDataJobLogId!: number | null;
  public executeRunJobLogId!: number | null;
  public errorLogEntries: ErrorLogEntry[] = [];
  public loadingLogs: boolean = false;
  public downloading: boolean = false;
  public selectedTab: number = 0;
  public contentValidationCategories!: ErrorCategoryEntry[];
  public fhirValidationCategories!: ErrorCategoryEntry[];
  public totalFhirValidationCategories: number = 0;
  public totalContentValidationCategories: number = 0;

  constructor(public dialogRef: MatDialogRef<ErrorLogComponent>, public dataService: DataService,
    public configurationService: ConfigurationService,
    public loadingService: LoadingService,
    public dialogService: DialogService,
    public jobLogService: JobLogService,
    public wayfindingService: WayfindingService,
    public userService: UserService,
    public dateService: DateService,
    public exportService: ExportService,
    public contentValidationService: ContentValidationService) { }

  ngOnInit(): void {
    switch (this.configurationService.currentWayfindingStage) {
      case WayfindingStageEnum.LOAD_DATA:
        this.selectedTab = 0;
        this.logType = LogTypeEnum.HEADER_VALIDATION;
        break;
      case WayfindingStageEnum.VALIDATE_CONTENT:
        if (this.contentValidationService.criticalError) {
          this.selectedTab = 0;
          this.logType = LogTypeEnum.HEADER_VALIDATION;
        } else {
          this.selectedTab = 1;
          this.logType = LogTypeEnum.CONTENT_VALIDATION;
        }
        break;
      case WayfindingStageEnum.VALIDATE_FHIR_BUNDLES:
        this.selectedTab = 2;
        this.logType = LogTypeEnum.FHIR_VALIDATION;
        break;
      case WayfindingStageEnum.LOAD_BUNDLES:
        this.selectedTab = 3;
        this.logType = LogTypeEnum.FHIR_SERVER_LOAD;
        break;
      default:
        this.selectedTab = 0;
        this.logType = LogTypeEnum.HEADER_VALIDATION;
        break;
    }
    this.initErrorLogs();
  }

  startLoading(): number | null {
    this.loadingLogs = true;
    let jobLogId = this.loadDataJobLogId;
    if (this.executeRunJobLogId) {
      jobLogId = this.executeRunJobLogId;
      this.logType = LogTypeEnum.EXECUTE_RUN;
    }
    return jobLogId;
  }
  getErrorLogs(): void {
    let jobLogId = this.startLoading();
    this.fetchLoadErrorLogs(jobLogId);
  }

  initErrorLogs() {
    let jobLogId = this.startLoading();
    if (this.logType === LogTypeEnum.EXECUTE_RUN) {
      this.fetchExecuteErrorLogs(jobLogId);
      return;
    }

    forkJoin([this.dataService.getErrorLogs(jobLogId, LogTypeEnum.HEADER_VALIDATION),
    this.dataService.getErrorLogCategories(jobLogId, LogTypeEnum.CONTENT_VALIDATION),
    this.dataService.getErrorLogCategories(jobLogId, LogTypeEnum.FHIR_VALIDATION),
    this.dataService.getErrorLogs(jobLogId, LogTypeEnum.FHIR_SERVER_LOAD)]).subscribe(
      (responses: any[]) => {
        this.initTabData(responses);
        this.loadingLogs = false;
      },
      () => { this.loadingLogs = false; }
    );
  }

  initTabData(responses: any[]) {

    // Header Validation Error Logs
    if (responses[0] && this.selectedTab === 0) {
      this.errorLogEntries = responses[0].error_logs;
    }


    // Content Validation Error Logs
    if (responses[1]) {
      this.contentValidationCategories = responses[1].categories;
      this.totalContentValidationCategories = responses[1].totalCategoryCount
    }

    // FHIR Validation Error Logs
    if (responses[2]) {
      this.fhirValidationCategories = responses[2].categories;
      this.totalFhirValidationCategories = responses[2].totalCategoryCount;
    }

    // FHIR Server Load Error Logs
    if (responses[3] && this.selectedTab === 3) {
      this.errorLogEntries = responses[3].error_logs;
    }
  }


  async fetchLoadErrorLogs(jobLogId: number | null) {
    if (this.selectedTab === 0 || this.selectedTab === 3) {
      let response = await this.dataService.getErrorLogs(jobLogId, this.logType).toPromise();
      if (!response) return;
      this.errorLogEntries = response.error_logs;
    }
    if (this.selectedTab === 1) {
      let response = await this.dataService.getErrorLogCategories(jobLogId, LogTypeEnum.CONTENT_VALIDATION.toString()).toPromise();
      if (!response) return;
      this.contentValidationCategories = response.categories;
      this.totalContentValidationCategories = response.totalCategoryCount;
    }
    if (this.selectedTab === 2) {
      let response = await this.dataService.getErrorLogCategories(jobLogId, LogTypeEnum.FHIR_VALIDATION.toString()).toPromise();
      if (!response) return;
      this.fhirValidationCategories = response.categories;
      this.totalFhirValidationCategories = response.totalCategoryCount;
    }
    this.loadingLogs = false;
  }

  async fetchExecuteErrorLogs(jobLogId: number | null) {
    let response = await this.dataService.getErrorLogs(jobLogId, this.logType).toPromise();
    this.errorLogEntries = response?.error_logs ? response.error_logs : this.errorLogEntries;
    this.loadingLogs = false;
  }

  closeDialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  onTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    switch (tabChangeEvent.index) {
      case 0:
        this.logType = LogTypeEnum.HEADER_VALIDATION;
        break;
      case 1:
        this.logType = LogTypeEnum.CONTENT_VALIDATION;
        break;
      case 2:
        this.logType = LogTypeEnum.FHIR_VALIDATION;
        break;
      case 3:
        this.logType = LogTypeEnum.FHIR_SERVER_LOAD;
        break;
      default:
        this.logType = LogTypeEnum.HEADER_VALIDATION;
        break;
    }
    this.getErrorLogs();
  }

  downloadLogFiles(): void {
    this.downloading = true;
    let jobLogId = this.loadDataJobLogId;
    if (this.executeRunJobLogId) {
      jobLogId = this.executeRunJobLogId;
      this.logType = LogTypeEnum.EXECUTE_RUN;
    }
    this.dataService.downloadErrorLogs(jobLogId, this.logType).subscribe((response: ErrorLogResponse[] | null) => {
      let totalLogs = 0;
      if (response && response.length) {
        const logs: any[] = [];
        totalLogs = response[0].total_record_count;
        response.forEach((res) => {
          let error_logs = res.error_logs;
          if (this.logType === LogTypeEnum.CONTENT_VALIDATION || this.logType === LogTypeEnum.FHIR_VALIDATION) {
            error_logs = res.error_logs_reduced;
          }
          error_logs.forEach((log) => {
            switch (this.logType) {
              case LogTypeEnum.HEADER_VALIDATION:
                logs.push({ 'Filename': log.fileName, 'Headers Expected': log.expectedHeaders, 'Headers Received': log.receivedHeaders });
                break;
              case LogTypeEnum.CONTENT_VALIDATION:
                logs.push({ 'Error Type': log.errorType, 'Filename': log.fileName, 'Last Logged': log.lastLogged, 'Identifier': log.identifier, 'Error Message': log.errorMessage });
                break;
              case LogTypeEnum.FHIR_VALIDATION:
                logs.push({ 'Error Type': log.errorType, 'Filename': log.fileName, 'Last Logged': log.lastLogged, 'Identifier': log.identifier, 'Error Message': log.errorMessage });
                break;
              case LogTypeEnum.FHIR_SERVER_LOAD:
                logs.push({ 'FHIR Bundle ID': log.fhirBundleId, 'Encounter ID': log.encounterId, 'Error Message': log.errorMessage });
                break;
              case LogTypeEnum.EXECUTE_RUN:
                logs.push({ 'Error Logged At': log.createdAt, 'Member ID': log.identifier, 'Measure': log.measure, 'Error Message': log.errorMessage });
                break;
              default:
                break;
            }
          });
        });
        if (totalLogs > 0) {
          this.exportService.exportArrayAsCSV(logs, 'ErrorLogs');
        }
      }
      this.downloading = false;
    }, () => {
      this.downloading = false;
    });
  }

}
