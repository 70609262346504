import { Injectable } from '@angular/core';
import { WayfindingStageEnum } from '../models/enums/wayfinding-stage-enum';
import { ConfigurationEntry } from '../models/configuration-entry';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { ApplicationStateEnum } from '../models/enums/application-state-enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(public dataService: DataService, public fileService: FileService) {
  }

  // Defaults
  private _id: number = 1;
  private _client: string = '';
  private _executingRunJobLogId: number | null = null;
  private _loadedJobLogId: number | null = null;
  private _applicationState: string = ApplicationStateEnum.READY_TO_RUN;
  private _currentWayfindingStage: string = WayfindingStageEnum.LOAD_DATA;
  private _showWayfinding: boolean = false;
  private _canceledJobLogId: number | null = null;
  private _currentJobLogId: number | null = null;
  private _errorMessage!: string;
  private _clientTimezone!: string;
  private _totalResources: number = 0;
  private _builtResources: number = 0;
  private _loadedBundles: number = 0;
  private _failedBundles: number = 0;
  private _ncqaMode: boolean = false;
  private _fileRefreshSeconds: number = 10;
  private _executeRunRefreshSeconds: number = 10;


  private _configuration: ConfigurationEntry = new ConfigurationEntry(
    this._id, this._client, this._executingRunJobLogId, this._loadedJobLogId, this._applicationState, this._currentWayfindingStage,
    this._showWayfinding, this._canceledJobLogId, this._currentJobLogId, this._errorMessage,
    this._clientTimezone, this._totalResources, this._builtResources, this._loadedBundles, this._failedBundles, this._ncqaMode, this._fileRefreshSeconds,
    this._executeRunRefreshSeconds
  );

  public getConfiguration(): Observable<any> {
    return new Observable((subscriber) => {
      this.dataService.getConfiguration().subscribe((configurationEntry: ConfigurationEntry | null) => {
        if (configurationEntry) {
          this._configuration = configurationEntry;
          if(configurationEntry.fileRefreshSeconds === undefined) {
            this._configuration.fileRefreshSeconds = this._fileRefreshSeconds;
          }
          if (this.showWayfinding && this.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA) {
            this.fileService.refreshIncomingFiles();
          }
          subscriber.next();
          subscriber.complete();
        }
      }, () => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  public newLoad(jobId: number): void {
    if (this._configuration) this._configuration.currentJobLogId=jobId;
    this.dataService.newLoad(jobId).subscribe((configurationEntry: ConfigurationEntry | null) => {
      if (configurationEntry) {
        this._configuration = configurationEntry;
      }
    });
  }

  public cancel(): void {
    this.dataService.cancel().subscribe((configurationEntry: ConfigurationEntry | null) => {
      if (configurationEntry) {
        this._configuration = configurationEntry;
      }
    });
  }

  get organizationName(): string {
    return this.configuration.organizationName;
  }

  get organizationId(): string {
    return this.configuration.organizationId;
  }

  get cmsContractNumber(): string {
    return this.configuration.cmsContractNumber;
  }

  set organizationName(value: string) {
    this.configuration.organizationName = value;
  }

  set organizationId(value: string) {
    this.configuration.organizationId = value;
  }

  set cmsContractNumber(value: string) {
    this.configuration.cmsContractNumber = value;
  }

  set configuration(value: ConfigurationEntry) {
    this._configuration = value;
  }

  get configuration(): ConfigurationEntry {
    return this._configuration;
  }

  get currentJobLogId(): number | null {
    return this.configuration.currentJobLogId;
  }

  get loadedJobLogId(): number | null {
    return this.configuration.loadedJobLogId;
  }

  get executingRunJobLogId(): number | null {
    return this.configuration.executingRunJobLogId;
  }

  set executingRunJobLogId(value: number | null) {
    this.configuration.executingRunJobLogId = value;
  }

  set loadedJobLogId(jobLogId: number | null){
    this.configuration.loadedJobLogId = jobLogId;
  }

  get canceledJobLogId(): number | null {
    return this.configuration.canceledJobLogId;
  }

  get applicationState(): string {
    return this.configuration.applicationState;
  }

  get showWayfinding(): boolean {
    return this.configuration.showWayfinding;
  }

  get cancelingValidation(): boolean {
    return this.configuration.cancelingValidation;
  }

  get currentWayfindingStage(): string {
    return this.configuration.currentWayfindingStage;
  }

  set currentWayfindingStage(value: string) {
    this.configuration.currentWayfindingStage = value;
  }

  get errorMessage(): string {
    return this.configuration.errorMessage;
  }

  get fhirValidationProgress(): number {
    if (this.configuration.totalResources === 0) {
      return 0;
    } else {
      return this.configuration.processedResources/this.configuration.totalResources * 100;
    }
  }

  get totalResources() {
    return this.configuration.totalResources;
  }


  get bundlesToLoad() {
    return this.configuration.bundlesToLoad;
  }

  get processedResources() {
    return this.configuration.processedResources;
  }

  get loadedBundles() {
    return this.configuration.loadedBundles;
  }

  get failedBundles() {
    return this.configuration.failedBundles;
  }
  get ncqaMode(){
    return this.configuration.ncqaMode;
  }
  get fileRefreshSeconds(){
    return this.configuration.fileRefreshSeconds;
  }
  get executeRunRefreshSeconds(){
    return this.configuration.executeRunRefreshSeconds;
  }
}
