import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { LoadingService } from 'src/app/core/loading.service';
import { FileService } from 'src/app/core/file.service';
import { ContentValidationService } from 'src/app/core/content-validation.service';
import { ApplicationStateService } from 'src/app/core/application-state.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { JobLogService } from 'src/app/core/job-log.service';
import * as moment from 'moment';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';

@Component({
  selector: 'app-data-staging',
  templateUrl: './data-staging.component.html',
  styleUrls: ['./data-staging.component.scss']
})
export class DataStagingComponent implements OnInit {

  constructor(public fileService: FileService, public loadingService: LoadingService, public configurationService: ConfigurationService,
    public contentValidationService: ContentValidationService, public applicationStateService: ApplicationStateService,
    public userService: UserService, public wayfindingService: WayfindingService, public jobLogService: JobLogService) { }

  ngOnInit(): void { 
    this.loadingService.initialLoad = true; 
  }

  refresh(): void {
    this.fileService.refreshIncomingFiles();
  }

  shouldDisableValidation(): boolean {
    return this.loadingService.loading || !this.fileService.allFilesValid(this.configurationService.ncqaMode) || !(this.userService.isAdministrator() || this.userService.isDeveloper())
    || this.configurationService.applicationState.toLowerCase() === 'running';
  }

  startContentValidation(): void {
    this.loadingService.validating = true;
    this.jobLogService.currentLoadStarted = moment(new Date()).format('MM/DD/YYYY hh:mm a');
    this.configurationService.currentWayfindingStage = WayfindingStageEnum.VALIDATE_CONTENT;
    this.contentValidationService.criticalError =false;
    this.contentValidationService.contentValidationError=false;
    this.fileService.fileValidations=[];
    this.contentValidationService.validateHeader().subscribe(() => {
      this.fileService.getFileValidations();
    });
  }
}
