import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-user-cheat-sheet',
  templateUrl: './manage-user-cheat-sheet.component.html',
  styleUrls: ['./manage-user-cheat-sheet.component.scss']
})
export class ManageUserCheatSheetComponent implements OnInit {

  public userDataSource!: MatTableDataSource<any>;
  public displayedColumns: string[] =
  ['labels', 'administrator', 'approver', 'viewer', 'developer'];
  public cheatSheetInfo = [
    {label: 'Load Data', administrator: true, approver: false, developer: true, viewer: false},
    {label: 'View Load Data Logs', administrator: true, approver: true, developer: true, viewer: true},
    {label: 'Configure Runs', administrator: true, approver: true, developer: true, viewer: false},
    {label: 'Run Measure(s)', administrator: true, approver: true, developer: true, viewer: false},
    {label: 'View Run Results', administrator: true, approver: true, developer: true, viewer: true},
    {label: 'View Run Logs', administrator: true, approver: true, developer: true, viewer: true},
    {label: 'Download Run Results', administrator: true, approver: true, developer: true, viewer: true},
    {label: 'View Organization Details', administrator: true, approver: true, developer: true, viewer: true},
    {label: 'Edit Organization Details', administrator: true, approver: true, developer: true, viewer: false},
    {label: 'Manage Users', administrator: true, approver: false, developer: true, viewer: false},
    {label: 'NCQA Mode', administrator: false, approver: false, developer: true, viewer: false},
    {label: 'Upload Measure Packages', administrator: false, approver: false, developer: true, viewer: false}]
  constructor(public dialogRef: MatDialogRef<ManageUserCheatSheetComponent>) { }

  ngOnInit(): void {
    this.userDataSource = new MatTableDataSource(this.cheatSheetInfo);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
