<h1 md-dialog-title class="title">Edit Run Configuration Name</h1>
<div md-dialog-content>
    <form [formGroup]="editForm" novalidate>
        <mat-form-field class="run-name">
            <mat-label>Run Configuration Name</mat-label>
            <input matInput autocomplete="off" class="name-input" required formControlName="name" [(ngModel)]="runName">
            <mat-error id='mat-error' *ngIf="editForm.controls['name'].errors?.required">
                Name is required.
            </mat-error>
            <mat-error id='mat-error' *ngIf="editForm.controls['name'].hasError('maxlength')">
                Name must be shorter than 256 characters.
            </mat-error>
            <mat-error id='mat-error' *ngIf="editForm.controls['name'].errors?.forbiddenValues">
                Name already in use. Please choose another.
            </mat-error>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions class="actions">
    <div>
        <button mat-button id="secondary-btn" class="secondary-btn" (click)="closeDialog(false)">Cancel</button>
        <button mat-stroked-button id="primary-btn" class="primary-btn" (click)="okay()">Okay</button>
    </div>
</mat-dialog-actions>