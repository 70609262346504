import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap, delay, bufferCount, reduce, toArray } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEventType } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { AuditEntry } from '../models/audit-entry';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { LoggerService } from './logger.service';
import { LoadDataJobLogEntry } from '../models/load-data-job-log-entry';
import { FileMetadata } from '../models/file-metadata';
import { ConfigurationEntry } from '../models/configuration-entry';
import { FileValidation } from '../models/file-validation';
import { ErrorLogResponse } from '../models/error-log-response';
import { HeaderValidationResponse } from '../models/header-validation-response';
import { FhirValidation } from '../models/fhir-validation';
import { MeasurementPeriod } from '../models/measurement-period';
import { RunConfigFilterOptions } from '../models/run-config-filter-options';
import { RunConfiguration } from '../models/run-configuration';
import { MeasureExecution } from '../models/measure-execution';
import { Measure } from '../models/measure';
import { Product } from '../models/product';
import { RunResult } from '../models/run-result';
import { DownloadGapListResponse } from '../models/download-gap-list-response';
import { RunResultSummary } from '../models/run-result-summary';
import { RunResultSummaryResponse } from '../models/run-result-summary-response';
import { MemberLevelResult } from '../models/member-level-result';
import { RunResultMemberLevelResponse } from '../models/member-level-response';
import { LogTypeEnum } from '../models/enums/log-type-enum';
import { ErrorLogCategoryResponse } from '../models/error-log-category-response';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  token: string = '';
  private urlBase = environment.api.base;
  private auditApi = environment.api.endpoints.audit;
  private jobLogApi = environment.api.endpoints.jobLog;
  private configurationApi = environment.api.endpoints.configuration;
  private filesApi = environment.api.endpoints.files;
  private validateApi = environment.api.endpoints.validate;
  private errorLogApi = environment.api.endpoints.errorLog;
  private fhirValidationApi = environment.api.endpoints.fhirValidation;
  private resourceApi = environment.api.endpoints.resource;
  private runConfigApi = environment.api.endpoints.runConfig;
  private membersApi = environment.api.endpoints.members;
  private measuresApi = environment.api.endpoints.measures;
  private productsApi = environment.api.endpoints.products;
  private fhirServerApi = environment.api.endpoints.fhirServier;
  private runResultsApi = environment.api.endpoints.runResults;
  private usersApi = environment.api.endpoints.users;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private logger: LoggerService) {
  }

  deleteFileContent(filetype: string): Observable<any | null> {
    const url = `${this.urlBase}${this.filesApi}/${filetype}`;
    return this.http.delete<any | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }


  uploadFile(fileContent: string, filetype: string): Observable<any | null> {
    const url = `${this.urlBase}${this.filesApi}/${filetype}`;
    return this.sendPage(url, fileContent);
  }


  sendPage(url: string, data: any): Observable<any | null> {
    const headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'authorization': `Bearer ${this.token}`,
        'content-length': data.length
      })
    };
    return this.http.post<any>(url, data, headers)
      .pipe(
        map(validationResult => validationResult),
        catchError(this.handleError)
      );
  }

  generateNcqaOutputForMeasure(measureRecordId: number, executeRunJobLogId: number) {
    const url = `${this.urlBase}ncqa/generate/${executeRunJobLogId}?measure_record_id=${measureRecordId}`;
    return this.http.get<any | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getMemberLevelOutputKeys(executeRunJobLogId: number): Observable<any | null> {
    const url = `${this.urlBase}run/results/${executeRunJobLogId}/member-level-output/keys`;
    return this.http.get<any | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getMemberLevelOutputFile(key: string): Observable<any | null> {
    let headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    const url = `${this.urlBase}run/results/download/member-level-output?key=${key}`;
    return this.http.get<any>(url, { headers, observe: 'body', responseType: 'blob' as 'json', })
      .pipe(
        catchError(this.handleError)
      )
      .pipe(
        map(res => {
          return {
            key: key,
            result: res
          }
        })
      )
  }

  downloadIdssFiles(runLogId: number, runConfigId: number): Observable<any | null> {
    const url = `${this.urlBase}${this.runResultsApi}/${runConfigId}/download/idss?executeRunJobLogId=${runLogId}`;
    return this.http.get<any | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  downloadGapList(runLogId: number): Observable<DownloadGapListResponse[] | null> {
    return new Observable((subscriber) => {
      const downloadGapListData: DownloadGapListResponse[] = [];
      let pageUrls: string[] = [];
      const url = `${this.urlBase}${this.runResultsApi}/${runLogId}/download/gap-list?page_no=1`;
      this.getDownloadPage(url).subscribe((downloadGapListResponse: DownloadGapListResponse | null) => {
        downloadGapListData.push(downloadGapListResponse!);
        if (downloadGapListResponse!.total_pages < 2) {
          subscriber.next(downloadGapListData);
          subscriber.complete();
        } else {
          for (let i = 2; i <= downloadGapListResponse!.total_pages; i++) {
            const pageUrl = `${this.urlBase}${this.runResultsApi}/${runLogId}/download/gap-list?page_no=${i}`;
            pageUrls.push(pageUrl);
          }
        }
        if (pageUrls.length > 0) {
          this.makeLimitedParallelCalls(pageUrls, this.getDownloadPage.bind(this)).subscribe(responses => {
            responses.forEach((downloadGapListResponse: DownloadGapListResponse) => {
              downloadGapListData.push(downloadGapListResponse);
            });
            subscriber.next(downloadGapListData);
            subscriber.complete();
          });
        } else {
          subscriber.next(downloadGapListData);
          subscriber.complete();
        }
      });
    });
  }

  getRunResults(runLogId: number): Observable<RunResult | null> {
    const url = `${this.urlBase}${this.runConfigApi}/results/${runLogId}`;
    return this.http.get<RunResult | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getRunResultSummary(runLogId: number, pageNumber: number, pageSize: number): Observable<RunResultSummaryResponse | null> {
    const url = `${this.urlBase}${this.runResultsApi}/${runLogId}?page=${pageNumber}&pageSize=${pageSize}`
    return this.http.get<RunResultSummaryResponse | null>(url);
  }

  getRunResultSummaryCSV(runLogId: number): Observable<any | null> {
    const url = `${this.urlBase}${this.runResultsApi}/${runLogId}/export`
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.get(url, options);
  }

  getMemberLevelData(runLogId: number, pageNumber: number, pageSize: number): Observable<RunResultMemberLevelResponse | null> {
    const url = `${this.urlBase}${this.runResultsApi}/member-level/${runLogId}?page=${pageNumber}&pageSize=${pageSize}`
    return this.http.get<RunResultMemberLevelResponse | null>(url);
  }


  saveOrganizationName(configuration: ConfigurationEntry): Observable<any | null> {
    const url = `${this.urlBase}${this.configurationApi}/organization-name`;
    return this.http.post<ConfigurationEntry | null>(url, configuration, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  saveOrganizationId(configuration: ConfigurationEntry): Observable<any | null> {
    const url = `${this.urlBase}${this.configurationApi}/organization-id`;
    return this.http.post<ConfigurationEntry | null>(url, configuration, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  saveOrganizationContractNumber(configuration: ConfigurationEntry): Observable<any | null> {
    const url = `${this.urlBase}${this.configurationApi}/contract-number`;
    return this.http.post<ConfigurationEntry | null>(url, configuration, {})
      .pipe(
        catchError(this.handleError)
      )
  }


  saveProduct(product: Product): Observable<Product | null> {
    const url = `${this.urlBase}${this.productsApi}`;
    return this.http.post<Product | null>(url, product, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  public makeLimitedParallelCalls(urls: any[], httpMethod: (url: any) => Observable<any>,
    maxConcurrency: number = environment.downloadThreads): Observable<any> {
    return from(urls).pipe(mergeMap(request => httpMethod(request), maxConcurrency), toArray());
  }

  downloadErrorLogs(loadDataJobLogId: number | null, logType: string): Observable<ErrorLogResponse[] | null> {
    return new Observable((subscriber) => {
      const errorLogData: ErrorLogResponse[] = [];
      let pageUrls: string[] = [];
      const url = `${this.urlBase}${this.errorLogApi}/${loadDataJobLogId}/${logType}/download?page_no=1`;
      this.getDownloadPage(url).subscribe((errorLogResponse: ErrorLogResponse | null) => {
        errorLogData.push(errorLogResponse!);
        if (errorLogResponse!.total_pages < 2) {
          subscriber.next(errorLogData);
          subscriber.complete();
        } else {
          for (let i = 2; i <= errorLogResponse!.total_pages; i++) {
            const pageUrl = `${this.urlBase}${this.errorLogApi}/${loadDataJobLogId}/${logType}/download?page_no=${i}`;
            pageUrls.push(pageUrl);
          }
        }
        if (pageUrls.length > 0) {
          this.makeLimitedParallelCalls(pageUrls, this.getDownloadPage.bind(this)).subscribe(responses => {
            responses.forEach((errorLogResponsePage: ErrorLogResponse) => {
              errorLogData.push(errorLogResponsePage);
            });
            subscriber.next(errorLogData);
            subscriber.complete();
          });
        } else {
          subscriber.next(errorLogData);
          subscriber.complete();
        }
      });
    });
  }

  public getDownloadPage(url: string): Observable<any | null> {
    return this.http.get<any | null>(url)
      .pipe(
        catchError(this.handleError));
  }

  postBundle(bundle: any, measure: Measure): Observable<any | null> {
    let formData = new FormData();
    formData.append("bundle", new Blob([bundle], {
      type: "application/json"
    }));
    return this.http.post(`${this.urlBase}${this.fhirServerApi}/transaction?measureTitle=${measure.title}&measureVersion=${measure.version}`, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllProductLines(): Observable<any[] | null> {
    const url = `${this.urlBase}${this.productsApi}/product-lines`;
    return this.http.get<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllProducts(): Observable<any[] | null> {
    const url = `${this.urlBase}${this.productsApi}`;
    return this.http.get<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  saveMeasure(measure: Measure): Observable<Measure | null> {
    measure.bundle = '';
    const url = `${this.urlBase}${this.measuresApi}`;
    return this.http.post<Measure | null>(url, measure, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getRunMeasureStatus(runConfigId: number, executeRunJobLogId: number): Observable<MeasureExecution[] | null> {
    if (executeRunJobLogId) {
      const url = `${this.urlBase}${this.runConfigApi}/${runConfigId}/measure-execution/${executeRunJobLogId}`;
      return this.http.get<MeasureExecution[] | null>(url, {})
        .pipe(
          catchError(this.handleError)
        )
    } else {
      return of([]);
    }
  }

  cancelRun(id: number): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/${id}/cancel`;
    return this.http.put<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }


  executeRun(id: number): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/${id}/execute`;
    return this.http.put<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllMeasures(): Observable<Measure[] | null> {
    const url = `${this.urlBase}${this.measuresApi}`;
    return this.http.get<Measure[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getRunConfigurations(): Observable<RunConfiguration[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/list`;
    return this.http.get<RunConfiguration[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  saveRunConfig(runConfig: RunConfiguration): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}`;
    return this.http.post<any[] | null>(url, runConfig, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getExecuteRunTable(id: number): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/execute-table/${id}`;
    return this.http.get<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getRunConfigurationTable(id: number): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/table/${id}`;
    return this.http.get<any[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getFilteredRunConfigurationTable(filters: RunConfigFilterOptions): Observable<any[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/table`;
    return this.http.post<any[] | null>(url, filters, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getRunConfigFilterOptions(): Observable<RunConfigFilterOptions | null> {
    const url = `${this.urlBase}${this.runConfigApi}/filter-options`;
    return this.http.get<RunConfigFilterOptions | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  updateMemberData(): Observable<any> {
    const url = `${this.urlBase}${this.membersApi}/update`;
    return this.http.post<any>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  startLoadingBundles(jobId: number): Observable<any> {
    const url = `${this.urlBase}${this.configurationApi}/start_loading_bundles/${jobId}`;
    return this.http.put<any>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getMeasurementPeriodOptions(): Observable<MeasurementPeriod[] | null> {
    const url = `${this.urlBase}${this.runConfigApi}/measurement-period-options`;
    return this.http.get<MeasurementPeriod[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }


  loadTriggerResource(loadDataJobLogId: number | null): Observable<any> {
    const url = `${this.urlBase}${this.resourceApi}/trigger/${loadDataJobLogId}`;
    return this.http.post<any>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getFhirValidations(): Observable<FhirValidation[] | null> {
    const url = `${this.urlBase}${this.fhirValidationApi}/entries`;
    return this.http.get<FhirValidation[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getErrorLogs(loadDataJobLogId: number | null, logType: string): Observable<ErrorLogResponse | null> {
    const url = `${this.urlBase}${this.errorLogApi}/${loadDataJobLogId}/${logType}`;
    return this.http.get<ErrorLogResponse | null>(url, {})
      .pipe(
        catchError(this.handleError)
      );
  }
  
  getErrorLogCategories(jobLogId: number | null, category: string): Observable<ErrorLogCategoryResponse | null> {
    const url = `${this.urlBase}${this.errorLogApi}/${jobLogId}?category=${category}`;
    return this.http.get<ErrorLogCategoryResponse>(url).pipe(catchError(this.handleError));  }

  validateHeader(jobId: number): Observable<HeaderValidationResponse[] | null> {
    const url = `${this.urlBase}${this.validateApi}/header/${jobId}`;
    return this.http.post<HeaderValidationResponse[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getFileValidations(): Observable<FileValidation[] | null> {
    const url = `${this.urlBase}${this.filesApi}/file_validation`;
    return this.http.get<FileValidation[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getIncomingFiles(): Observable<FileMetadata[] | null> {
    const url = `${this.urlBase}${this.filesApi}/incoming`;
    return this.http.get<FileMetadata[] | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  createLoadDataJobLogEntry(): Observable<any> {
    const url = `${this.urlBase}${this.jobLogApi}/create`;
    return this.http.put<any>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }


  newLoad(jobId: number): Observable<any> {
    const url = `${this.urlBase}${this.configurationApi}/new_load/${jobId}`;
    return this.http.put<any>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getConfiguration(): Observable<ConfigurationEntry | null> {
    const url = `${this.urlBase}${this.configurationApi}`;
    return this.http.get<ConfigurationEntry | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getUsers(): Observable<User[] | null> {
    const url = `${this.urlBase}${this.usersApi}`;
    return this.http.get<User[]>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  cancel(): Observable<ConfigurationEntry | null> {
    const url = `${this.urlBase}${this.configurationApi}/cancel`;
    return this.http.put<ConfigurationEntry | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  getLoadDataJobLogEntry(jobId: number): Observable<LoadDataJobLogEntry | null> {
    const url = `${this.urlBase}${this.jobLogApi}/${jobId}`;
    return this.http.get<LoadDataJobLogEntry | null>(url, {})
      .pipe(
        catchError(this.handleError)
      )
  }

  sendAuditEntry(auditEntry: AuditEntry): Observable<AuditEntry | null> {
    const url = `${this.urlBase}${this.auditApi}`;
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(url, auditEntry, options)
      .pipe(
        tap(_data => this.logger.log('sent audit data')),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.logger.error(`An error occurred: ${error.error.message}`, true);
    } else if (!error.status.toString().startsWith('2')) {
      if (this.logger) {
        this.logger.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      } else {
        throw new Error(`Backend returned code ${error.status}, body was: ${error.error}`);
      }
    }
    return of(null);
  }

  updateUsers(users: User[]): Observable<any> {
    return this.http.post<any>(`${this.urlBase}${this.usersApi}`, users)
      .pipe(
        catchError(this.handleError)
      );
  }

}
