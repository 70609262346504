import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { environment } from '../../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MsalInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: `${environment.auth.clientId}`,
            authority: `${environment.auth.instance}`,
            knownAuthorities: [`${environment.auth.instance}`],
            redirectUri: `${window.location.origin}/dashboard`,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE,
        }
    });
}

export function MsalGuardConfFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [`${environment.auth.clientId}/.default`],
        },
        loginFailedRoute: '/login',
    } as MsalGuardConfiguration;
}

export function MsalInterceptorConfFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: null!
    } as MsalInterceptorConfiguration
}
