<div class="container">
    <div class="content-validation-container">
        <div>
            <p id="loading-title" class="title">
                <span [hidden]="shouldHideValidatingMessage()">
                    Validating Content ... 
                </span>
                <span [hidden]="!contentValidationService.criticalError">
                    Content Validation Critical Error
                </span>
                <span *ngIf="(contentValidationService.contentValidationError && fileService.allContentValidated()) && !contentValidationService.criticalError">
                    Content Validation Non-Critical Error
                </span>
                <span *ngIf="shouldShowValidatedMessage()">
                    Validated Content
                </span>
            </p>
        </div>
        <div class="table-container">
            <mat-table #table [dataSource]="dataSource">

                <!-- File Name Column -->
                <ng-container matColumnDef="file_name">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Filename
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell"> 
                        <img [hidden]="!shouldShowAlert(cell.fileName)" src="../../../../../assets/images/alert-icon-red.svg" class="alert-icon">
                        <img [hidden]="!shouldShowNonCritical(cell.fileName)" src="../../../../../assets/images/non-critical.svg" class="alert-icon">
                        {{cell.fileName  | titlecase}}
                    </mat-cell>
                </ng-container>

                <!-- Last Modified Date Column -->
                <ng-container matColumnDef="last_modified">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell">
                        Last Modified Date </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell"> {{cell.lastModified | date: 'MM/dd/yyyy hh:mm a':dateService.getFormattedTimeZone()}}
                    </mat-cell>
                </ng-container>

                <!-- Total Rows Column -->
                <ng-container matColumnDef="total_rows">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Total Rows
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                        {{cell.totalRows}} </mat-cell>
                </ng-container>

                <!-- Missing Columns Column -->
                <ng-container matColumnDef="missing_columns">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Missing Columns
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                        {{cell.missingColumns}}
                    </mat-cell>
                </ng-container>

                <!-- Missing Required Field Column -->
                <ng-container matColumnDef="missing_required_fields">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell last"> Missing Required Fields
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell last">
                        {{cell.missingRequiredFieldsCount}}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
        </div>
        <div>
            <button mat-button id="validate-fhir-bundles-btn" (click)="validateFhirBundles()" [disabled]="shouldDisableValidateFhirBundles()">
                <span>Validate FHIR Bundles</span>
            </button>
        </div>
    </div>
</div>