import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrameworkModule } from './framework/framework.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { GlobalErrorHandler } from './core/global-error-handler';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutDialogComponent } from './shared/dialogs/timeout-dialog/timeout-dialog.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { EditRunNameDialogComponent } from './shared/dialogs/edit-run-name-dialog/edit-run-name-dialog.component';
import { MeasureRunSnackbarComponent } from './shared/snackbars/measure-run-snackbar/measure-run-snackbar.component';
import { UploadMeasurePackageDialogComponent } from './shared/dialogs/upload-measure-package-dialog/upload-measure-package-dialog.component';
import { EditMeasurePackageDialogComponent } from './shared/dialogs/edit-measure-package-dialog/edit-measure-package-dialog.component';
import { EditProductDialogComponent } from './shared/dialogs/edit-product-dialog/edit-product-dialog.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import {AuthModule} from "../app/core/auth/auth.module"
import { TimeoutService } from './core/timeout.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DialogComponent,
        EditRunNameDialogComponent,
        UploadMeasurePackageDialogComponent,
        EditMeasurePackageDialogComponent,
        EditProductDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        SharedModule,
        FrameworkModule,
        AppRoutingModule,
        HttpClientXsrfModule,
        AuthModule,
        NgbModule
    ],
    providers: [
        TimeoutService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
