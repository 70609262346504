<div class="container">
    <div class="load-bundles-container">
        <div>
            <p id="loading-title" class="title">
                <span [hidden]="!loadingService.loading">
                    Loading data into FHIR server
                </span>
                <span *ngIf="!loadingService.loading && configurationService.failedBundles === 0 && configurationService.applicationState !== 'failed'">
                    <img src="../../../../../assets/images/check-purple.svg" class="alert-icon">
                    Data load complete without errors
                </span>
                <span *ngIf="configurationService.failedBundles > 0 && configurationService.applicationState !== 'failed' && !loadingService.loading" >
                    <img src="../../../../../assets/images/non-critical-purple.svg" class="alert-icon">
                    Data load complete with errors
                </span>
                <span *ngIf="configurationService.applicationState === 'failed'">
                    <img src="../../../../../assets/images/alert-purple.svg" class="alert-icon">
                    Data load failed
                </span>
                <mat-progress-bar mode="determinate" id="progress-bar" [hidden]="configurationService.applicationState === 'failed' || !loadingService.loading"
                value="{{((configurationService.loadedBundles + configurationService.failedBundles) / configurationService.bundlesToLoad) * 100}}"></mat-progress-bar>
            </p>
        </div>
    </div>
</div>