<div class="config-header mat-elevation-z2">
    <section class="section">
        <div class="run-name">
            <mat-label class="label">Run Name</mat-label>
            <mat-label>{{runConfig.runName}}</mat-label>
        </div>
    </section>
    <section class="section">
        <div class="measurement-period">
            <mat-label class="label">Measurement Period</mat-label>
            <span class="date"> {{dateService.convertUTC(runConfig.startDate!)| date: 'MM/dd/yyyy' }} - {{dateService.convertUTC(runConfig.endDate!) | date:
                'MM/dd/yyyy'}}
            </span>
        </div>
    </section>
    <section class="section-2">
        <span [hidden]="!running" class="running-measures">
            <svg class="icon" width="16" height="22" viewBox="0 0 16 22" fill="none" [class.loading]="running"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                    fill="#61267A" />
            </svg>
            Running measures...</span>
        <button mat-stroked-button id="run-btn" (click)="toggleRunMeasures()" [disabled]="(!userService.isAdministrator() && !userService.isDeveloper())
        || shouldDisableRunMeasuresButton()">
            <span [hidden]="running">Run Measures
                <span class="inline-spinner" *ngIf="canceling">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </span>
            </span>
            <span [hidden]="!running">Cancel
            </span>
        </button>
    </section>
</div>
<div class="spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div class="configuration-table" *ngIf="!loading">
    <div class="sticky-header-windows-fix">
        <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

            <!-- Line of Business Column -->
            <ng-container matColumnDef="lineOfBusiness">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">
                    <svg [class.invisible]="!running" class="icon" width="16" height="22" viewBox="0 0 16 22"
                        fill="none" [class.loading]="running" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#212121" />
                    </svg>
                    Line of Business
                </mat-header-cell>
                <mat-cell *matCellDef="let cell" class="mat-standard-cell"> 
                    {{cell.lineOfBusiness}}
                </mat-cell>
            </ng-container>

            <!-- Measure Column -->
            <ng-container matColumnDef="measureName">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell"> Display Name
                </mat-header-cell>
                <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                    {{cell.measureName}}
                </mat-cell>
            </ng-container>

            <!-- Product Line Column -->
            <ng-container matColumnDef="productLine">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">
                    Product Line </mat-header-cell>
                <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                    {{cell.productLine}}
                </mat-cell>
            </ng-container>

            <!-- Spacer Column -->
            <ng-container matColumnDef="spacer">
                <mat-header-cell *matHeaderCellDef class="mat-header-cell"></mat-header-cell>
                <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
    </div>
</div>
