import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/core/data.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { Measure } from 'src/app/models/measure';

@Component({
  selector: 'app-generate-ncqa-output-dialog',
  templateUrl: './generate-ncqa-output-dialog.component.html',
  styleUrls: ['./generate-ncqa-output-dialog.component.scss']
})
export class GenerateNcqaOutputDialogComponent implements OnInit {

  public selectedMeasure!: number;
  public measures: Measure[] | null = [];
  public loadingMeasures = false;
  public executeRunJobLogId!: number;

  constructor(public dialogRef: MatDialogRef<GenerateNcqaOutputDialogComponent>,
    public dataService: DataService, public snackbarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.loadingMeasures = true;
    this.dataService.getAllMeasures().subscribe(measures => {
      this.measures = measures;
      this.loadingMeasures = false;
    }, () => {
      this.loadingMeasures = false;
    });
  }

  generate() {
    this.dataService.generateNcqaOutputForMeasure(this.selectedMeasure, this.executeRunJobLogId).subscribe();
    this.snackbarService.showInfoBar("Generation started. This may take a while. When generation is finished the output will be uploaded to the S3 folder 'ncqa_output/'")
    this.dialogRef.close(true);
  }


  closeDialog(result: boolean) {
    this.dialogRef.close(result);
  }

}
