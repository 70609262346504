import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
  name: 'fhirDate'
})
export class FhirDatePipe implements PipeTransform {

  transform(value: string): any {
    const datepipe: DatePipe = new DatePipe('en-US')
    if(value){
      if (Date.parse(value)){
       return datepipe.transform(value, 'MM/dd/yyyy');     
      }
      return '';
    }
    return '';
  }

}


