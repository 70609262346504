import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DialogService } from 'src/app/core/dialog.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';
@Component({
  selector: 'app-wayfinding-path',
  templateUrl: './wayfinding-path.component.html',
  styleUrls: ['./wayfinding-path.component.scss']
})
export class WayfindingPathComponent implements OnInit {
  @Input() errorLogCustomClass!: {};
  public get WayfindingStageEnum(): typeof WayfindingStageEnum {
    return WayfindingStageEnum;
  }

  constructor(public configurationService: ConfigurationService,
    public loadingService: LoadingService,
    public dialogService: DialogService,
    public jobLogService: JobLogService,
    public wayfindingService: WayfindingService,
    public userService: UserService) { }

  ngOnInit(): void {
  }


}
