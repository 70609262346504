<app-header></app-header>
<mat-sidenav-container class="container">
    <mat-sidenav #sidenav mode="side" opened="{{componentService.navOpen}}" class="sidenav" mode="side">
        <mat-nav-list [class.remove-selection]="componentService.shouldDisplayPopover()">
            <mat-list-item (click)="componentService.selectedComponent = componentService.ComponentEnum.LOAD" class="nav-list-item"
                [class.selected-nav]="componentService.selectedComponent === componentService.ComponentEnum.LOAD">
                <img [hidden]="configurationService.showWayfinding || shouldDisplaySpinner()" id="loadIcon" src="assets/icons/load-icon.svg" class="alert-icon">
                <img id="loadIcon" src="../../../assets/images/{{wayfindingService.icon}}.svg" class="alert-icon" [hidden]="shouldDisplaySpinner() || !configurationService.showWayfinding">
                <span [hidden]="!shouldDisplaySpinner()" class="refresh" [class.loading]="shouldDisplaySpinner()">
                    <svg class="icon"  width="22" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z" fill="white"/>
                    </svg>
                </span>
                <div class="sidenav-item">
                    <div> <span class="nav-title">Load Data</span>
                    </div>
                    <div>
                        <span class="nav-subtitle">Last loaded on</span>

                    </div>
                    <span class="nav-subtitle">{{jobLogService.loadFinished}}</span>
                </div>
            </mat-list-item>

            <mat-list-item (click)="componentService.selectedComponent = componentService.ComponentEnum.EXECUTE" class="nav-list-item"
                [class.selected-nav]="componentService.selectedComponent === componentService.ComponentEnum.EXECUTE">
                <img id="executeIcon" src="assets/icons/execute-icon.svg" class="nav-icon" [hidden]="configurationService.executingRunJobLogId">
                <span [hidden]="!configurationService.executingRunJobLogId" class="refresh" [class.loading]="configurationService.executingRunJobLogId">
                    <svg class="icon"  width="22" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z" fill="white"/>
                    </svg>
                </span>
                <div class="sidenav-item">
                    <span class="nav-title">Set Up & <br>Execute Runs</span>
                </div>
            </mat-list-item>

            <mat-list-item (click)="componentService.selectedComponent = componentService.ComponentEnum.VIEW" class="nav-list-item"
                [class.selected-nav]="componentService.selectedComponent === componentService.ComponentEnum.VIEW">
                <img id="viewIcon" src="assets/icons/view-icon.svg" class="nav-icon">
                <div class="sidenav-item">
                    <span class="nav-title">View Run <br>Results</span>
                </div>
            </mat-list-item>
        </mat-nav-list>
        <button mat-button id="collapse-btn" (click)=componentService.toggleNav()><span id="collapseIcon"
                class="material-icons">keyboard_arrow_left</span>
        </button>
    </mat-sidenav>

    <mat-sidenav-content class="main-content">
        <ng-container [ngSwitch]="true" *ngIf="componentService.shouldDisplayPopover()">
            <div *ngSwitchCase="componentService.displayManageMeasurePackageComponent">
                <app-manage-measure-package></app-manage-measure-package>
            </div>
            <div *ngSwitchCase="componentService.displayMangeOrganizationInformationComponent">
                <app-manage-organization-information></app-manage-organization-information>
            </div>
            <div *ngSwitchCase="componentService.displayManageUsersComponent">
              <app-manage-users></app-manage-users>
          </div>
        </ng-container>
        <ng-container [ngSwitch]="true" *ngIf="!componentService.shouldDisplayPopover()">
            <div *ngSwitchCase="componentService.selectedComponent === componentService.ComponentEnum.LOAD">
                <app-load></app-load>
            </div>
            <div *ngSwitchCase="componentService.selectedComponent === componentService.ComponentEnum.EXECUTE">
                <app-execute></app-execute>
            </div>
            <div *ngSwitchCase="componentService.selectedComponent === componentService.ComponentEnum.VIEW">
                <app-view></app-view>
            </div>
            <div *ngSwitchDefault>
                <app-load></app-load>
            </div>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>
