<div id="manage-user-dialog-container">
  <h2 class="title">{{title}}</h2>
  <div>
      <form name="manageUsersForm" [formGroup]="manageUsersForm" novalidate class="form-container">
          <div class="row">
              <mat-form-field class="full-width first-name" [ngClass]="{'mat-form-field-readonly':!user.active}">
                  <mat-label>First Name</mat-label>
                  <input formControlName="first-name" [readonly]="!user.active" [class.disabled-text]="!user.active"
                      id="first-name" matInput [(ngModel)]="user.first_name" placeholder="Rachel">
                  <mat-error *ngIf="!isControlValid('first-name')">
                      * Required
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width last-name" [ngClass]="{'mat-form-field-readonly':!user.active}">
                  <mat-label>Last Name</mat-label>
                  <input formControlName="last-name" [readonly]="!user.active" [class.disabled-text]="!user.active"
                      id="last-name" matInput [(ngModel)]="user.last_name" placeholder="Green">
                  <mat-error *ngIf="!isControlValid('last-name')">
                      * Required
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width email" [ngClass]="{'mat-form-field-readonly':!user.active}">
                  <mat-label>Email</mat-label>
                  <input formControlName="email-id" [readonly]="!user.active" [class.disabled-text]="!user.active"
                      id="email-id" matInput [(ngModel)]="user.email_id" placeholder="greenr@astrata.co">
                  <mat-error *ngIf="!isControlValid('email-id')">
                      {{getEmailErrorMessage()}}
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width network-id" [ngClass]="{'mat-form-field-readonly':!user.active || !user.editable}">
                  <mat-label>Network ID</mat-label>
                  <input formControlName="network-id" [readonly]="!user.editable || !user.active"
                      [class.disabled-text]="!user.editable || !user.active" id="network-id" matInput [(ngModel)]="user.user_id"
                      placeholder="greenr">
                  <mat-error *ngIf="!isControlValid('network-id')">
                      {{getUserIdErrorMessage()}}
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width permissions" [ngClass]="{'mat-form-field-readonly':!user.active}">
                  <mat-select formControlName="role"  [disabled]="!user.active"
                      [class.disabled-text]="!user.active" id="user-role" [(ngModel)]=user.role
                      placeholder="Permission Level">
                      <mat-option value="Administrator">Administrator</mat-option>
                      <mat-option value="Approver">Approver</mat-option>
                      <mat-option value="View Only">Viewer</mat-option>
                      <mat-option value="Developer" [hidden]="!userService.isDeveloper()">Developer</mat-option>
                  </mat-select>
              </mat-form-field>
              <div class="checkbox-container">
                  <mat-label class="checkbox-label">Active</mat-label>
                  <mat-checkbox [ngModelOptions]="{standalone: true}" id="active" class="active" [(ngModel)]="user.active"
                      (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(user) : null"
                      [checked]="isChecked(user)">
                  </mat-checkbox>
              </div>
          </div>
      </form>
  </div>
  <div class="footer row">
    <button mat-stroked-button id="cancel-btn" (click)="dialogRef.close()">
        <span class="btn-txt">Cancel</span></button>
    <button mat-stroked-button id="save-btn" (click)="save()" [disabled]="!getEnableSave()">
        <span class="btn-txt">Save</span></button>
  </div>
</div>
