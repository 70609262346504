<div class="container">
    <div class="data-staging-area-container">
        <div class="row">
            <div>
                <p id="data-staging-title" class="title">
                    Data Staging Area
                </p>
            </div>
            <div class="refresh-container">
                <button mat-stroked-button [class.loading]="loadingService.shouldShowLoadingElements()" class="refresh" (click)="refresh()">
                    <svg class="icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#7564A0" />
                    </svg>
                    <span id="refresh-title" class="refresh-txt">
                        <span [hidden]="loadingService.shouldShowLoadingElements()">
                            Refresh
                        </span>
                        <span [hidden]="!loadingService.shouldShowLoadingElements()">
                            Refreshing ...
                        </span>
                    </span>
                </button>
            </div>
        </div>
        <div id="upload-title" class="uploads">
            <div>
                <span class="uploads-title" [hidden]="fileService.incomingFiles.length > 0">
                    Uploads
                </span>
                <span class="uploads-title" [hidden]="fileService.incomingFiles.length === 0">
                    Uploaded
                </span>
            </div>

            <div class="uploaded-ghost">
                <div [hidden]="!loadingService.shouldShowLoadingElements()" 
                    class="placeholder animated">
                </div>
                <div [hidden]="!loadingService.shouldShowLoadingElements()" 
                    class="placeholder animated">
                </div>
                <div [hidden]="!loadingService.shouldShowLoadingElements()"
                    class="placeholder animated">
                </div>
            </div>
            <div [hidden]="loadingService.shouldShowLoadingElements()" class="uploaded-files-list">
                <div class="uploaded-files"
                    *ngFor="let file of fileService.incomingFiles">
                    <p><span [class.invalid-file]="!file.valid" class="file-name">{{file.fileName}}</span> <span
                            class="last-modified">{{file.lastModified | date: 'MM/dd/yyyy hh:mm
                            a'}}</span></p>
                </div>
            </div>
        </div>
        <div class="expected-files">
            <div id="expected-title" class="expected-title">
                <span>
                    Expected files
                </span>
            </div>

            <div class="ghost">
                <div [hidden]="!loadingService.shouldShowLoadingElements()"
                    class="placeholder animated">
                </div>
                <div [hidden]="!loadingService.shouldShowLoadingElements()"
                    class="placeholder animated">
                </div>
                <div [hidden]="!loadingService.shouldShowLoadingElements()"
                    class="placeholder animated">
                </div>
            </div>
            <div class="expected-files-list" [class.hidden]="loadingService.shouldShowLoadingElements()" [hidden]="loadingService.shouldShowLoadingElements()">
                <div *ngFor="let file of fileService.fileValidations">
                    <div *ngIf="file.isRequired && !configurationService.ncqaMode">
                        <span>
                            <div class="inline-row">
                                <img [hidden]="!file.validated" src="../../../../../assets/images/checkmark.svg" class="checkmark">
                                <p id="{{file.fileName}}">{{file.fileName | titlecase}}<span>.{{file.fileExtension}}</span></p>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button mat-button id="start-content-validation-btn" (click)="startContentValidation()" class="start-validation-btn" 
            [disabled]="shouldDisableValidation()">
                <span>Start Content Validation</span>
            </button>
        </div>
    </div>
</div>