<h1 md-dialog-title class="title">Generate NCQA Output</h1>
<div md-dialog-actions>
    <mat-form-field class=full-width>
        <mat-label>Measure</mat-label>
        <mat-select id="measureSelect" [(value)]=selectedMeasure [disabled]="loadingMeasures">
            <mat-option *ngFor="let measure of measures" [value]="measure.id">
                {{measure.displayName}}
            </mat-option>
        </mat-select>
        <mat-spinner *ngIf="loadingMeasures" [diameter]="20"></mat-spinner>
    </mat-form-field>
    <button [disabled]="!selectedMeasure" mat-stroked-button class="generate-btn"
        (click)="generate()">
        <span class="btn-txt">Generate</span></button>
    <button mat-stroked-button class="cancel-btn" (click)="closeDialog(false)">
        <span class="btn-txt">Cancel</span></button>
</div>