<mat-toolbar id="header" class="header">
    <button mat-button class="button-icon" (click)="componentService.hidePopover()"><img id="backIcon"
            src="assets/images/arrow_back_grey.svg"></button>
    <span id="title">
        Manage Organization Information
    </span>
    <button mat-stroked-button id="add-btn" (click)="addNewProduct()"
    [hidden]="readOnly">
        <span>Add New Products</span>
    </button>
</mat-toolbar>

<div class="spinner" *ngIf="loading" id="spinner">
    <mat-spinner></mat-spinner>
</div>

<div class="container" *ngIf="!loading">
    <div id=organization-info class="organization-info">
        <form [formGroup]="form" novalidate class="form" *ngIf="!loading">

            <mat-form-field class="mat-form-field">
                <mat-label>Organization Name</mat-label>
                <input matInput #message1 maxlength="128" required formControlName="organizationName"
                    id="organization-name" [readonly]="readOnly"
                    (focusout)="saveOrganizationName()">
                <mat-hint align="end">{{message1.value.length}} / 128</mat-hint>
                <mat-error id='mat-error-1'
                    *ngIf="form.controls['organizationName'].errors?.required || form.controls['organizationName'].errors?.pattern">
                    Organization name is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="mat-form-field">
                <mat-label>Organization ID</mat-label>
                <input matInput [disabled]="loading" #message2 maxlength="64" required formControlName="organizationId"
                    id="organization-id" [readonly]="readOnly"
                    (focusout)="saveOrganizationId()">
                <mat-hint align="end">{{message2.value.length}} / 64</mat-hint>
                <mat-error id='mat-error-2'
                    *ngIf="form.controls['organizationId'].errors?.required || form.controls['organizationId'].errors?.pattern">
                    Organization ID is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="mat-form-field">
                <mat-label>CMS Contract Number</mat-label>
                <input matInput [disabled]="loading" #message3 maxlength="64" required
                    formControlName="cmsContractNumber" id="cms-contract-number"
                    [readonly]="readOnly"
                    (focusout)="saveOrganizationContractNumber()">
                <mat-hint align="end">{{message3.value.length}} / 64</mat-hint>
                <mat-error id='mat-error-3'
                    *ngIf="form.controls['cmsContractNumber'].errors?.required || form.controls['cmsContractNumber'].errors?.pattern">
                    Contract number is required.
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <div id=supported-products class="supported-products">
        <h1 id="supported-products-title">
            Supported Products
        </h1>
        <div class="table-container" id="org-table-container" *ngIf="!loading">
            <mat-table #table [dataSource]="dataSource" [@listAnimation]='dataSource && dataSource.data.length' matSort (matSortChange)="sortData($event)">

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell">
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                        <button [hidden]="readOnly" mat-button class="button-icon-row" (click)="edit(cell)"
                            [disabled]="cell.inAnActiveRun" [class.not-allowed]="cell.inAnActiveRun"><img
                                id="editIcon" src="assets/images/edit.svg"
                                [matTooltip]="cell.inAnActiveRun ? 'Action is disabled while product is running' : ''"></button>
                        <button [hidden]="cell.deleted || readOnly" mat-button class="button-icon" (click)="toggleActive(cell)"
                            [disabled]="cell.inAnActiveRun" [class.not-allowed]="cell.inAnActiveRun"><img
                                id="visibleIcon" src="assets/images/visibility_on.svg"
                                [matTooltip]="cell.inAnActiveRun ? 'Action is disabled while product is running' : ''"></button>
                        <button [hidden]="!cell.deleted || readOnly" mat-button class="button-icon"
                            (click)="toggleActive(cell)"><img id="visibleOffIcon"
                                src="assets/images/visibility_off.svg"></button>
                    </mat-cell>
                </ng-container>

                <!-- Product Column -->
                <ng-container matColumnDef="displayName">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header> Product
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                        {{cell.displayName}}
                    </mat-cell>
                </ng-container>

                <!-- Product Line Column -->
                <ng-container matColumnDef="productLineName">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header>
                        Product Line </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                        {{cell.productLineName}}
                    </mat-cell>
                </ng-container>

                <!-- Auditable Column -->
                <ng-container matColumnDef="auditable">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header>
                        Auditable
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell"
                        [class.innactive-checkbox]="cell.deleted">
                        <mat-checkbox [hidden]="!cell.auditable" id="auditable-checkbox" class="auditable-checkbox" [disabled]="true"
                            [checked]="cell.auditable" [class.innactive-checkbox]="cell.deleted">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
        </div>
    </div>
</div>