import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RunConfigFilterOptions } from '../models/run-config-filter-options';
import { RunConfiguration } from '../models/run-configuration';
import { DataService } from './data.service';
import { LoadingService } from './loading.service';
import { PRODUCT_PRODUCT_LINE_SEPARATOR } from '../models/constants';
@Injectable({
  providedIn: 'root'
})
export class RunConfigService {
  configTableLoadedEventEmitter = new Subject();
  configTableLoadedAnnounced = this.configTableLoadedEventEmitter.asObservable();

  configListLoadedEventEmitter = new Subject();
  configListLoadedAnnounced = this.configListLoadedEventEmitter.asObservable();

  constructor(public dataService: DataService, public loadingService: LoadingService) {
    this.loadFilterOptions();
    this.loadRunConfigurations();
  }
  public separator = PRODUCT_PRODUCT_LINE_SEPARATOR;
  public showCreateConfiguration: boolean = false;
  public executeRun: boolean = false;
  public showRunResults: boolean = false;
  public runLogId!: number;
  public filterOptions: RunConfigFilterOptions = new RunConfigFilterOptions();
  public selectedFilterOptions: RunConfigFilterOptions = new RunConfigFilterOptions();
  public runConfigTableRows: any[] = [];
  public displayedColumns: string[] = [];
  public productGroups: Map<string, Object[]> = new Map();
  public currentColClass = '0';
  public currentGroup = 'group';
  public currentKeyClass = '0';
  public measureIdMap: Map<string, number> = new Map();
  public productIdMap: Map<string, number> = new Map();
  public productLineIdMap: Map<string, number> = new Map();
  public runConfigurations: RunConfiguration[] = [];
  public savedRunConfigNames: Set<String> = new Set();
  public runConfig: RunConfiguration = new RunConfiguration();
  public editing = false;
  public displayedProductLineColumns: any[] = [];


  loadRunConfigurations(): void {
    this.savedRunConfigNames = new Set();
    this.dataService.getRunConfigurations().subscribe((runConfigurations: RunConfiguration[] | null) => {
      if (runConfigurations) {
        this.runConfigurations = runConfigurations;
        this.runConfigurations.forEach(runConfiguration => {
          this.savedRunConfigNames.add(runConfiguration.runName);
        });
      }
      this.configListLoadedEventEmitter.next();
    });
  }

  loadFilterOptions(): void {
    this.dataService.getRunConfigFilterOptions().subscribe((filterOptions: RunConfigFilterOptions | null) => {
      if (filterOptions !== null) {
        this.filterOptions = filterOptions;
        this.filterOptions.measures.forEach(measure => {
          this.measureIdMap.set(measure.displayName, measure.recordId);
        });
        this.filterOptions.products.forEach(productProductLine => {
          let productLine = productProductLine.displayName.split('/')[0];
          let product = productProductLine.displayName.split('/')[1];
          this.productLineIdMap.set(productLine, productProductLine.recordId.split('/')[0]);
          this.productIdMap.set(product + this.separator + productLine, productProductLine.recordId.split('/')[1]);
        });
      }
    });
  }

  clearTableData(): void {
    this.displayedColumns = [];
    this.productGroups = new Map();
    this.currentColClass = '0';
    this.currentGroup = 'group';
    this.currentKeyClass = '0';
    this.runConfigTableRows = [];
  }

  loadRunConfigTable(): void {
    this.loadingService.loadingConfig = true;
    let loadRunConfigMethod!: Observable<any>;
    if (this.editing) {
      loadRunConfigMethod = this.dataService.getRunConfigurationTable(this.runConfig.id);
      this.editing = false;
    } else {
      this.unCheckHiddenCheckboxes();
      this.clearTableData();
      loadRunConfigMethod = this.dataService.getFilteredRunConfigurationTable(this.selectedFilterOptions);
    }
    loadRunConfigMethod.subscribe((response) => {
      if (response && response.length > 0) {
        this.runConfigTableRows = response;
        this.buildDisplayColumns();
      }
      this.configTableLoadedEventEmitter.next();
      this.loadingService.loadingConfig = false;
    }, () => {
      this.loadingService.loadingConfig = false;
    });
  }

  buildDisplayColumns(): void {
    Object.keys(this.runConfigTableRows[0]).forEach(name => {
      if (name === 'Products') {
        Object.keys(this.runConfigTableRows[0][name]).forEach(productKey => {
          this.productGroups.set(productKey, this.runConfigTableRows[0][name][productKey]);
        });
      } else {
        this.displayedColumns.push(name);
      }
    });
    this.productGroups.forEach((products, productLineName) => {
      products.forEach(product => { this.displayedColumns.push(Object.keys(product)[0] + this.separator + productLineName) })
      let displayedProductLineColumn = { name: productLineName, span: products.length };
      this.displayedProductLineColumns.push(displayedProductLineColumn);


    });


    if (!this.runConfig.id) {
      this.runConfigTableRows.forEach(row => {
        this.productGroups.forEach((_, productLine) => {
          row['Products'][productLine].forEach((product: any) => {
            let productName = Object.keys(product)[0];
            if (product[productName] === true) {
              this.setCheckboxValueByCol(row, productName + this.separator + productLine, product[productName]);
            }
          });
        });
      });
    }
  }


  unCheckHiddenCheckboxes(): void {
    if (this.selectedFilterOptions.selectedProducts.length > 0) {
      let productFilters = new Set();
      this.selectedFilterOptions.products.forEach(product => {
        productFilters.add(product.displayName.split('/')[1]);
      });
      let measureFilters = new Set();
      this.selectedFilterOptions.measures.forEach(measure => {
        measureFilters.add(measure.displayName);
      });
      [...this.selectedFilterOptions.selectedProducts].forEach(selectedProduct => {
        // selectedProduct i.e. CDC Eye Exam::CHIP (Measure::Product)
        // products i.e. Chip/CHIP (Product Line/Product)
        // measures i.e. CDC Eye Exam (Measure)
        let filteredProduct = productFilters.has(selectedProduct.split('::')[1]);
        let filteredMeasure = measureFilters.has(selectedProduct.split('::')[0]);
        if (!filteredProduct || !filteredMeasure) {
          this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(selectedProduct), 1);
        }
      });
    }
  }

  shouldShowCheckboxLossAlert(): boolean {
    let shouldShowCheckboxLossAlert = false;
    if (this.selectedFilterOptions.selectedProducts.length > 0) {
      let productFilters = new Set();
      this.selectedFilterOptions.products.forEach(product => {
        productFilters.add(product.displayName.split('/')[1]);
      });
      let measureFilters = new Set();
      this.selectedFilterOptions.measures.forEach(measure => {
        measureFilters.add(measure.displayName);
      });
      [...this.selectedFilterOptions.selectedProducts].forEach(selectedProduct => {
        // selectedProduct i.e. CDC Eye Exam::CHIP (Measure::Product)
        // products i.e. Chip/CHIP (Product Line/Product)
        // measures i.e. CDC Eye Exam (Measure)
        let filteredProduct = productFilters.has(selectedProduct.split('::')[1]);
        let filteredMeasure = measureFilters.has(selectedProduct.split('::')[0]);
        if (!filteredProduct || !filteredMeasure) {
          shouldShowCheckboxLossAlert = true;
          return;
        }
      });
    }
    return shouldShowCheckboxLossAlert;
  }

  getClassByKey(key: string): string[] {
    if (this.productGroups.has(key)) {
      if (this.currentKeyClass === '1') {
        this.currentKeyClass = '2';
      } else {
        this.currentKeyClass = '1';
      }
      return ['col-class-' + this.currentKeyClass];
    } else {
      this.currentKeyClass = '0';
      return [];
    }
  }

  getClassByCol(column: string): string[] {

    if (column.indexOf(this.separator) > 0) {
      let k = column.split(this.separator)[1];
      if (this.currentGroup !== k) {
        if (this.currentColClass === '1') {
          this.currentColClass = '2';
        } else {
          this.currentColClass = '1';
        }
      }
      this.currentGroup = k;
      return ['col-class-' + this.currentColClass];
    }
    else {
      this.currentGroup = 'group';
      this.currentColClass = '0';
      return [];
    }
  }

  getIndCheckboxValueByColForProductLine(productLine: string): boolean {
    let indeterminate = false;
    let checked = true;
    this.runConfigTableRows.forEach(row => {
      let selectedProducts = row['Products'][productLine];
      selectedProducts.forEach((product: any) => {
        let productName = Object.keys(product)[0];
        if (product[productName]) {
          indeterminate = true;
        } else {
          checked = false
        }
      });
    });
    return indeterminate && !checked;
  }

  getCheckboxValueByColForProductLine(productLine: string): boolean {
    let checked = true;
    this.runConfigTableRows.forEach(row => {
      let selectedProducts = row['Products'][productLine];
      selectedProducts.forEach((product: any) => {
        let productName = Object.keys(product)[0];
        if (!product[productName]) {
          checked = false;
          return;
        }
      });
    });
    return checked;
  }

  setCheckboxValueByColForProductLine(productLine: string, checkvalue: boolean) {
    let productsForProductLine = this.productGroups.get(productLine);
    productsForProductLine!.forEach(p => {
      let productName = Object.keys(p)[0];
      this.runConfigTableRows.forEach(row => {
        let selectedProducts = row['Products'][productLine];
        selectedProducts.forEach((product: any) => {
          let currentProductName = Object.keys(product)[0];
          if (currentProductName === productName) {
            product[currentProductName] = checkvalue;
            let productId = this.productIdMap.get(productName + this.separator + productLine);
            let measureId = this.measureIdMap.get(row['Measure Name']);
            if (checkvalue && !(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId) > -1)) {
              this.selectedFilterOptions.selectedProducts.push(measureId + '::' + productId);
            } else if (!checkvalue) {
              this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId), 1);
            }
          }
        });
      });
    });
  }

  getIndCheckboxValueByColForHeader(productName: string): boolean {
    let indeterminate = false;
    let checked = true;
    let productLine = productName.split(this.separator)[1];
    this.runConfigTableRows.forEach(row => {
      let selectedProducts = row['Products'][productLine!];
      selectedProducts.forEach((product: any) => {
        let currentProductName = Object.keys(product)[0];
        if (currentProductName + this.separator + productLine === productName) {
          if (product[currentProductName]) {
            indeterminate = true;
          } else {
            checked = false;
          }
        }
      });
    });
    return indeterminate && !checked;
  }

  getCheckboxValueByColForHeader(productName: string): boolean {
    let checked = true;
    let productLine = productName.split(this.separator)[1];
    this.runConfigTableRows.forEach(row => {
      let selectedProducts = row['Products'][productLine!];
      selectedProducts.forEach((product: any) => {
        let currentProductName = Object.keys(product)[0];
        if (currentProductName + this.separator + productLine === productName) {
          if (!product[currentProductName]) {
            checked = false;
            return;
          }
        }
      });
    });
    return checked;
  }

  setCheckboxValueByColForHeader(productName: string, checkvalue: boolean) {
    let productLine = productName.split(this.separator)[1];
    this.runConfigTableRows.forEach(row => {
      let selectedProducts = row['Products'][productLine!];
      selectedProducts.forEach((product: any) => {
        let currentProductName = Object.keys(product)[0];
        if (currentProductName + this.separator + productLine === productName) {
          product[currentProductName] = checkvalue;
          let productId = this.productIdMap.get(productName);
          let measureId = this.measureIdMap.get(row['Measure Name']);
          if (checkvalue && !(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId) > -1)) {
            this.selectedFilterOptions.selectedProducts.push(measureId + '::' + productId);
          } else if (!checkvalue) {
            this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId), 1);
          }
        }
      });
    });
  }

  getCheckboxValueByCol(row: any, productName: string): boolean {
    let checked = false;
    let productLine = productName.split(this.separator)[1];
    let selectedProducts = row['Products'][productLine!];
    selectedProducts.forEach((product: any) => {
      let currentProductName = Object.keys(product)[0];
      if (currentProductName + this.separator + productLine === productName) {
        checked = product[currentProductName];
        return;
      }
    });
    return checked;
  }

  setCheckboxValueByCol(row: any, productName: string, checkvalue: boolean) {
    let productLine = productName.split(this.separator)[1];
    let selectedProducts = row['Products'][productLine!];
    if (selectedProducts) {
      selectedProducts.forEach((product: any) => {
        let currentProductName = Object.keys(product)[0];
        if (currentProductName + this.separator + productLine === productName) {
          product[currentProductName] = checkvalue;
          let productId = this.productIdMap.get(productName);
          let measureId = this.measureIdMap.get(row['Measure Name']);
          if (checkvalue && !(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId) > -1)) {
            this.selectedFilterOptions.selectedProducts.push(measureId + '::' + productId);
          } else if (!checkvalue) {
            this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId), 1);
          }
        }
      });
    }
  }

  getIndCheckboxValueByRowForMeasure(row: any): boolean {
    let indeterminate = false;
    let checked = true;
    for (let [productLine, _] of this.productGroups.entries()) {
      let selectedProducts = row['Products'][productLine];
      selectedProducts.forEach((product: any) => {
        let productName = Object.keys(product)[0];
        if (product[productName]) {
          indeterminate = true;
        } else {
          checked = false;
        }
      });
    }
    return indeterminate && !checked;
  }

  getCheckboxValueByRowForMeasure(row: any): boolean {
    let checked = true;
    for (let [productLine, _] of this.productGroups.entries()) {
      let selectedProducts = row['Products'][productLine];
      selectedProducts.forEach((product: any) => {
        let productName = Object.keys(product)[0];
        if (!product[productName]) {
          checked = false;
          return;
        }
      });
    }
    return checked;
  }

  setCheckboxValueByRowForMeasure(row: any, checkvalue: boolean) {
    for (let [productLine, _] of this.productGroups.entries()) {
      let selectedProducts = row['Products'][productLine];
      selectedProducts.forEach((product: any) => {
        let productName = Object.keys(product)[0];
        product[productName] = checkvalue;
        let productId = this.productIdMap.get(productName + this.separator + productLine);
        let measureId = this.measureIdMap.get(row['Measure Name']);
        if (checkvalue && !(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId) > -1)) {
          this.selectedFilterOptions.selectedProducts.push(measureId + '::' + productId);
        } else if (!checkvalue) {
          this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId), 1);
        }
      });
    }
  }

  getIndCheckboxValueForMeasureHeader(): boolean {
    let indeterminate = false;
    let checked = true;
    for (let [productLine, _] of this.productGroups.entries()) {
      this.runConfigTableRows.forEach(row => {
        let selectedProducts = row['Products'][productLine];
        selectedProducts.forEach((product: any) => {
          let productName = Object.keys(product)[0];
          if (product[productName]) {
            indeterminate = true;
          } else {
            checked = false;
          }
        });
      });
    }
    return indeterminate && !checked;
  }

  getCheckboxValueForMeasureHeader(): boolean {
    let checked = true;
    for (let [productLine, _] of this.productGroups.entries()) {
      this.runConfigTableRows.forEach(row => {
        let selectedProducts = row['Products'][productLine];
        selectedProducts.forEach((product: any) => {
          let productName = Object.keys(product)[0];
          if (!product[productName]) {
            checked = false;
            return;
          }
        });
      });
    }
    return checked;
  }

  setCheckboxValueForMeasureHeader(checkvalue: boolean) {
    for (let [productLine, _] of this.productGroups.entries()) {
      this.runConfigTableRows.forEach(row => {
        let selectedProducts = row['Products'][productLine];
        selectedProducts.forEach((product: any) => {
          let productName = Object.keys(product)[0];
          product[productName] = checkvalue;
          let productId = this.productIdMap.get(productName + this.separator + productLine);
          let measureId = this.measureIdMap.get(row['Measure Name']);
          if (checkvalue && !(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId) > -1)) {
            this.selectedFilterOptions.selectedProducts.push(measureId + '::' + productId);
          } else if (!checkvalue) {
            this.selectedFilterOptions.selectedProducts.splice(this.selectedFilterOptions.selectedProducts.indexOf(measureId + '::' + productId), 1);
          }
        });
      });
    }
  }
}
