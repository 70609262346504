<div class="container error-log" [class.run-color]="executeRunJobLogId">
    <mat-toolbar id="error-log-header" class="wayfinding-header-logs">
        <div class="header-container">
            <span class="title">Error Log Files</span>
        </div>
        <app-wayfinding-path [hidden]="!configurationService.showWayfinding || executeRunJobLogId"></app-wayfinding-path>
        <div class="btn-container">
            <button mat-stroked-button class="btn" id="dismissBtn" (click)="closeDialog(false)">
                <span>Dismiss</span>
            </button>
            <button mat-stroked-button class="btn" id="downloadLogFilesBtn" (click)="downloadLogFiles()">
                <span>Download Log Files</span>
                <span class="spinner" *ngIf="downloading">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </span>
            </button>
        </div>
    </mat-toolbar>
    <div class="content-container">
        <ng-container *ngIf="loadDataJobLogId">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z0" [(selectedIndex)]="selectedTab"
                (selectedTabChange)="onTabChanged($event);">
                <mat-tab label="Header Validation">
                    <div *ngIf="loadingLogs" id="spinner" class="tab-content">
                        <mat-spinner style="margin-top: 2em;"></mat-spinner>
                    </div>
                    <div class="tab-content" [hidden]="loadingLogs">
                        <div *ngFor="let log of errorLogEntries" style="padding-bottom: 1em;">
                            <div>
                                <span *ngIf="log.fileName">{{log.fileName!.split(',')[0]}},
                                    {{log.fileName!.split(',')[1] + ' UTC' | date: 'MM/dd/yyyy hh:mm
                                    a':dateService.getFormattedTimeZone()}}</span>
                            </div>
                            <div>
                                <span>Headers expected: </span><span>{{log.expectedHeaders}}</span>
                            </div>
                            <div>
                                <span>Headers received: </span><span>{{log.receivedHeaders}}</span>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <span>Content Validation ({{totalContentValidationCategories}})</span>
                  </ng-template>
                  <div *ngIf="loadingLogs" id="spinner" class="tab-content">
                    <!-- TODO SKELETON LOADERS -->
                      <mat-spinner style="margin-top: 2em;"></mat-spinner>
                  </div>
                  <div class="tab-content" [hidden]="loadingLogs">
                    <div *ngFor="let category of contentValidationCategories;">
                      <mat-accordion [togglePosition]="'before'">
                        <mat-expansion-panel class="accordion">
                            <mat-expansion-panel-header class="expansion-panel">
                                <mat-panel-title>
                                  {{category.category}} ({{category.errorLogs ? category.totalErrorLogs : 0}})
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span class="top-border"></span>
                            <table class="table table-striped table-bordered">
                              <thead>
                                  <tr>
                                      <th class="error-type-header">Error Type</th>
                                      <th class="filename-header">Filename</th>
                                      <th class="logged-at-header">Last Logged</th>
                                      <th class="identifier-header">Identifier</th>
                                      <th class="error-msg-header">Error Message</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let log of category.errorLogs.slice(0, 10)">
                                      <td class="error-type-row">{{log.errorType}}</td>
                                      <td class="filename-row">{{log.fileName}}</td>
                                      <td class="logged-at-row">{{log.lastLogged  | date: 'MM/dd/yyyy hh:mm a'}}</td>
                                      <td class="identifier-row">{{log.identifier}}</td>
                                      <td class="error-msg-row">{{log.errorMessage}}</td>
                                  </tr>
                              </tbody>
                          </table>
                          <div *ngIf="category.totalErrorLogs > 10" class="more-errors-message">
                            {{category.totalErrorLogs - 10}} more errors of this type. Download logs to see all errors.
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                      <span>FHIR Validation ({{totalFhirValidationCategories}})</span>
                    </ng-template>
                    <div *ngIf="loadingLogs" id="spinner" class="tab-content">
                        <mat-spinner style="margin-top: 2em;"></mat-spinner>
                    </div>
                    <div class="tab-content" [hidden]="loadingLogs">
                      <div *ngFor="let category of fhirValidationCategories;">
                        <mat-accordion [togglePosition]="'before'">
                          <mat-expansion-panel class="accordion">
                              <mat-expansion-panel-header class="expansion-panel">
                                  <mat-panel-title>
                                    {{category.category}} ({{category.errorLogs ? category.totalErrorLogs : 0}})
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <span class="top-border"></span>
                              <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                      <th class="error-type-header">Error Type</th>
                                      <th class="filename-header_FHIR">Filename</th>
                                      <th class="logged-at-header">Last Logged</th>
                                      <th class="identifier-header_FHIR">Identifier</th>
                                      <th class="error-msg-header_FHIR">Error Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let log of category.errorLogs.slice(0, 10)">
                                      <td class="error-type-row">{{log.errorType}}</td>
                                      <td class="filename-row_FHIR">{{log.fileName}}</td>
                                      <td class="logged-at-row">{{log.lastLogged| date: 'MM/dd/yyyy hh:mm a'}}</td>
                                      <td class="identifer-row_FHIR">{{log.identifier}}</td>
                                      <td class="error-msg-row_FHIR">{{log.errorMessage}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span *ngIf="category.totalErrorLogs > 10" class="more-errors-message">
                              {{category.totalErrorLogs - 10}} more errors of this type. Download logs to see all errors.
                            </span>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                </mat-tab>
                <mat-tab label="FHIR Server Load">
                    <div *ngIf="loadingLogs" id="spinner" class="tab-content">
                        <mat-spinner style="margin-top: 2em;"></mat-spinner>
                    </div>
                    <div class="tab-content" [hidden]="loadingLogs">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="bundle-id-header">FHIR Bundle ID</th>
                                    <th class="encounter-id-header">Encounter ID</th>
                                    <th class="error-msg-header_LOAD">Error Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of errorLogEntries">
                                    <td class="bundle-id-row">{{log.fhirBundleId}}</td>
                                    <td class="encounter-id-row">{{log.encounterId}}</td>
                                    <td class="error-msg-row_LOAD">{{log.errorMessage}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-container *ngIf="executeRunJobLogId">
            <div *ngIf="loadingLogs" id="spinner" class="tab-content">
                <mat-spinner style="margin-top: 2em;"></mat-spinner>
            </div>
            <div class="tab-content" [hidden]="loadingLogs">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th class="logged-at-header-run">Error Logged At</th>
                            <th class="member-id-header-run">Member ID</th>
                            <th class="measure-header-run">Measure</th>
                            <th class="error-msg-header-run">Error Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of errorLogEntries">
                            <td class="logged-at-row-run">{{log.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</td>
                            <td class="member-id-row-run">{{log.identifier}}</td>
                            <td class="measure-row-run">{{log.measure}}</td>
                            <td class="error-msg-row-run">{{log.errorMessage}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>
