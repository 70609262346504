import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AuditEntry } from '../models/audit-entry';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private dataService: DataService, private auth: AuthService) {
  }

  sendAuthorizedAuditEvent(targetUrl: string): any {
    let auditEntry = this.buildAuditEntryWithUser();
    auditEntry.event = 'Authorized';
    auditEntry.message = targetUrl;
    this.sendAuditEntry(auditEntry);
  }

  sendLogoutAuditEntry(): any {
    let auditEntry = this.buildAuditEntryWithUser();
    auditEntry.event = 'Logout';

    this.sendAuditEntry(auditEntry);
  }

  sendLoginAuditEntry(): any {
    let auditEntry = this.buildAuditEntryWithUser();
    auditEntry.event = 'Login';

    this.sendAuditEntry(auditEntry);
  }

  sendInsufficientPermissionsAuditEvent(targetUrl: string): any {
    let auditEntry = this.buildAuditEntryWithUser();
    auditEntry.event = 'Insufficient Permissions';
    auditEntry.message = targetUrl;
    this.sendAuditEntry(auditEntry);
  }

  private buildAuditEntryWithUser() {
    const user = this.auth.userName;
    let auditEntry = new AuditEntry();
    auditEntry.user = user;
    return auditEntry;
  }

  public sendAuditEntry(auditEntry: AuditEntry) {
    this.dataService.sendAuditEntry(auditEntry)
      .subscribe();
  }

}
