import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentService } from 'src/app/core/component.service';
import { DataService } from 'src/app/core/data.service';
import { Measure } from 'src/app/models/measure';
import { EditMeasurePackageDialogComponent } from 'src/app/shared/dialogs/edit-measure-package-dialog/edit-measure-package-dialog.component';
import { UploadMeasurePackageDialogComponent } from 'src/app/shared/dialogs/upload-measure-package-dialog/upload-measure-package-dialog.component';
import { DateService } from 'src/app/core/date.service';
import { listAnimation } from 'src/app/shared/animations/table-row-animation';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-manage-measure-package',
  templateUrl: './manage-measure-package.component.html',
  styleUrls: ['./manage-measure-package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation],
})
export class ManageMeasurePackageComponent implements OnInit {

  public uploadMeasurePackageDialogRef!: MatDialogRef<UploadMeasurePackageDialogComponent>;
  public editMeasurePackageDialogRef!: MatDialogRef<EditMeasurePackageDialogComponent>;
  public displayedColumns: string[] =
    ['buttons', 'title', 'displayName', 'version', 'measurementYear', 'certificationDate', 'uploadDate', 'lineOfBusiness'];
  public measures: Measure[] = [];
  public dataSource: MatTableDataSource<Measure> = new MatTableDataSource();
  public loading: Boolean = false;
  public productLines: any[] = [];
  public updatedMeasure?: Measure;

  constructor(public componentService: ComponentService, public dataService: DataService, public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef,
    public dateService: DateService) { }

  ngOnInit(): void {
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.dataService.getAllProductLines().subscribe(productLines => {
      if (productLines) {
        this.productLines = productLines;
      }
      this.dataService.getAllMeasures().subscribe((measures) => {
        if (measures) {
          measures.forEach(measure => {
            measure.certificationDate = this.dateService.convertUTC(measure.certificationDate);
            measure.uploadDate = this.dateService.convertUTC(measure.uploadDate);
            measure.selectedProductLines = this.productLines.filter(a => measure.defaultProductLines.some(b => a.id === b.productLineId));
          })
          this.measures = measures;
          this.dataSource = new MatTableDataSource(measures);
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        }
      });
    });
  }

  public edit(measure: Measure): void {
    this.editMeasurePackageDialogRef = this.dialog.open(EditMeasurePackageDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        measure: measure,
        existingMeasures: this.measures.filter(m => m.id !== measure.id),
        productLines: this.productLines
      }
    });
    this.editMeasurePackageDialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.dataService.saveMeasure(response.measure).subscribe((measure) => {
          if (measure) {
            measure = measure;
            measure.certificationDate = this.dateService.convertUTC(measure.certificationDate);
          }
          this.dataSource = new MatTableDataSource(this.measures);
          this.changeDetectorRef.markForCheck();
        });
      } else {
        this.dataSource = new MatTableDataSource(this.measures);
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  public toggleActive(measure: Measure): void {
    measure.deleted = !measure.deleted;
    this.dataService.saveMeasure(measure).subscribe();
  }


  sortData(sort: Sort) {
    this.dataSource = new MatTableDataSource(this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare((a as any)[sort.active], (b as any)[sort.active], isAsc);
    }));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      a = '';
    }
    if (b === null) {
      b = '';
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  uploadMeasurePackages(): void {
    this.uploadMeasurePackageDialogRef = this.dialog.open(UploadMeasurePackageDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
      }
    });
    this.uploadMeasurePackageDialogRef.afterClosed().subscribe(response => {
      if (response) {
        let measure = new Measure();
        measure.title = response.title;
        measure.version = response.version;
        measure.resourceId = response.resourceId;
        measure.uploadDate = new Date();
        let dataCalls: Observable<void>[] = [];
        for (let index = 1; index <= response.bundles.length; index++) {
          measure.bundle = response.bundles[index-1];
          measure.bundleFileIndex = index;
          dataCalls.push(this.dataService.postBundle(response.bundles[index-1], measure));
        }
        this.editMeasurePackageDialogRef = this.dialog.open(EditMeasurePackageDialogComponent, {
          autoFocus: false,
          disableClose: true,
          data: {
            measure: measure,
            existingMeasures: this.measures,
            productLines: this.productLines,
            bundlesToUpload: dataCalls
          }
        });
        this.editMeasurePackageDialogRef.afterClosed().subscribe(response => {
          if (response) {
            this.dataService.saveMeasure(response.measure).subscribe((measure) => {
              if (measure) {
                measure = measure;
                measure.certificationDate = this.dateService.convertUTC(measure.certificationDate);
                this.measures.push(measure);
              }
              this.dataSource = new MatTableDataSource(this.measures);
              this.changeDetectorRef.markForCheck();
            });
          }
        });
      }
    });
  }
}
