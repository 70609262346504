import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoadingService } from 'src/app/core/loading.service';
import { RunConfigService } from 'src/app/core/run-config.service';
import { ModalType } from 'src/app/models/enums/modal-type-enum';
import { MeasurementPeriod } from 'src/app/models/measurement-period';
import { RunConfigFilterOptions } from 'src/app/models/run-config-filter-options';
import { RunConfiguration } from 'src/app/models/run-configuration';
import * as _moment from 'moment';
import * as moment from 'moment';
import { DateService } from 'src/app/core/date.service';
import { PRODUCT_PRODUCT_LINE_SEPARATOR } from 'src/app/models/constants';

@Component({
  selector: 'app-create-configuration',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class CreateConfigurationComponent implements OnInit {
  public separator = PRODUCT_PRODUCT_LINE_SEPARATOR;
  configTableLoadedSubscription: Subscription;
  menuOpen: boolean = false;
  headerOpen: boolean = true;
  public showSelectError = false;
  public form!: UntypedFormGroup;

  constructor(public dataService: DataService, public runConfigService: RunConfigService,
    private changeDetectorRef: ChangeDetectorRef, public dialogService: DialogService, public loadingService: LoadingService,
    private fb: UntypedFormBuilder, public dateService: DateService) {
    this.createForm();
    this.configTableLoadedSubscription = this.runConfigService.configTableLoadedEventEmitter.subscribe(() => {
      this.numberOfAppliedFilters = (this.selectedMeasureFilters.length > 0 ? 1 : 0) + (this.selectedProductFilters.length > 0 ? 1 : 0);
      this.changeDetectorRef.markForCheck();
    });
  }

  public runTypes: string[] = [];
  public measurementPeriods: MeasurementPeriod[] = [];
  public numberOfAppliedFilters = 0;
  public selectedProductFilters: any[] = [];
  public selectedMeasureFilters: any[] = [];
  public savedRunConfigId: number | undefined = undefined;

  ngOnInit(): void {
    this.runConfigService.loadFilterOptions();
    this.initializeDefaultConfiguration();
    if (this.runConfigService.displayedColumns.length === 0) {
      this.runConfigService.loadRunConfigTable();
    }
  }

  private isNameTaken(forbiddenValues: Set<String>): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (forbiddenValues && c.value && forbiddenValues.has(c.value.trim()) && c.value.trim() != this.runConfigService.runConfig.runName.trim()) {
        return { 'forbiddenValues': true };
      }
      return null;
    };
  }
  private isInvalidEndDate(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && this.runConfigService.runConfig.selectedMeasurementPeriod?.startDate && c.value < this.runConfigService.runConfig.selectedMeasurementPeriod.startDate) {
        return { 'invalid': true };
      }
      return null;
    };
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.runConfigService.runConfig.runName, [Validators.required, Validators.maxLength(256), this.isNameTaken(this.runConfigService.savedRunConfigNames),
      Validators.pattern(/\S/)]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required, this.isInvalidEndDate()]]
    });
  }

  ngOnDestroy() {
    this.runConfigService.clearTableData();
    this.runConfigService.selectedFilterOptions = new RunConfigFilterOptions();
    this.runConfigService.runConfig = new RunConfiguration();
    this.configTableLoadedSubscription.unsubscribe();
  }

  public toggleHeader(): void {
    this.headerOpen = !this.headerOpen;
    localStorage.setItem('headerOpen', String(this.headerOpen));
  }

  areRecordsEqual(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.recordId === item2.recordId : item1 === item2;
  }

  createConfiguration() {
    if (this.form.status === 'VALID') {
      if (this.runConfigService.selectedFilterOptions.selectedProducts.length === 0) {
        this.showSelectError = true;
      } else {
        this.showSelectError = false;
        this.runConfigService.runConfig.runName = this.form.controls['name'].value.trim();
        this.runConfigService.runConfig.startDate = this.runConfigService.runConfig.selectedMeasurementPeriod.startDate!;
        this.runConfigService.runConfig.endDate = this.runConfigService.runConfig.selectedMeasurementPeriod.endDate!;
        this.runConfigService.runConfig.measurementPeriod = this.runConfigService.runConfig.selectedMeasurementPeriod.displayName!;
        this.runConfigService.runConfig.configuration = JSON.stringify(this.runConfigService.selectedFilterOptions);
        this.dataService.saveRunConfig(this.runConfigService.runConfig).subscribe(() => {
          this.runConfigService.loadRunConfigurations();
          this.runConfigService.showCreateConfiguration = false;
        }, (_err) => {
          this.runConfigService.savedRunConfigNames.add(this.runConfigService.runConfig.runName.trim());
          this.runConfigService.runConfig.runName = '';
          this.form.controls['name'].updateValueAndValidity();
          this.form.controls['name'].markAllAsTouched();
          this.changeDetectorRef.detectChanges();
        });
      }
    }
  }

  applyFilters() {
    if (this.selectedProductFilters.length === 0) {
      this.runConfigService.selectedFilterOptions.products = this.runConfigService.filterOptions.products;
    } else {
      this.runConfigService.selectedFilterOptions.products = this.selectedProductFilters;
    }
    if (this.selectedMeasureFilters.length === 0) {
      this.runConfigService.selectedFilterOptions.measures = this.runConfigService.filterOptions.measures;
    } else {
      this.runConfigService.selectedFilterOptions.measures = this.selectedMeasureFilters;
    }
    if (this.runConfigService.shouldShowCheckboxLossAlert()) {
      let modalTitle = 'Some checked items will be removed';
      let modalMessage = 'The filter setting will hide some items you\'ve checked from your view. The system will uncheck those items when you apply the filter.'
      let primaryBtnText = 'Proceed and uncheck items';
      let secondaryBtnText = 'Cancel filter';
      this.dialogService.openModal(ModalType.WARN, modalTitle, modalMessage, primaryBtnText, secondaryBtnText, () => {
        this.runConfigService.loadRunConfigTable();
      }, () => {
      });
    } else {
      this.runConfigService.loadRunConfigTable();
    }
  }

  clearFilters() {
    this.selectedProductFilters = [];
    this.selectedMeasureFilters = [];
    this.applyFilters();
  }

  getNumberOfAppliedFilters(): number {
    return this.numberOfAppliedFilters;
  }

  dateChanged(event: any, startDate: boolean, fromInputField: boolean) {
    if (this.runConfigService.runConfig.selectedMeasurementPeriod.displayName.toUpperCase() !== 'CUSTOM') {
      this.runConfigService.runConfig.selectedMeasurementPeriod = this.measurementPeriods.filter(m => m.displayName.toUpperCase() === 'CUSTOM')[0];
    }
    if (!fromInputField) {
      if (startDate) {
        this.runConfigService.runConfig.selectedMeasurementPeriod.startDate = event.value;
      } else {
        this.runConfigService.runConfig.selectedMeasurementPeriod.endDate = event.value;
      }
      this.selectedMeasurementPeriodChanged(null);
    }
  }

  selectedMeasurementPeriodChanged(_event: any) {
    this.form.controls['startDate'].setValue(moment(this.runConfigService.runConfig.selectedMeasurementPeriod.startDate));
    this.form.controls['endDate'].setValue(moment(this.runConfigService.runConfig.selectedMeasurementPeriod.endDate));
  }

  initializeDefaultConfiguration(): void {
    if (this.runConfigService.runConfig.id) {
      this.selectedProductFilters = JSON.parse(this.runConfigService.runConfig.configuration).products;
      this.selectedMeasureFilters = JSON.parse(this.runConfigService.runConfig.configuration).measures;
    }
    this.measurementPeriods.push(new MeasurementPeriod(999, 'Custom', 999, null, null));
    this.dataService.getMeasurementPeriodOptions().subscribe((options => {
      options?.forEach(option => {
        option.startDate = new Date(option.startDate!.toString());
        option.endDate = new Date(option.endDate!.toString());
        this.measurementPeriods.push(option);
      });
      this.measurementPeriods.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
      if (this.runConfigService.runConfig.id) {
        this.runConfigService.runConfig.selectedMeasurementPeriod = this.measurementPeriods.filter(f => f.displayName === this.runConfigService.runConfig.measurementPeriod)[0];
        this.runConfigService.runConfig.selectedMeasurementPeriod.startDate = this.runConfigService.runConfig.startDate;
        this.runConfigService.runConfig.selectedMeasurementPeriod.endDate = this.runConfigService.runConfig.endDate;
        this.form.controls['name'].setValue(this.runConfigService.runConfig.runName);
        this.form.controls['startDate'].setValue(this.dateService.convertUTC(this.runConfigService.runConfig.selectedMeasurementPeriod.startDate));
        this.form.controls['endDate'].setValue(this.dateService.convertUTC(this.runConfigService.runConfig.selectedMeasurementPeriod.endDate))
      } else {
        this.runConfigService.runConfig.selectedMeasurementPeriod = this.measurementPeriods[0];
        this.selectedMeasurementPeriodChanged(null);
      }
      this.form.updateValueAndValidity();

      this.changeDetectorRef.markForCheck();
    }));
    this.runTypes = ['Surveilliance', 'Another Type', 'Even One More Type'];
  }

  getHeaderRowGroups(): any[] {
    let numberOfProducts = this.runConfigService.productGroups.size;

    let productGroupDefenition = ['header-row-group-spacer-1', 'header-row-group-spacer-2'];

    if (numberOfProducts === 0) {
      return productGroupDefenition;
    } else {
      for (let index = 0; index < numberOfProducts; index++) {
        productGroupDefenition.push('header-row-group-' + index)
      }
      return productGroupDefenition;
    }
  }

}
