export class MeasurementPeriod {
    id!: number;
    displayName!: string;
    displayOrder!: number;
    startDate!: Date | null;
    endDate!: Date | null;

    constructor(id: number, displayName: string, displayOrder: number, startDate: Date | null, endDate: Date | null) {
        this.id = id;
        this.displayName = displayName;
        this.displayOrder = displayOrder;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}