<div id="container">
    <mat-toolbar id="header1" class="header" [hidden]="runConfigService.showRunResults">
        <span id="title">
            <img id="executeIcon" src="assets/icons/view-icon.svg" class="icon">
            View Run Results
        </span>
        <button mat-stroked-button id="compare-btn" [disabled]="true" [hidden]="true">
            <span>Compare Selected Runs</span>
        </button>
    </mat-toolbar>

    <mat-toolbar id="header2" class="header" [hidden]="!runConfigService.showRunResults">
        <span id="title">
            <button mat-button class="back" (click)="backFromResults()"><img id="backIcon"
                    src="assets/images/arrow_back.svg"></button>
            <a class="new-run-title">Run Results</a>
        </span>
    </mat-toolbar>
</div>
<ng-container [ngSwitch]="true">
    <div *ngSwitchCase="runConfigService.showRunResults">
        <app-run-results @slideInOut (exportMemberLevelClicked)="downloadMemberLevelOutputFiles(selectedRunLog.id)" [selectedRunLog]="selectedRunLog" [selectedRunConfig]="selectedRunConfig" ></app-run-results>
    </div>
    <div *ngSwitchDefault>
        <div class="configuration-container">
            <cdk-virtual-scroll-viewport [itemSize]="120" class="viewport">
                <div *cdkVirtualFor="let config of runConfigService.runConfigurations; templateCacheSize: 0;">
                    <mat-accordion>
                        <mat-expansion-panel class="accordian">
                            <mat-expansion-panel-header class="expansion-panel">
                                <mat-panel-title>
                                    <span class="title" id="config-name">
                                        {{config.runName}}
                                    </span>
                                    <span class="date-range" id="config-date-range">
                                        Date Range: {{config.startDate?.split('T')[0] | date: 'LLL'}}
                                        {{config.startDate?.split('T')[0] | date:
                                        'yyyy'}} -
                                        {{config.endDate?.split('T')[0] | date: 'LLL'}} {{config.endDate?.split('T')[0]
                                        | date: 'yyyy'}}
                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span class="top-border"></span>
                            <div *ngFor="let runLog of config.executeRunJobLogEntityList" class="run-log-row"
                                [hidden]="!runLog.endTime" (click)="viewResults(runLog, config); $event.stopPropagation();">
                                <svg class="icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                                    [class.loading]="!runLog.endTime" xmlns="http://www.w3.org/2000/svg"
                                    [class.invisible]="runLog.endTime">
                                    <path
                                        d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                                        fill="#787A7A" />
                                </svg>
                                <span class=run-info>{{runLog.startTime | date: 'MMMM d, y hh:mm
                                    aaaaa\'m\'':dateService.getFormattedTimeZone()}}
                                    by {{runLog.fullName}}
                                </span>
                                <button mat-stroked-button id="moreBtn" [matMenuTriggerFor]="menu"
                                    (click)="$event.stopPropagation(); openMen();">
                                    <img src="assets/images/more-elips.svg" class="icon">
                                </button>
                                <mat-menu #menu="matMenu">
                                    <div [class.hidden-menu]="!isMenuOpen">
                                        <button mat-menu-item
                                            (click)="$event.stopPropagation(); viewResults(runLog, config);">View
                                            Run Results</button>
                                        <button mat-menu-item
                                            (click)="$event.stopPropagation(); downloadGapList(runLog);">
                                            <span class="download-label">
                                                Download Gap List
                                            </span>
                                            <span class="inline-spinner" *ngIf="downloadingGapList">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </span>
                                        </button>
                                        <button mat-menu-item
                                            (click)="$event.stopPropagation(); downloadIdssFiles(runLog.id, runLog.runConfigId);">
                                            <span class="download-label">
                                                Download IDSS Files
                                            </span>
                                            <span class="inline-spinner" *ngIf="downloadingIdssFile">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </span>
                                        </button>
                                        <button mat-menu-item
                                            (click)="$event.stopPropagation(); downloadMemberLevelOutputFiles(runLog.id);">
                                            <span class="download-label">
                                                Download Member Level Files
                                            </span>
                                            <span class="inline-spinner" *ngIf="downloadingMemberLevelOutput">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </span>
                                        </button>
                                    </div>
                                </mat-menu>

                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-container>