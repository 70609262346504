import { OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

const COUNT_START = environment.timeout.countDownStart;

@Injectable({
  providedIn: 'root'
})

export class TimeoutService implements OnInit, OnDestroy {
  public timerSubscription: Subscription = new Subscription;
  public _timer!: Observable<number>;
  public timeoutExpired: Subject<number> = new Subject<number>();
  public calledComplete: Boolean = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  setSubscription() {
      this._timer = timer(0, 1000);
      this.timerSubscription = this._timer.subscribe(n => {
        let current = new Date();
        if(current.getTime() >= Number(localStorage.getItem('expirationDate')) && !this.calledComplete) {
          this.timerComplete();
        }
        if (current.getTime() > Number(localStorage.getItem('expirationDate')) + (COUNT_START - 1)*1000) {
          this.logout();
        }
      });
  }

  startTimer() {
    this.calledComplete = false;
    if (this.timerSubscription) {
        this.stopTimer();
    }
    this.setSubscription();
  }

  stopTimer() {
    this.timerSubscription.unsubscribe();
  }

  timerComplete() {
    this.calledComplete = true;
    this.timeoutExpired.next();
  }

  logout() {
    this.stopTimer();
    this.authService.logout();
    localStorage.removeItem('expirationDate');
  }

}
