import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { DatePipe } from '@angular/common'
import { DateService } from 'src/app/core/date.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { Measure } from 'src/app/models/measure';
import { MeasureGroupMapping } from 'src/app/models/measure-group-mapping';
import { textChangeRangeIsUnchanged } from 'typescript';
import { DefaultProductLine } from 'src/app/models/default-product-line';
interface Genders {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-edit-measure-package-dialog',
  templateUrl: './edit-measure-package-dialog.component.html',
  styleUrls: ['./edit-measure-package-dialog.component.scss']
})
export class EditMeasurePackageDialogComponent implements OnInit {
  public form!: UntypedFormGroup;
  public formAdvanced!: UntypedFormGroup;
  public productLines: any[] = [];
  public selectedProductLines = [];
  public measure = new Measure();
  public existingMeasures: Measure[] = [];
  public bundlesToUpload: Observable<void>[] = [];
  public loading = false;
  public savedMeasure = new Measure();
  public genders: Genders[] = [
    { value: 'ALL', viewValue: 'All' },
    { value: 'MALE', viewValue: 'Male' },
    { value: 'FEMALE', viewValue: 'Female' },
  ];
  displayMeasureGroupColumns: string[] = ['control', 'groupLabel', 'allowGap'];
  private selectedElement: any;


  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditMeasurePackageDialogComponent>,
    public dateService: DateService, public snackBar: SnackbarService) { }
  ngOnInit(): void {
    if (this.data.measure) {
      this.measure = Object.assign({}, JSON.parse(JSON.stringify(this.data.measure)))
      this.savedMeasure = this.data.measure;
      this.measure.certificationDate = this.dateService.convertUTC(this.measure.certificationDate);
      if (this.measure.measureGroupMappings?.length == 0) {
        this.measure.measureGroupMappings = [new MeasureGroupMapping()]
        this.measure.measureGroupMappings[0].measureId = this.measure.id;
      }
    }
    this.existingMeasures = this.data.existingMeasures;
    this.productLines = this.data.productLines;
    if (this.data.bundlesToUpload) {
      this.bundlesToUpload = this.data.bundlesToUpload;
    }
    this.createForm();
    this.createFormAdvanced();
  }

  onFocus(event: { target: any; } | null) {
    if (event) {
      this.selectedElement = event.target;
    } else {
      this.selectedElement = null;
    }
  }

  isHidden(elId: string): boolean {
    return this.selectedElement?.id !== elId;
  }
  areRecordsEqual(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }
  private isForbidden(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && this.existingMeasures.find(m => m.displayName.toUpperCase() === c.value.toUpperCase() && m.version.toUpperCase() === this.measure.version.toUpperCase())) {
        return { 'forbiddenValues': true };
      }
      return null;
    };
  }
  createForm(): void {
    this.form = this.fb.group({
      displayName: [this.measure.displayName, [Validators.required, Validators.pattern(/\S/), this.isForbidden()]],
      measureAbbr: [this.measure.measureAbbr, [Validators.required, Validators.pattern(/\S/), this.isForbidden()]],
      certification: [this.measure.certification, []],
      measurementYear: [this.measure.measurementYear, [Validators.required, Validators.pattern(/\S/)]],
      defaultProductLines: [this.measure.selectedProductLines, [Validators.required]]
    });

  }

  createFormAdvanced(): void {
    this.formAdvanced = this.fb.group({
      outputAge: [this.measure.outputAge],
      filterAge: [this.measure.filterAge],
      gender: [this.measure.outputGender]
    });

  }
  public update(): void {
    this.form.markAllAsTouched();
    if (this.form.status === 'VALID') {
      if (this.bundlesToUpload.length > 0) {
        this.form.disable();
        this.loading = true;
        forkJoin(this.bundlesToUpload).subscribe(() => {
          this.measure.certificationDate = this.dateService.convertUTC(this.measure.certificationDate);
          this.dialogRef.close({ 'measure': this.measure });
          this.loading = false;
          this.form.enable();
        }, (e) => {
          console.log(e);
          this.loading = false;
          this.form.enable();
          this.snackBar.showNegativeBar('An unexpected error occurred while uploading measure package. Please see logs for more details.');
        });
      } else {
        this.measure.certificationDate = this.dateService.convertUTC(this.measure.certificationDate);
        this.savedMeasure.certification = this.measure.certification;
        this.savedMeasure.measureAbbr = this.measure.measureAbbr;
        this.savedMeasure.selectedProductLines = this.measure.selectedProductLines;
        this.savedMeasure.certificationDate = this.measure.certificationDate;
        this.savedMeasure.displayName = this.measure.displayName;
        this.savedMeasure.measurementYear = this.measure.measurementYear;
        this.savedMeasure.deleted = this.measure.deleted;
        this.savedMeasure.outputAge = this.measure.outputAge;
        this.savedMeasure.filterAge = this.measure.filterAge;
        this.savedMeasure.outputGender = this.measure.outputGender;
        this.savedMeasure.hasOutputEvent = this.measure.hasOutputEvent
        this.savedMeasure.measureGroupMappings = this.measure.measureGroupMappings;
        this.measure.defaultProductLines =
          this.measure.defaultProductLines.filter(dpl => this.measure.selectedProductLines.some(sp => dpl.productLineId === sp.id));
        let newlySelectedProductLines = this.measure.selectedProductLines.filter(sp => !this.measure.defaultProductLines.some(dp => sp.id === dp.productLineId));
        newlySelectedProductLines.forEach(dp => {
          let newDefaultProductLineRow = new DefaultProductLine();
          newDefaultProductLineRow.measureId = this.measure.id;
          newDefaultProductLineRow.productLineId = dp.id;
          this.measure.defaultProductLines = [newDefaultProductLineRow, ...this.measure.defaultProductLines];
        })
        this.savedMeasure.defaultProductLines = this.measure.defaultProductLines;
        this.dialogRef.close({ 'measure': this.savedMeasure });
      }
    }
  }
  public cancel(): void {
    this.measure = this.savedMeasure;
    this.dialogRef.close();
  }
  areMeasureGroupMappingsEqual(item1: any, item2: any): boolean {
    return item1 && item2 && item1.id > 0 ? item1.id === item2.id && item1.id > 0 : item1 === item2;
  }
  addGroupLabel() {
    const newGroupMappingRow = new MeasureGroupMapping();
    newGroupMappingRow.measureId = this.measure.id;
    this.measure.measureGroupMappings = [...this.measure.measureGroupMappings, newGroupMappingRow];
  }
  removeGroupLabel(measureGroupMapping: MeasureGroupMapping) {
    this.measure.measureGroupMappings = this.measure.measureGroupMappings.filter((u: MeasureGroupMapping) => !this.areMeasureGroupMappingsEqual(u, measureGroupMapping));
  }
}