<mat-toolbar id="wayfinding-header" class="wayfinding-header" [ngStyle]="{'height.px': wayfindingService.height}">
    <div class="wayfinding-container">
        <div class="wayfinding-path">
            <span>
                <img id="loadDataIcon" src="../../../../../assets/images/{{wayfindingService.icon}}.svg" class="icon"
                    [hidden]="loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_DATA">
                <img id="loadDataIcon" src="../../../../../assets/images/checkmark-white.svg" class="icon"
                    [hidden]="configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA">
                <span [hidden]="!loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_DATA" class="refresh" [class.loading]="loadingService.shouldShowLoadingElements()">
                    <svg class="icon" width="22" height="22" viewBox="0 0 16 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="white" />
                    </svg>
                </span>
                <span id="load-data-title" class="light-title bold-title">
                    <span [hidden]="!loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_DATA">Loading Data</span>
                    <span [hidden]="loadingService.shouldShowLoadingElements() && configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA">Load Data</span>
                </span>
            </span>
            <span>
                <img id="loadDataIcon" src="../../../../../assets/images/{{wayfindingService.icon}}.svg" class="icon"
                    [hidden]="loadingService.shouldShowLoadingElements() || loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_CONTENT">
                <img id="loadDataIcon" src="../../../../../assets/images/checkmark-white.svg" class="icon"[hidden]="configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_CONTENT || configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA"> 
                <span
                    [hidden]="!loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_CONTENT"
                    class="refresh" [class.loading]=loadingService.validating>
                    <svg class="icon" width="22" height="22" viewBox="0 0 16 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="white" />
                    </svg>
                </span>
                <span id="validate-content-title" class="light-title"
                    [class.bold-title]="configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_DATA">
                    <span [hidden]="!loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_CONTENT">Validating Content</span>
                    <span [hidden]="loadingService.validating && configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_CONTENT">Validate Content</span>
                </span>
            </span>
            <span>
                <span
                    [hidden]="!loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_FHIR_BUNDLES"
                    class="refresh" [class.loading]=loadingService.validating>
                    <svg class="icon" width="22" height="22" viewBox="0 0 16 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="white" />
                    </svg>
                </span>
                <img id="loadDataIcon" src="../../../../../assets/images/{{wayfindingService.icon}}.svg" class="icon"
                    [hidden]="loadingService.shouldShowLoadingElements() || loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_FHIR_BUNDLES">
                <img id="loadDataIcon" src="../../../../../assets/images/checkmark-white.svg" class="icon"
                    [hidden]="configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES">
                <span id="validate-fhir-title" class="light-title"
                    [class.bold-title]="configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_FHIR_BUNDLES || configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES">
                    <span [hidden]="!loadingService.validating || configurationService.currentWayfindingStage !== WayfindingStageEnum.VALIDATE_FHIR_BUNDLES">Validating FHIR</span>
                    <span [hidden]="loadingService.validating && configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_FHIR_BUNDLES">Validate FHIR</span>
                </span>
            </span>
            <span>
                <span [hidden]="!loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES" class="refresh" [class.loading]="loadingService.shouldShowLoadingElements()">
                    <svg class="icon" width="22" height="22" viewBox="0 0 16 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="white" />
                    </svg>
                </span>
                <img id="loadDataIcon" src="../../../../../assets/images/{{wayfindingService.icon}}.svg" class="icon"
                [hidden]="loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES">
                <span id="load-fhir-title" class="light-title"
                    [class.bold-title]="configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES">
                    <span [hidden]="!loadingService.shouldShowLoadingElements() || configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES">Loading FHIR</span>
                    <span [hidden]="loadingService.shouldShowLoadingElements() && configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES">Load FHIR</span>
                </span>
            </span>
        </div>
        <div>
            <div>
                <p id="wayfinding-message" class="wayfinding-message" [innerHtml]="wayfindingService.message"
                    [hidden]="loadingService.shouldShowLoadingElements() && configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA">
                </p>
            </div>
        </div>
    </div>
</mat-toolbar>