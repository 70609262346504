import { Injectable } from '@angular/core';
import { ComponentEnum } from '../models/enums/component-enum';
import { RunConfigService } from './run-config.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  public displayManageMeasurePackageComponent: boolean = false;
  public displayMangeOrganizationInformationComponent: boolean = false;
  public displayManageUsersComponent: boolean = false;
  public _selectedComponent: ComponentEnum = ComponentEnum.LOAD;
  public navOpen: boolean = true;

  constructor(public runConfigService: RunConfigService) { }

  public get ComponentEnum(): typeof ComponentEnum {
    return ComponentEnum;
  }

  public get selectedComponent() {
    return this._selectedComponent;
  }

  public set selectedComponent(selectedComponent: ComponentEnum) {
    if (this._selectedComponent !== selectedComponent || this.shouldDisplayPopover()) {
      this.hidePopover();
      this._selectedComponent = selectedComponent;
      localStorage.setItem('selectedComponent', selectedComponent);
    }
  }

  public hidePopover(): void {
    this.runConfigService.showCreateConfiguration = false;
    this.runConfigService.executeRun = false;
    this.runConfigService.showRunResults = false;
    this.displayManageMeasurePackageComponent = false;
    this.displayMangeOrganizationInformationComponent = false;
    this.displayManageUsersComponent = false;
  }

  public shouldDisplayPopover(): boolean {
    return this.displayManageMeasurePackageComponent || this.displayMangeOrganizationInformationComponent || this.displayManageUsersComponent;
  }

  public toggleNav(): void {
    this.navOpen = !this.navOpen;
    localStorage.setItem('navOpen', String(this.navOpen));
  }

  public initializeComponentState(): void {
    if (!localStorage.getItem('navOpen')) {
      localStorage.setItem('navOpen', 'true');
    }
    if (!localStorage.getItem('selectedComponent')) {
      localStorage.setItem('selectedComponent', ComponentEnum.LOAD);
    }
    this._selectedComponent = localStorage.getItem('selectedComponent') as ComponentEnum;
    this.navOpen = localStorage.getItem('navOpen') === 'true';
  }
}
