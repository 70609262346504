<div id="manage-users-container">
  <mat-toolbar id="header" class="header">
    <span id="title">
        Manage Users
    </span>
    <span id="title">
      <img id="cheatSheetIcon" src="assets/images/info-white.svg" class="icon" (click)="cheatSheet()">
    </span>
    <button mat-stroked-button id="add-users-btn" (click)="addUsers()">
        <span>Add User</span>
    </button>
  </mat-toolbar>
  <div class="table-container">
    <mat-table #table [dataSource]="userDataSource" matSort (matSortChange)="sortData($event)"
        [@listAnimation]='userDataSource && userDataSource.data.length && !newUser'>

        <!-- First Name Column -->
        <ng-container matColumnDef="first_name">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> First Name
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <span *ngIf="cell.first_name">
                {{cell.first_name}}
              </span>
            </mat-cell>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="last_name">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell">
                Last Name </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <span *ngIf="cell.last_name">
                {{cell.last_name}}
              </span>
            </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email_id">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> Email
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <span *ngIf="cell.email_id">
                {{cell.email_id}}
              </span>
            </mat-cell>
        </ng-container>

        <!-- Network ID Column -->
        <ng-container matColumnDef="user_id">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> Network ID
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <span *ngIf="cell.user_id">
                {{cell.user_id}}
              </span>
            </mat-cell>
        </ng-container>

        <!-- Permissions Column -->
        <ng-container matColumnDef="role">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> Permissions
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <span *ngIf="cell.role">
                {{viewerPipe.transform(cell.role)}}
              </span>
            </mat-cell>
        </ng-container>

        <!-- Last Login Column -->
        <ng-container matColumnDef="last_login">
          <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> Last Login
          </mat-header-cell>
          <mat-cell *matCellDef="let cell" class="mat-standard-cell">
            <span *ngIf="loadingTable" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </span>
            <span *ngIf="cell.last_login">
              {{cell.last_login.split("T")[0]}}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="active">
            <mat-header-cell mat-sort-header *matHeaderCellDef class="mat-header-cell"> Status
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell">
              <span *ngIf="loadingTable" class="skeleton-label">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </span>
              <div [hidden]="loadingTable || !cell.active">Active</div>
              <div [hidden]="loadingTable || cell.active">Inactive</div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [class.inactive]="!row.active" [class.disabled]="row.role === 'Developer' && !userService.isDeveloper()"
            class="table-cursor" (click)="editUser(row)" [@rowsAnimation]='isNewUser(row)'>
        </mat-row>
    </mat-table>
  </div>
</div>
