<app-wayfinding-path class="wayfinding-path-header"></app-wayfinding-path>
<mat-toolbar class="cancel-toolbar" [ngStyle]="{'height.px': wayfindingService.height}">
    <div [class.column-reverse]="configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES && !loadingService.loading">
        <button mat-stroked-button id="cancel-btn" (click)="cancel()" [disabled]="(configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES && loadingService.loading) 
            || (!userService.isAdministrator() && !userService.isDeveloper())">
            <span [hidden]="configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES && !loadingService.loading">Cancel Load</span>
            <span [hidden]="configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES || loadingService.loading">Dismiss</span>
        </button>
        <button mat-stroked-button
            *ngIf="contentValidationService.criticalError || contentValidationService.contentValidationError || (configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES && !loadingService.loading)"
            id="view-logs-btn" (click)="viewLogs()">
            <span>View Logs</span>
        </button>
    </div>
</mat-toolbar>