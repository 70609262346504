import { Injectable } from '@angular/core';
import { LoadDataJobLogEntry } from '../models/load-data-job-log-entry';
import { DataService } from './data.service';
import * as moment from 'moment'
import { ConfigurationService } from './configuration.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobLogService {

  public loadedCurrentJobEventEmitter: Subject<any> = new Subject<any>();

  public loadedCurrentJobAnnounced: Observable<any> = this.loadedCurrentJobEventEmitter.asObservable();

  // LOADED JOB LOG
  private _loadStarted: string = 'N/A';
  private _loadFinished: string = 'N/A';
  private _validationDuration: string = 'N/A';
  private _fhirServerLoadDuration: string = 'N/A';
  public loadingLoadedJobLogEntry: boolean = false;
  private _isContentValidationDone: boolean=false;

  public  isContentValidationDone(): boolean {
    return this._isContentValidationDone;
  }

  public get loadStarted(): string {
    return this._loadStarted;
  }

  public get loadFinished(): string {
    return this._loadFinished;
  }

  public get validationDuration(): string {
    return this._validationDuration;
  }

  public get fhirServerLoadDuration(): string {
    return this._fhirServerLoadDuration;
  }

  public set loadStarted(value: string) {
    this._loadStarted = value
  }

  public set loadFinished(value: string) {
    this._loadFinished = value;
  }

  public set validationDuration(value: string) {
    this._validationDuration = value;
  }

  public set fhirServerLoadDuration(value: string) {
    this._fhirServerLoadDuration = value;
  }

  public setLoadedJobLogFromCurrentJobLog() {
    this.configurationService.loadedJobLogId = this.configurationService.currentJobLogId;
    this.loadStarted = this.currentLoadStarted;
    this.loadFinished = this.currentLoadFinished;
    this.validationDuration = this.currentValidationDuration;
    this.fhirServerLoadDuration = this.currentFhirServerLoadDuration;
  }

  // CURRENT JOB LOG
  private _currentLoadStarted: string = 'N/A';
  private _currentLoadFinished: string = 'N/A';
  private _currentValidationDuration: string = 'N/A';
  private _currentFhirServerLoadDuration: string = 'N/A';
  private _currentJobError: boolean = false;
  public loadingJobLogEntry: boolean = false;
  public currentEndTime: Date | null = null!

  public clearCurrentJobLog(): void {
    this.currentLoadStarted = '';
    this.currentLoadFinished = '';
    this.currentValidationDuration = '';
    this.currentFhirServerLoadDuration = '';
    this._isContentValidationDone=false;
  }

  public get currentJobError(): boolean {
    return this._currentJobError;
  }

  public set currentJobError(value: boolean) {
    this._currentJobError = value;
  }

  public get currentLoadStarted(): string {
    if (this._currentLoadStarted === 'N/A'
      && this.configurationService.configuration.showWayfinding) {
      return '';
    } else {
      return this._currentLoadStarted;
    }
  }

  public get currentLoadFinished(): string {
    if (this._currentLoadFinished === 'N/A'
      && this.configurationService.configuration.showWayfinding) {
      return '';
    } else {
      return this._currentLoadFinished;
    }
  }

  public get currentValidationDuration(): string {
    if (this._currentValidationDuration === 'N/A'
      && this.configurationService.configuration.showWayfinding) {
      return '';
    } else {
      return this._currentValidationDuration;
    }
  }

  public get currentFhirServerLoadDuration(): string {
    if (this._currentFhirServerLoadDuration === 'N/A'
      && this.configurationService.configuration.showWayfinding) {
      return '';
    } else {
      return this._currentFhirServerLoadDuration;
    }
  }

  public set currentLoadStarted(value: string) {
    this._currentLoadStarted = value;
  }

  public set currentLoadFinished(value: string) {
    this._currentLoadFinished = value;
  }

  public set currentValidationDuration(value: string) {
    this._currentValidationDuration = value;
  }

  public set currentFhirServerLoadDuration(value: string) {
    this._currentFhirServerLoadDuration = value;
  }

  constructor(public dataService: DataService, public configurationService: ConfigurationService) { }

  createNewJobLogEntry(): Observable<any> {
    this.loadingJobLogEntry = true;
    this.currentJobError = false;
    return this.dataService.createLoadDataJobLogEntry();
  }

  async getCurrentLoadDataJobLogEntry(jobLogId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getLoadDataJobLogEntry(jobLogId).subscribe((result: LoadDataJobLogEntry | null) => {
        if (result) {
          this.currentJobError = result.error;
          if (result.loadStartTime) {
            let loadStartTime: moment.Moment = moment(result.loadStartTime);
            this.currentLoadStarted = loadStartTime.format('MM/DD/YYYY hh:mm a').toUpperCase();
          }

          if (result.loadEndTime) {
            let loadEndTime: moment.Moment = moment(result.loadEndTime);
            this.currentLoadFinished = loadEndTime.format('MM/DD/YYYY hh:mm a').toUpperCase();
          }

          let contentValidationTimeDiff: number | undefined = undefined;

          if (result.contentValidationEndTime) {
            this._isContentValidationDone=true;
            let contentValidationStartTime: moment.Moment = moment(result.contentValidationStartTime);
            let contentValidationEndTime: moment.Moment = moment(result.contentValidationEndTime);
            contentValidationTimeDiff = contentValidationEndTime.diff(contentValidationStartTime);
          }

          let fhirBundleValidationTimeDiff: number | undefined = undefined;

          if (result.fhirBundleValidationEndTime) {
            let fhirBundleValidationStartTime: moment.Moment = moment(result.fhirBundleValidationStartTime);
            let fhirBundleValidationEndTime: moment.Moment = moment(result.fhirBundleValidationEndTime);
            fhirBundleValidationTimeDiff = fhirBundleValidationEndTime.diff(fhirBundleValidationStartTime);
          }

          if (contentValidationTimeDiff !== undefined && fhirBundleValidationTimeDiff !== undefined) {
            let totalValidationTimeDiff = contentValidationTimeDiff + fhirBundleValidationTimeDiff;
            this.currentValidationDuration = Math.floor(moment.duration(totalValidationTimeDiff).asHours()) + ' hrs '
              + moment(totalValidationTimeDiff).format("m") + ' min';
          }

          let fhirServerLoadTimeDiff!: number;
          
          if (result.fhirServerLoadEndTime) {
            let fhirServerLoadStartTime: moment.Moment = moment(result.fhirServerLoadStartTime);
            let fhirServerLoadEndTime: moment.Moment = moment(result.fhirServerLoadEndTime);
            fhirServerLoadTimeDiff = fhirServerLoadEndTime.diff(fhirServerLoadStartTime);
          }

          if (fhirServerLoadTimeDiff) {
            this.currentFhirServerLoadDuration = Math.floor(moment.duration(fhirServerLoadTimeDiff).asHours()) + ' hrs '
            + moment(fhirServerLoadTimeDiff).format("m") + ' min';
          }

          this.currentEndTime = result.loadEndTime;
        }
        this.loadingJobLogEntry = false;
        this.loadedCurrentJobEventEmitter.next();
        resolve(); 
      },
        () => {
          this.loadingJobLogEntry = false;
          this.loadedCurrentJobEventEmitter.next();
        resolve(); 
        });
      
      })
  }

  getLoadedDataJobLogEntry(): void {
    this.loadingLoadedJobLogEntry = true;
    if (this.configurationService.loadedJobLogId) {
      this.dataService.getLoadDataJobLogEntry(this.configurationService.loadedJobLogId).subscribe((result: LoadDataJobLogEntry | null) => {
        if (result) {
          let loadStartTime: moment.Moment = moment(result.loadStartTime);
          this.loadStarted = loadStartTime.format('MM/DD/YYYY hh:mm a').toUpperCase();

          let loadEndTime: moment.Moment = moment(result.loadEndTime);
          this.loadFinished = loadEndTime.format('MM/DD/YYYY hh:mm a').toUpperCase();

          let contentValidationStartTime: moment.Moment = moment(result.contentValidationStartTime);
          let contentValidationEndTime: moment.Moment = moment(result.contentValidationEndTime);
          let fhirBundleValidationStartTime: moment.Moment = moment(result.fhirBundleValidationStartTime);
          let fhirBundleValidationEndTime: moment.Moment = moment(result.fhirBundleValidationEndTime);
          let contentValidationTimeDiff: number = contentValidationEndTime.diff(contentValidationStartTime);
          let fhirBundleValidationTimeDiff: number = fhirBundleValidationEndTime.diff(fhirBundleValidationStartTime);

          let totalValidationTimeDiff = contentValidationTimeDiff + fhirBundleValidationTimeDiff;

          this.validationDuration = Math.floor(moment.duration(totalValidationTimeDiff).asHours()) + ' hrs '
            + moment(totalValidationTimeDiff).format("m") + ' min';

          let fhirServerLoadStartTime: moment.Moment = moment(result.fhirServerLoadStartTime);
          let fhirServerLoadEndTime: moment.Moment = moment(result.fhirServerLoadEndTime);
          let fhirServerLoadTimeDiff: number = fhirServerLoadEndTime.diff(fhirServerLoadStartTime);

          this.fhirServerLoadDuration = Math.floor(moment.duration(fhirServerLoadTimeDiff).asHours()) + ' hrs '
            + moment(fhirServerLoadTimeDiff).format("m") + ' min';
        }
        this.loadingLoadedJobLogEntry = false;
      },
        () => {
          this.loadingLoadedJobLogEntry = false;
        });
    } else {
      this.loadingLoadedJobLogEntry = false;
    }
  }
}
