<form [formGroup]="form" novalidate>

    <div class="config-header mat-elevation-z2">
        <section class="section">
            <mat-form-field class="run-name">
                <mat-label>Run Name</mat-label>
                <input id="run-name" matInput autocomplete="off" class="form-control" required formControlName="name">
                <mat-error id='mat-error-1' *ngIf="form.controls['name'].errors?.required || form.controls['name'].errors?.pattern">
                    Name is required.
                </mat-error>
                <mat-error id='mat-error-2' *ngIf="form.controls['name'].errors?.forbiddenValues">
                    Name already in use. Please choose another.
                </mat-error>
            </mat-form-field>
        </section>
        <!-- <section class="section">
        <mat-form-field>
            <mat-label>Run Type</mat-label>
            <mat-select>
                <mat-option *ngFor="let item of runTypes" [value]="item">
                    {{item}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section> -->
        <section class="section">
            <div class="date-picker">
              <mat-hint class="measurement-period">Measurement Period</mat-hint><br/>
                <mat-form-field class="start-date">
                    <mat-label>Start Date</mat-label>
                    <input id="measurement-period-start-date" matInput autocomplete="off"
                        [matDatepicker]="start" (dateInput)="dateChanged($event, true, true)"
                        (dateChange)="dateChanged($event, true, false)" formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                    <mat-error id='mat-error-3' *ngIf="form.controls['startDate'].errors?.required">
                        Valid start date is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="end-date">
                    <mat-label>End Date</mat-label>
                    <input id="measurement-period-end-date" matInput autocomplete="off"
                        [matDatepicker]="end"
                        (dateInput)="dateChanged($event, false, true)" (dateChange)="dateChanged($event, false, false)"
                        formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                    <mat-error id='mat-error-4' *ngIf="form.controls['endDate'].errors?.required">
                        Valid end date is required.
                    </mat-error>
                    <mat-error id='mat-error-4-1' *ngIf="form.controls['endDate'].errors?.invalid">
                        End date must be after start date.
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
        <section class="section-2">
            <button mat-stroked-button id="filter-btn" [matMenuTriggerFor]="filterMenu"
                #filterMenuTrigger="matMenuTrigger" [class.active]="getNumberOfAppliedFilters() > 0"
                (click)="menuOpen = !menuOpen; $event.stopPropagation();">
                <img src="../../../../../assets/images/ic_filter_list.svg" class="filter-icon">
                <span class="filter-title">Filter <span
                        *ngIf="getNumberOfAppliedFilters()>0">({{getNumberOfAppliedFilters()}})</span> </span>
                <img src="../../../../../assets/images/ic_arrow_drop_down.svg" class="arrow-icon">
            </button>
            <mat-menu #filterMenu="matMenu" class="filter-dropdown" (click)="$event.stopPropagation();">
                <div (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();"
                    class="filter-menu menuWrapper">
                    <section class="menu-section-1">
                        <mat-form-field>
                            <mat-label>Product Line/Product</mat-label>
                            <mat-select id="product-line-select" multiple [compareWith]="areRecordsEqual" name="selectedProductFilters"
                                [(ngModel)]="selectedProductFilters" [ngModelOptions]="{standalone: true}">
                                <ng-container *ngFor="let item of runConfigService.filterOptions['products']">
                                    <mat-option id="{{item.displayName}}" [value]="item">
                                        {{item.displayName}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="measure-select">
                            <mat-label>Display Name</mat-label>
                            <mat-select id="measure-select" multiple [compareWith]="areRecordsEqual" name="selectedMeasureFilters"
                                [(ngModel)]="selectedMeasureFilters" [ngModelOptions]="{standalone: true}">
                                <ng-container *ngFor="let item of runConfigService.filterOptions['measures']">
                                    <mat-option id="{{item.displayName}}" [value]="item">
                                        {{item.displayName}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </section>
                    <section class="menu-section-2">
                        <button mat-stroked-button class="clear-filters-btn" (click)="clearFilters()">
                            <img src="../../../../../assets/images/clear-filters.svg" class="filter-icon">
                        </button>
                        <button mat-stroked-button class="apply-btn"
                            (click)="applyFilters(); filterMenuTrigger.closeMenu();">
                            <span>Apply</span>
                        </button>
                    </section>
                </div>
            </mat-menu>
            <button mat-stroked-button id="create-btn" (click)="createConfiguration()">
                <span [hidden]="runConfigService.runConfig.id">Create Configuration</span>
                <span [hidden]="!runConfigService.runConfig.id">Save Configuration</span>
            </button>
        </section>
    </div>
</form>


<div class="spinner" *ngIf="loadingService.loadingConfig">
    <mat-spinner></mat-spinner>
</div>
<div class="alert alert-warning" id='select-alert' *ngIf="showSelectError">
    Please select at least one measure to run.
</div>
<div class="config-table" *ngIf="!loadingService.loadingConfig">
    <div class="sticky-header-windows-fix">
        <table mat-table [dataSource]="runConfigService.runConfigTableRows">
            <ng-container matColumnDef="header-row-group-spacer-1">
                <th mat-header-cell *matHeaderCellDef class="product-groupings spacer-header-fixed" [attr.colspan]="1"
                    [ngClass]="runConfigService.getClassByKey('spacer')">
                </th>
            </ng-container>
            <ng-container matColumnDef="header-row-group-spacer-2">
                <th mat-header-cell *matHeaderCellDef class="product-groupings spacer-header" [attr.colspan]="3">
                </th>
            </ng-container>
            <ng-container *ngFor="let header of runConfigService.displayedProductLineColumns; let i = index">
                <ng-container matColumnDef="header-row-group-{{i}}">
                    <th mat-header-cell *matHeaderCellDef class="product-groupings" [attr.colspan]="header.span"
                        [ngClass]="runConfigService.getClassByKey(header.name)">
                        <mat-checkbox class="l-checkbox" [labelPosition]="'before'"
                            [checked]="runConfigService.getCheckboxValueByColForProductLine(header.name)"
                            [indeterminate]="runConfigService.getIndCheckboxValueByColForProductLine(header.name)"
                            (change)="runConfigService.setCheckboxValueByColForProductLine(header.name, $event.checked)">
                            {{header.name}}</mat-checkbox>
                    </th>
                </ng-container>
            </ng-container>

            <ng-container *ngFor="let column of runConfigService.displayedColumns" >
                <ng-container [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]=1 style="min-width: 150px;"
                        [ngClass]="runConfigService.getClassByCol(column)" >
                        <span *ngIf="column === 'Measure Name'">
                            <mat-checkbox class="r-checkbox"
                                [checked]="runConfigService.getCheckboxValueForMeasureHeader()"
                                [indeterminate]="runConfigService.getIndCheckboxValueForMeasureHeader()"
                                (change)="runConfigService.setCheckboxValueForMeasureHeader($event.checked)">
                                Display Name
                            </mat-checkbox>
                        </span>
                        <span
                            *ngIf="column !== 'Measure Name' && column !== 'Version #' && column !== 'Measurement Year' && column !== 'Certification Date'">
                            <mat-checkbox class="l-checkbox" [labelPosition]="'before'"
                                [checked]="runConfigService.getCheckboxValueByColForHeader(column)"
                                [indeterminate]="runConfigService.getIndCheckboxValueByColForHeader(column)"
                                (change)="runConfigService.setCheckboxValueByColForHeader(column, $event.checked)">
                                {{column.split(separator)[0]}}
                            </mat-checkbox>
                        </span>
                        <span class="margin-bottom"
                            *ngIf="column === 'Version #' || column === 'Measurement Year' || column === 'Certification Date'">
                            {{column}}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="runConfigService.getClassByCol(column)">
                        <!-- Products -->
                        <span
                            *ngIf="column !== 'Measure Name' && column !== 'Version #' && column !== 'Measurement Year' && column !== 'Certification Date'">
                            <mat-checkbox [checked]="runConfigService.getCheckboxValueByCol(row, column)"
                                (change)="runConfigService.setCheckboxValueByCol(row, column, $event.checked)"
                                class="l-checkbox"></mat-checkbox>
                        </span>
                        <!-- Measure Name -->
                        <span *ngIf="column === 'Measure Name'">
                            <mat-checkbox class="l-checkbox"
                                [checked]="runConfigService.getCheckboxValueByRowForMeasure(row)"
                                [indeterminate]="runConfigService.getIndCheckboxValueByRowForMeasure(row)"
                                (change)="runConfigService.setCheckboxValueByRowForMeasure(row, $event.checked)">
                                {{row[column]}}
                            </mat-checkbox>
                        </span>
                        <!-- Version #, Measurement year, Certification Date -->
                        <span
                            *ngIf="column === 'Version #' || column === 'Measurement Year'">
                            {{row[column]}}
                        </span>
                        <span
                            *ngIf="column === 'Certification Date'">
                            {{row[column] | date: 'MM/dd/yyyy'}}
                        </span>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getHeaderRowGroups(); sticky: true;"></tr>
            <tr mat-header-row *matHeaderRowDef="runConfigService.displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: runConfigService.displayedColumns;"></tr>
        </table>
    </div>
</div>
