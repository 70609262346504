import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ComparatorService {

  constructor() { }

  sortData(sort: Sort, table: MatTableDataSource<any>) {
    table = new MatTableDataSource(table.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    }));
    return table;
  }

  sortUserData(sort: Sort, table: MatTableDataSource<any>) {
    table = new MatTableDataSource(table.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      if(typeof a[sort.active] === 'boolean' && typeof b[sort.active] === 'boolean') {
        return this.compareUserActiveHeader(a[sort.active], b[sort.active], isAsc)
      }
      else {
        return this.compare(a[sort.active], b[sort.active], isAsc);
      }
    }));
    return table;
  }

  compareUserActiveHeader(a: boolean, b: boolean, isAsc: boolean): number {
    if (a === b) {
      return 0;
    }
    return (a === true && b === false ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compare(a: string | number, b: string | number, isAsc: boolean): number {
    typeof a === 'string' ? a = a.toLowerCase() : null;
    typeof b === 'string' ? b = b.toLowerCase() : null;
    if (a === null) {
      a = '';
    }
    if (b === null) {
      b = '';
    }

    if (a === b) {
      return 0;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
