import { NgModule } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import { MsalGuardConfFactory, MsalInstanceFactory, MsalInterceptorConfFactory } from "../auth/auth.config";
import { TokenInterceptor } from '../../core/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    MsalModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfFactory
    },
    MsalGuard,
    MsalService,
  ],
  bootstrap: [MsalRedirectComponent]
})
export class AuthModule {
}
