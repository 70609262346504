import { Injectable } from '@angular/core';
import { UserRole } from '../models/enums/user-role-enum';
import { DataService } from './data.service';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userRole!: string;
  public userName!: string;
  public users!: User[];

  constructor(public dataService: DataService) {
  }

  public isDeveloper(): boolean {
    return this.userRole.toUpperCase() === UserRole.DEVELOPER;
  }

  public isAdministrator(): boolean {
    return this.userRole.toUpperCase() === UserRole.ADMINISTRATOR;
  }

  public isApprover(): boolean {
    return this.userRole.toUpperCase() === UserRole.APPROVER;
  }

  public initializeUser(userName: string, userRole: string): void{
    this.userRole = userRole;
    this.userName = userName;
  }
}
