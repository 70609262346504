<div id="container">

    <mat-toolbar id="header1" class="header"
        [hidden]="runConfigService.showCreateConfiguration || runConfigService.executeRun">
        <span id="title">
            <img id="executeIcon" src="assets/icons/execute-icon.svg" class="icon">
            Set Up & Execute Runs
        </span>
        <button mat-stroked-button id="create-btn" [disabled]="shouldDisableCreateConfigButton()"
            (click)="runConfigService.showCreateConfiguration = true;">
            <span>Create New Run Configuration</span>
        </button>
    </mat-toolbar>

    <mat-toolbar id="header2" class="header" [hidden]="!runConfigService.showCreateConfiguration">
        <span id="title">
            <button mat-button class="back" (click)="backFromCreate()"><img id="backIcon"
                    src="assets/images/arrow_back.svg"></button>
            <a [hidden]="runConfigService.runConfig.id" class="new-run-title">New Run Configuration</a>
            <a [hidden]="!runConfigService.runConfig.id" class="new-run-title">Edit Run Configuration</a>
        </span>
    </mat-toolbar>

    <mat-toolbar id="header3" class="header" [hidden]="!runConfigService.executeRun">
        <span id="title">
            <button mat-button class="back" (click)="backFromRun()"><img id="backIcon"
                    src="assets/images/arrow_back.svg"></button>
            <a class="new-run-title">Execute Run</a>
        </span>
    </mat-toolbar>
</div>


<ng-container [ngSwitch]="true">
    <div *ngSwitchCase="runConfigService.showCreateConfiguration">
        <app-create-configuration @slideInOut></app-create-configuration>
    </div>
    <div *ngSwitchCase="runConfigService.executeRun">
        <app-execute-run @slideInOut [runConfig]="configToRun" [refreshAnnounced]="refreshAnnounced"></app-execute-run>
    </div>
    <div *ngSwitchDefault>
        <div class="configuration-container">
            <cdk-virtual-scroll-viewport [itemSize]="120" class="viewport">
                <div *cdkVirtualFor="let config of runConfigService.runConfigurations; templateCacheSize: 0; trackBy: trackById">
                    <mat-accordion>
                        <mat-expansion-panel class="accordian">
                            <mat-expansion-panel-header class="expansion-panel">
                                <mat-panel-title>
                                    <span class="title" id="config-name">
                                        {{config.runName}}
                                        <button mat-button [hidden]="shouldHideEdit()" class="pencil"
                                            (click)="editName(config)"><img id="pencil_{{config.id}}" class="pencil-img"
                                                src="assets/images/pencil.svg"></button>
                                    </span>
                                    <span id="config-date-range">
                                        Date Range: {{config.startDate?.split('T')[0] | date: 'LLL'}}
                                        {{config.startDate?.split('T')[0] | date:
                                        'yyyy'}} -
                                        {{config.endDate?.split('T')[0] | date: 'LLL'}} {{config.endDate?.split('T')[0]
                                        | date: 'yyyy'}}
                                    </span>
                                </mat-panel-title>
                                <button mat-stroked-button id="editBtn"
                                    [hidden]="shouldHideEdit() || config.initiallyRun"
                                    (click)="$event.stopPropagation(); edit(config)">
                                    <span>Edit</span>
                                </button>
                                <button mat-stroked-button id="runBtn" (click)="$event.stopPropagation(); run(config)"
                                    [hidden]="shouldDisableCreateConfigButton()">
                                    <span [hidden]="isConfigurationRunning(config)">Run</span>
                                    <span [hidden]="!isConfigurationRunning(config)">
                                        <span>
                                            <svg class="icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                                                [class.loading]="isConfigurationRunning(config)"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                                                    fill="#61267A" />
                                            </svg>Running</span>
                                    </span>
                                </button>
                            </mat-expansion-panel-header>
                            <span class="top-border"></span>
                            <div *ngFor="let runLog of config.executeRunJobLogEntityList" (click)="viewLogs(runLog)"
                                class="run-log-row">
                                <svg class=" icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                                    [class.loading]="!runLog.endTime" xmlns="http://www.w3.org/2000/svg"
                                    [class.invisible]="runLog.endTime">
                                    <path
                                        d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                                        fill="#787A7A" />
                                </svg>
                                <span class=run-info>View logs for {{runLog.startTime | date: 'MMMM d, y hh:mm
                                    aaaaa\'m\'':dateService.getFormattedTimeZone()}}
                                    by {{runLog.fullName}}
                                </span>
                                <span *ngIf="!runLog.endTime" class="time-remaining">
                                    <span class="prog-text">{{runLog.totalMessages != null && runLog.totalMessages != 0
                                        ? (((runLog.processedMessages / runLog.totalMessages) * 100) | number : '1.0-0')
                                        : 0}}% complete</span>
                                    <mat-progress-bar class="prog" mode="determinate"
                                        value="{{ runLog.totalMessages != 0 ? (runLog.processedMessages / runLog.totalMessages) * 100 : 0}}"></mat-progress-bar>
                                </span>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-container>