export class Product {
    id!: number;
    name!: string;
    displayOrder!: number;
    productLineId!: number;
    deleted: boolean = false;
    auditable: boolean = false;
    productLineName?: string;
    inAnActiveRun: boolean = false;
    displayName!: string;
}