import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalType } from 'src/app/models/enums/modal-type-enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  modalTitle: string;
  modalMessage: string;
  primaryBtnText: string;
  secondaryBtnText: string;
  thirdBtnText: string;
  modalType: ModalType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalType = data.modalType;
    this.modalTitle = data.title;
    this.modalMessage = data.message;   
    this.primaryBtnText = data.primaryBtnText;
    this.secondaryBtnText = data.secondaryBtnText; 
    this.thirdBtnText = data.thirdBtnText; 
  }

  ngOnInit(): void {
  }

  

}
