<mat-toolbar id="header" class="header">
    <button mat-button class="button-icon" (click)="componentService.hidePopover()"><img id="backIcon"
            src="assets/images/arrow_back_grey.svg"></button>
    <span id="title">
        Manage Measure Packages
    </span>
    <button mat-stroked-button id="upload-btn" (click)="uploadMeasurePackages()">
        <span>Upload Measure Packages</span>
    </button>
</mat-toolbar>
<div class="spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div class="table-container" id="measure-table-container" *ngIf="!loading">
    <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
        [@listAnimation]='dataSource && dataSource.data.length && !updatedMeasure'>

        <!-- Buttons Column -->
        <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell">
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                <button mat-button class="button-icon-row" (click)="edit(cell)" [disabled]="cell.isInAnActiveRun"
                    [class.not-allowed]="cell.isInAnActiveRun"><img id="editIcon" src="assets/images/edit.svg"
                        [matTooltip]="cell.isInAnActiveRun ? 'Action is disabled while measure is running' : ''"></button>
                <button [hidden]="cell.deleted" mat-button class="button-icon" (click)="toggleActive(cell)"
                    [disabled]="cell.isInAnActiveRun" [class.not-allowed]="cell.isInAnActiveRun"><img id="visibleIcon"
                        src="assets/images/visibility_on.svg"
                        [matTooltip]="cell.isInAnActiveRun ? 'Action is disabled while measure is running' : ''"></button>
                <button [hidden]="!cell.deleted" mat-button class="button-icon" (click)="toggleActive(cell)"><img
                        id="visibleOffIcon" src="assets/images/visibility_off.svg"></button>
            </mat-cell>
        </ng-container>

        <!-- Measure Name Column -->
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header> Measure Title
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                {{cell.title}}
            </mat-cell>
        </ng-container>

        <!-- Short Name Column -->
        <ng-container matColumnDef="displayName">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header>
                Display Name</mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                {{cell.displayName}}
            </mat-cell>
        </ng-container>

        <!-- Version # Column -->
        <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header>
                Version #
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                {{cell.version}} </mat-cell>
        </ng-container>

        <!-- Measurement Year Column -->
        <ng-container matColumnDef="measurementYear">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell" mat-sort-header>
                Measurement Year
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell" [class.innactive]="cell.deleted">
                {{cell.measurementYear}}
            </mat-cell>
        </ng-container>

        <!-- Certification Date Column -->
        <ng-container matColumnDef="certificationDate">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell last" mat-sort-header>
                Certification Date
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell last" [class.innactive]="cell.deleted">
                <span *ngIf="cell.certificationDate">
                    {{cell.certificationDate | date: 'MM/dd/yyyy'}}
                </span>
            </mat-cell>
        </ng-container>

        <!-- Upload Date Column -->
        <ng-container matColumnDef="uploadDate">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell last" mat-sort-header>
                Upload Date
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell last" [class.innactive]="cell.deleted">
                {{cell.uploadDate | date: 'MM/dd/yyyy'}}
            </mat-cell>
        </ng-container>


        <!-- Default Product Lines Column -->
        <ng-container matColumnDef="lineOfBusiness">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell last">
                Default Product Lines
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell last" [class.innactive]="cell.deleted">
                <span *ngFor="let productLine of cell.selectedProductLines; let i = index;">
                    <span
                        *ngIf="cell.selectedProductLines.length === 1 || i === cell.selectedProductLines.length - 1">{{productLine.name}}</span>
                    <span *ngIf="cell.selectedProductLines.length > 1 && i !== cell.selectedProductLines.length - 1"
                        class="multiple-product-lines">{{productLine.name}}
                        <li class="bullet"></li>
                    </span>
                </span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
</div>