// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    showNCQAModeHeader: false,
    api: {
      base: '/api/',
      endpoints: {
        audit: 'audit',
        login: 'login',
        logging: 'log',
        logout: 'logout',
        jobLog: 'jobs/logs',
        configuration: 'configuration',
        files: 'files',
        validate: 'validate',
        errorLog: 'error-log',
        fhirValidation: 'fhir_validation',
        resource: 'resource',
        runConfig: 'run/config',
        members: 'members',
        measures: 'measures',
        products: 'products',
        fhirServier: 'fhir-server',
        runResults: 'run/results',
        users: 'users'
      }
    },
    auth: {
      clientId: '023b19c3-23a4-4d8d-b4f6-4a08a81cc21d',
      instance: 'https://login.microsoftonline.com/aa644c39-2af9-4d35-8093-80c24543d76a'
    },
    timeout: {
      timeoutStart: 1800,
      countDownStart: 30
    },
    downloadThreads: 10,
    uploadThreads: 25,
    uploadChunkSize: 1024 * 64
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
    // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  