<div class="container">
    <div class="fhir-validation-container">
        <div>
            <p id="loading-title" class="title">
                <span [hidden]="!loadingService.validating">
                    Validating FHIR Bundles...
                </span>
                <span [hidden]="loadingService.validating || configurationService.applicationState === 'failed'">
                    FHIR Bundle Validation Complete
                </span>
                <span *ngIf="configurationService.applicationState === 'failed'">
                    FHIR Bundle Validation Failed
                </span>
                <mat-progress-bar mode="determinate" id="progress-bar" value="{{configurationService.fhirValidationProgress}}" [hidden]="configurationService.applicationState === 'failed' || !loadingService.validating"></mat-progress-bar>
            </p>
        </div>
        <div class="table-container">
            <mat-table #table [dataSource]="dataSource">

                <!-- FHIR Resource Column -->
                <ng-container matColumnDef="fhir_resource">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell"> FHIR Resource
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell"> 
                        <img [hidden]="!shouldShowNonCritical(cell)" src="../../../../../assets/images/non-critical.svg" class="alert-icon">
                        {{cell.fhirResource  | titlecase}}
                    </mat-cell>
                </ng-container>

                <!-- Type Errors Column -->
                <ng-container matColumnDef="type_errors">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell">
                        Type Errors </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell"> {{cell.typeErrors}}
                    </mat-cell>
                </ng-container>

                <!-- Reference Errors Column -->
                <ng-container matColumnDef="reference_errors">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Reference Errors
                    </mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="mat-standard-cell">
                        {{cell.referenceErrors}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
        </div>
        <div>
            <span [hidden]="loadingService.validating">
                This action will overwrite all existing loaded data.
            </span>
            <span [hidden]="!loadingService.validating">
                Error counts may increase as validation proceeds.
            </span>
            <button mat-button id="load-bundles-btn" (click)="loadFhirBundles()" [disabled]="shouldDisableLoadBundles()">
                <span>Load Bundles</span>
            </button>
        </div>
    </div>
</div>