export class ExecuteRunRow {
    measureName: string = '';
    productLine: string = '';
    lineOfBusiness: string = '';
    error = false;
    finished = false;

    constructor(measureName: string, productLine: string, lineOfBusines: string) {
        this.measureName = measureName;
        this.productLine = productLine;
        this.lineOfBusiness = lineOfBusines;
    }
}