import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-measure-run-snackbar',
  templateUrl: './measure-run-snackbar.component.html',
  styleUrls: ['./measure-run-snackbar.component.scss']
})
export class MeasureRunSnackbarComponent implements OnInit {

  constructor(public snackBarRef: MatSnackBarRef<MeasureRunSnackbarComponent>) { }

  ngOnInit(): void {
  }

}