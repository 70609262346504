import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { ContentValidationService } from 'src/app/core/content-validation.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FileService } from 'src/app/core/file.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { RefreshService } from 'src/app/core/refresh.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { ErrorLogComponent } from 'src/app/framework/error-log/error-log.component';
import { ModalType } from 'src/app/models/enums/modal-type-enum';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';

@Component({
  selector: 'app-wayfinding',
  templateUrl: './wayfinding.component.html',
  styleUrls: ['./wayfinding.component.scss']
})
export class WayfindingComponent implements OnInit {

  public errorLogRef!: MatDialogRef<ErrorLogComponent>;
  @Input() stopRefreshInterval!: Subject<void>;
  public get WayfindingStageEnum(): typeof WayfindingStageEnum {
    return WayfindingStageEnum;
  }

  constructor(public configurationService: ConfigurationService,
    public loadingService: LoadingService,
    public dialogService: DialogService,
    public jobLogService: JobLogService,
    public wayfindingService: WayfindingService,
    public userService: UserService,
    public dialog: MatDialog,
    public contentValidationService: ContentValidationService,
    public refreshService:RefreshService) { }

  ngOnInit(): void {
  }

  cancel(): void {
    let modalTitle = 'Data Loss Warning';
    let modalMessage = 'Cancelling will undo the entire data loading process up to this point. You\'ll have to begin the loading sequence again from the start.'
    let primaryBtnText = 'Keep Loading';
    let secondaryBtnText = 'Cancel and Start Over';

    if (this.configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_BUNDLES) {
      this.dialogService.openModal(ModalType.WARN, modalTitle, modalMessage, primaryBtnText, secondaryBtnText, () => {
      }, () => {
        this.stopRefreshInterval?.next();
        this.configurationService.cancel();
        this.wayfindingService.cancel();
        this.loadingService.validating = false;

      });
    } else {
      this.configurationService.cancel();
      this.wayfindingService.cancel();
    }

  }

  viewLogs(): void {
    this.errorLogRef = this.dialog.open(ErrorLogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'error-log-view'
    });
    this.errorLogRef.componentInstance.loadDataJobLogId = this.configurationService.currentJobLogId;
  }

}
