import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable, Subscription, zip } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { ExportService } from 'src/app/core/export.service';
import { RunConfigService } from 'src/app/core/run-config.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { UserService } from 'src/app/core/user.service';
import { DownloadGapListResponse } from 'src/app/models/download-gap-list-response';
import { RunConfiguration } from 'src/app/models/run-configuration';
import { GenerateNcqaOutputDialogComponent } from '../generate-ncqa-output-dialog/generate-ncqa-output-dialog.component';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],
  host: {
    "(window:click)": "onClick()"
  }
})
export class ViewComponent implements OnInit {

  generateNcqaOuptutDialogRef!: MatDialogRef<GenerateNcqaOutputDialogComponent>;
  configListLoadedSubscription: Subscription;
  public downloadingGapList: boolean = false;
  public downloadingIdssFile: boolean = false;
  public downloadingMemberLevelOutput: boolean = false;
  public isMenuOpen: boolean = false;
  public selectedRunLog: any;
  public selectedRunConfig: any;


  constructor(public userService: UserService, public dateService: DateService,
    public runConfigService: RunConfigService, public dataService: DataService,
    public configurationService: ConfigurationService, private changeDetectorRef: ChangeDetectorRef,
    public exportService: ExportService, public snackbarService: SnackbarService,
    public dialog: MatDialog, public datePipe: DatePipe) {
    this.configListLoadedSubscription = this.runConfigService.configListLoadedEventEmitter.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  onClick() {
    this.isMenuOpen = false;
  }

  openMen() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  backFromResults() {
    this.runConfigService.showRunResults = false;
    this.runConfigService.loadRunConfigurations();
    this.configurationService.getConfiguration().subscribe();
  }

  isConfigRun(config: RunConfiguration): boolean {
    return config.executeRunJobLogEntityList.filter(f => f.endTime !== null).length > 0;
  }

  ngOnDestroy() {
    this.configListLoadedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe(() => {
      this.runConfigService.loadRunConfigurations();
    });
  }



  viewResults(runLog: any, runConfig: any): void {
    this.runConfigService.runLogId = runLog.id;
    this.runConfigService.showRunResults = true;
    this.selectedRunLog = runLog;
    this.selectedRunConfig = runConfig;
  }

  downloadGapList(runLog: any): void {
    this.downloadingGapList = true;
    this.changeDetectorRef.markForCheck();
    this.dataService.downloadGapList(runLog.id).subscribe((response: DownloadGapListResponse[] | null) => {
      let totalGaps = 0;
      if (response && response.length) {
        const gaps: any[] = [];
        totalGaps = response[0].total_record_count;
        response.forEach((res) => {
          res.gaps.forEach((gap) => {
            gaps.push({
              "SUB_MEASURE_ID": gap.sub_measure_id,
              "SUB_MEASURE_DESC": gap.sub_measure_desc, "NUMERATOR": +gap.numerator,
              "DENOMINATOR": +gap.denominator, "DENOMINATOR_DATE": gap.denominator_date,
              "EXCLUSION": +gap.exclusion, "LOB": gap.lob,
              "MASTER_MEMBER_ID": gap.master_member_id, "MEMBER_NBR": gap.member_nbr,
              "MEMBER_FIRST_NAME": gap.member_first_name, "MEMBER_LAST_NAME": gap.member_last_name,
              "MEMBER_MIDDLE_NAME": gap.member_middle_name, "DOB": new Date(gap.dob).toLocaleDateString(),
              "MEMBER_GENDER": gap.member_gender, "GROUP_ID": gap.group_id,
              "GROUP_NAME": gap.group_name, "HEDIS_YEAR": gap.hedis_year,
              "HEDIS_RUN_MONTH": new Date(gap.hedis_run_month).toLocaleDateString(), "HEDIS_RUN_ID": gap.hedis_run_id
            });
          });
        });
        this.exportService.exportArrayAsCSV(gaps, ('Gaplist_' + new Date().toLocaleString()).replace(',', '_'));
        this.downloadingGapList = false;
        this.isMenuOpen = false;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }
    }, () => {
      this.downloadingGapList = false;
      this.isMenuOpen = false;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  downloadIdssFiles(executeRunJobLogId: number, runConfigId: number) {
    this.downloadingIdssFile = true;
    this.dataService.downloadIdssFiles(executeRunJobLogId, runConfigId).subscribe((response: any | null) => {
      if (response && response.length > 0) {
        let date = new Date().toLocaleString().replace(',', '_');
        response.forEach((res: any) => {
          let productLine = Object.keys(res)[0];
          this.exportService.exportXmlFile(res[productLine]!, productLine + '_Idss_' + date);
        });
        this.downloadingIdssFile = false;
        this.isMenuOpen = false;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      } else {
        this.snackbarService.showNegativeBar('Could not download IDSS file please see logs for more details')
        this.downloadingIdssFile = false;
        this.isMenuOpen = false;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }
    }, () => {
      this.snackbarService.showNegativeBar('Could not download IDSS file please see logs for more details')
      this.downloadingIdssFile = false;
      this.isMenuOpen = false;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  downloadMemberLevelOutputFiles(executeRunJobLogId: number) {
    this.downloadingMemberLevelOutput = true;
    let fileRequests: Observable<any>[] = [];
    this.dataService.getMemberLevelOutputKeys(executeRunJobLogId).subscribe((keyList) => {
      if (keyList) {
        keyList.forEach((key: string) => {
          fileRequests.push(this.dataService.getMemberLevelOutputFile(key));
        });
      }
      let zip: JSZip = new JSZip();
      forkJoin(fileRequests).subscribe((responses) => {
        responses.forEach((response) => {
          if (response.key) { zip.file(response.key.replace(/_\d{4}(-\d{2}){3}:\d{2}/, '_' + this.datePipe.transform(this.selectedRunLog.startTime, 'yyyy-MM-dd-HH:mm')).replace(':', '_').split("/")[2], response.result); }
        })
        zip.generateAsync({ type: 'blob' })
          .then(function (content) {
            saveAs(content, `MemberLevelOutput_Run_${executeRunJobLogId}` + '.zip');
          });
        this.downloadingMemberLevelOutput = false;
      }, () => {
        this.downloadingMemberLevelOutput = false;
        this.snackbarService.showNegativeBar('There was an error downloading files, please try again')
      });
    }, () => {
      this.downloadingMemberLevelOutput = false;
      this.snackbarService.showNegativeBar('No files found for selected run')
    });
  }
}
