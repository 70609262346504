import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DataService } from 'src/app/core/data.service';
import { FileService } from 'src/app/core/file.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { ApplicationStateEnum } from 'src/app/models/enums/application-state-enum';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';
import { FhirValidation } from 'src/app/models/fhir-validation';

@Component({
  selector: 'app-fhir-validation',
  templateUrl: './fhir-validation.component.html',
  styleUrls: ['./fhir-validation.component.scss']
})
export class FhirValidationComponent implements OnInit {

  public displayedColumns: string[] =
    ['fhir_resource', 'type_errors', 'reference_errors'];
  public fhirValidations: FhirValidation[] = [];
  public dataSource: MatTableDataSource<FhirValidation> = new MatTableDataSource();
  private loadedCurrentJobSubscription: Subscription;
  @Input() stopRefreshInterval!: Subject<void>;
  @Input() public refreshAnnounced!: Observable<void>;
  public refreshSubscription!: Subscription;



  constructor(public loadingService: LoadingService, public dataService: DataService,
    public wayfindingService: WayfindingService, public configurationService: ConfigurationService,
    public userService: UserService, public jobLogService: JobLogService, public fileService: FileService) {
    this.loadedCurrentJobSubscription = this.jobLogService.loadedCurrentJobAnnounced.subscribe(() => {
      this.initializeState();
    });
  }

  ngOnInit(): void {
    this.loadingService.initialLoad = true;
    this.getFhirValidations();
    this.initializeState();
    this.subscribeToRefresh();
  }
  subscribeToRefresh() {
    this.refreshSubscription = this.refreshAnnounced.subscribe(async () => {
      await this.jobLogService.getCurrentLoadDataJobLogEntry(this.configurationService.currentJobLogId!);
    });
    this.stopRefreshInterval.subscribe(() => this.refreshSubscription.unsubscribe());
  }

  ngOnDestroy(): void {
    this.loadedCurrentJobSubscription.unsubscribe();
    this.refreshSubscription?.unsubscribe();
  }

  initializeState(): void {
    if (this.jobLogService.currentValidationDuration || this.configurationService.applicationState === ApplicationStateEnum.FAILED) {
      this.loadingService.validating = false;
      this.refreshSubscription?.unsubscribe();
    } else {
      this.loadingService.validating = true;
    }
    this.getFhirValidations();
  }

  getFhirValidations() {
    this.wayfindingService.refreshMessaging();
    this.dataService.getFhirValidations().subscribe((entries: FhirValidation[] | null) => {
      if (entries) {
        this.dataSource = new MatTableDataSource(entries);
      }
    });
  }

  loadFhirBundles(): void {
    this.configurationService.currentWayfindingStage = WayfindingStageEnum.LOAD_BUNDLES;
    this.loadingService.loading = true;
    this.wayfindingService.refreshMessaging();
    this.dataService.startLoadingBundles(this.configurationService.currentJobLogId!).subscribe();
    this.dataService.updateMemberData().subscribe();
  }

  shouldShowNonCritical(cell: FhirValidation): boolean {
    return +cell.referenceErrors > 0 || +cell.typeErrors > 0;
  }

  shouldDisableLoadBundles() {
    return this.loadingService.validating || this.configurationService.applicationState === 'failed' || !(this.userService.isAdministrator() || this.userService.isDeveloper())
      || this.configurationService.applicationState.toLowerCase() === 'running';
  }
}
