import { Injectable } from '@angular/core';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { FileMetadata } from '../models/file-metadata';
import { FileValidation } from '../models/file-validation';
import { DataService } from './data.service';
import { LoadingService } from './loading.service';
import { RefreshService } from './refresh.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private _incomingFiles: FileMetadata[] = [];
  private _fileValidations: FileValidation[] = [];
  
  public refreshedFilesEventEmitter: Subject<any> = new Subject<any>();
  public fileValidationsEventEmitter: Subject<any> = new Subject<any>();

  public refreshedFilesAnnounced: Observable<any> = this.refreshedFilesEventEmitter.asObservable();
  public fileValidationsAnnounced: Observable<any> = this.fileValidationsEventEmitter.asObservable();

  constructor(public dataService: DataService, public loadingService: LoadingService) {
  }

  get incomingFiles(): FileMetadata[] {
    return this._incomingFiles;
  }

  set incomingFiles(value: FileMetadata[]) {
    this._incomingFiles = value;
  }

  get fileValidations(): FileValidation[] {
    return this._fileValidations;
  }

  set fileValidations(value: FileValidation[]) {
    this._fileValidations = value;
  }

  public invalidFilesPresent(): boolean {
    return (this.incomingFiles.filter(f => !f.valid).length > 0)
  }

  public allFilesValid(ncqaMode: boolean): boolean {
    if (this.fileValidations.filter(f => f.headerValidationResult).length > 0) {
      return true;
    } else {
      return this.incomingFiles.length > 0 && (ncqaMode || !(this.fileValidations.filter(f => !f.validated && f.isRequired).length > 0));
    }
  }

  public isContentValidationNonCriticalError() {
    return this.fileValidations.filter(f => f.missingRequiredFieldsCount && +f.missingRequiredFieldsCount > 0).length > 0;
  }

  validateFileNames() {
    this.fileValidations.forEach(fileValidation => {
      let matchedFiles = this.incomingFiles.filter(incomingFile => String(incomingFile.fileName).startsWith(fileValidation.fileName));
      if (matchedFiles?.length > 0) {
        matchedFiles.forEach(file => file.valid = true);
        fileValidation.lastModified = matchedFiles[0].lastModified;
        fileValidation.validated = true;
      }
    });
    this.loadingService.loading = false;
    this.refreshedFilesEventEmitter.next();
  }

  public getFileValidations() {
    this.dataService.getFileValidations().subscribe((fileValidations: FileValidation[] | null) => {
      if (fileValidations) {
        this.fileValidations = fileValidations;
        this.fileValidations.forEach((validation) => {
          if (validation.lastModified) {
            validation.lastModified = new Date(validation.lastModified.toString() + ' UTC');
          }
          if (validation.headerValidationResult) {
            validation.headerValidationResult = JSON.parse(validation.headerValidationResult.toString());
            validation.missingColumns = validation.headerValidationResult.missing_columns.toString();
          } else {
            validation.missingColumns = '-'
          }
          if (validation.missingRequiredFieldsCount === null) {
            validation.missingRequiredFieldsCount = '-';
          }
          if (validation.totalRows === null) {
            validation.totalRows = '-';
          }
        });
      }
      this.fileValidationsEventEmitter.next();
    });
  }

  public allContentValidated(): boolean {
    return (this.fileValidations && this.fileValidations.filter(f => f.headerValidationResult && !f.finishedValidation).length === 0)
  }

  public hasContentToValidate(): boolean {
    return (this.fileValidations && this.fileValidations.filter(f => f.headerValidationResult && f.finishedValidation).length > 0)
  }

  public refreshIncomingFiles(): void {
    this.loadingService.loading = true;
    this.dataService.getIncomingFiles().subscribe((files: FileMetadata[] | null) => {
      if (files) {
        this.incomingFiles = files;
        this.validateFileNames();
      } else {
        this.incomingFiles = [];
        this.loadingService.loading = false;
      }
    },
      () => {
        this.loadingService.loading = false;
      });
  }

  validateFileExtension(fileName: string, allowedFileExtensions: string[]) {
    let fileParts: string[] = fileName.split(".");
    return allowedFileExtensions.includes(fileParts[fileParts.length - 1])
  }

  /*
  Converts carriage returns to new lines
  Replaces 2 or more spaces with a single space
  Removes leading and trailing spaces
*/
  public cleanText(text: string): string {
    return text.replace(/[\n\r]+/g, '\n').replace(/\s{2,}/g, ' ').replace(/^\s+|\s+$/, '');
  }

  /**
 * Read |file| in chunks with line cuttoff handling.
 *
 * @param {File} file - The file to be read.
 * @param {number} chunkSize - The maximum amount of file to be read into memory at a time.
 * @param {Function} onChunkHandler - Called for each chunk. Must return an Observable so that it is guaranteed to execute before the next chunk is read.
 * @param {Function} onCompleteHandler - Called when the end of the file is reached.
 */
  readFileInChunks(file: File, chunkSize: number, onChunkHandler: Function = () => { return of(); }, onCompleteHandler: Function = () => { }) {
    let fileSize: number = file.size;
    let fileOffset: number = 0;
    let readProgress: number = 0;
    let readBuffer = '';

    let textDecoder: TextDecoder = new TextDecoder();
    let fileReader: FileReader = new FileReader();

    let readEventHandler = (evt: any) => {
      if (evt.target.error == null) {

        // Pre-pends the last saved line to the next chunk so we don't loose any data
        readBuffer += this.cleanText(textDecoder.decode(evt.target.result, { stream: true }));

        let lines: string[] = readBuffer.split('\n');

        // Pop the last line from the list and save it for the next chunk incase it is a partial
        readBuffer = lines.pop()!;

        fileOffset += chunkSize;
        readProgress = fileOffset > fileSize ? 100 : Math.floor(fileOffset / fileSize * 100);

        onChunkHandler(lines, readProgress).subscribe(() => {
          readChunk(fileOffset, chunkSize, file);
        }, () => {
          return;
        });

      } else {
        console.log("Read error: " + evt.target.error);
        return;
      }
    }

    let readChunk = (fileOffset: number, chunkSize: number, file: File) => {
      fileReader.onload = readEventHandler;
      if (fileOffset >= fileSize) {
        // If anything left in the buffer at the end of the file, process it
        if (readBuffer) {
          onChunkHandler([readBuffer], readProgress).subscribe(() => {
            readBuffer = '';
            onCompleteHandler();
            return;
          }, () => {
            return;
          })
        } else {
          onCompleteHandler();
          return;
        }
      } else {
        let fileSlice = file.slice(fileOffset, chunkSize + fileOffset);
        // Using this and TextDecoder incase file is in UTF-8 and not ASCII
        fileReader.readAsArrayBuffer(fileSlice);
      }
    }

    // read first chunk
    readChunk(fileOffset, chunkSize, file);
  }

  /**
   * 
   * @returns true if all content has been validated;
   */
  refreshCalls() {
    this.getFileValidations();
    return this.allContentValidated() && this.hasContentToValidate();
  }
}
