<div id="run-results-container">
  <ng-template #runInfo>
    <section id="run-details">
      <div id="run-name" class="run-details-item">
        <span>RUN NAME</span>
        <span>{{selectedRunConfig.runName}}</span>
      </div>
      <div id="measurement-period" class="run-details-item">
        <span>MEASUREMENT PERIOD</span>
        <span>{{selectedRunConfig.startDate?.split('T')[0] | date: 'LLL'}}
          {{selectedRunConfig.startDate?.split('T')[0] | date:
          'yyyy'}} -
          {{selectedRunConfig.endDate?.split('T')[0] | date: 'LLL'}} {{selectedRunConfig.endDate?.split('T')[0]
          | date: 'yyyy'}}</span>
      </div>
      <div id="data-version" class="run-details-item">
        <span>DATA VERSION</span>
        <span>{{selectedRunLog.dataLoadDate | date:'MM/dd/YYYY @ hh:mm a'}}</span>
      </div>
      <div id="run-date" class="run-details-item">
        <span>RUN DATE</span>
        <span>{{selectedRunLog.startTime | date:'MM/dd/YYYY @ hh:mm a'}} {{selectedRunLog.fullName |
          nameAbbreviation}}</span>
      </div>
    </section>
  </ng-template>
  <div class="header-row">
    <mat-tab-group id="run-results-tab-group" #tabGroup (selectedTabChange)="selectedTabIndex = $event.index"
      [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="Summary"></mat-tab>
      <mat-tab label="Member Level"></mat-tab>
    </mat-tab-group>
    <button mat-button id="export-btn" (click)="exportClicked.emit()">Export</button>
  </div>


  <div class="summary-tab" *ngIf="selectedTabIndex === 0">
    <ng-container *ngTemplateOutlet="runInfo"></ng-container>
    <app-run-results-summary (exportData)="exportData()" [exportClicked]="exportClicked"
      [selectedRunLog]="selectedRunLog" ></app-run-results-summary>
  </div>

  <div class="member-level-tab" *ngIf="selectedTabIndex === 1">
    <ng-container *ngTemplateOutlet="runInfo"></ng-container>
    <app-run-results-member-level (exportData)="exportData()" [exportClicked]="exportClicked"
      [selectedRunLog]="selectedRunLog"></app-run-results-member-level>
  </div>
</div>