import { Injectable } from '@angular/core';
import { forkJoin, interval, Observable, Subject, Subscription } from 'rxjs';
import { EventEmitter } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {

  refreshedEmitter: Subject<void> = new Subject<void>();
  subscription!: Subscription;

  constructor() { }

  public stopInterval() {
    this.subscription?.unsubscribe();
  }

  public startEmitting(seconds: number): void {
    this.stopInterval();
    this.subscription = interval(seconds * 1000).subscribe(() => {

      this.refreshedEmitter.next()
    });
  }

  public getObservable(): Observable<void> {
    return this.refreshedEmitter.asObservable();
  }
}
