import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading: boolean = false;
  private _validating: boolean = false;
  private _loadingConfig: boolean = false;
  private _initialLoad = true;

  get validating(): boolean {
    return this._validating;
  }

  set validating(value: boolean) {
    this._validating = value;
  }

  get loading(): boolean {
    return this._loading && (!this._validating);
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get loadingConfig(): boolean {
    return this._loadingConfig;
  }

  set loadingConfig(value: boolean) {
    this._loadingConfig = value;
  }

  get initialLoad(){
    return this._initialLoad;
  }

  set initialLoad(value: boolean){
    this._initialLoad = value;
  }

  constructor() { }

  public shouldShowLoadingElements(){
    if(!this._loading) {
      this.initialLoad = false;
    }
    return this._loading && this.initialLoad;
  }
}
