import { MeasurementPeriod } from "./measurement-period";
import { RunConfigFilterOptions } from "./run-config-filter-options";
import { RunLog } from "./run-log";

export class RunConfiguration {
    id!: number;
    runName: string = '';
    measurementPeriod: string = 'Custom';
    startDate!: Date;
    endDate!: Date;
    configuration!: string;
    initiallyRun!: boolean;
    executeRunJobLogEntityList: RunLog[] = [];
    selectedMeasurementPeriod: MeasurementPeriod = new MeasurementPeriod(1, 'Custom', 999, null, null);

    constructor() {
        this.initiallyRun = false;
        this.selectedMeasurementPeriod = new MeasurementPeriod(1, 'Custom', 999, null, null);
    }
}