import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentService } from 'src/app/core/component.service';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DataService } from 'src/app/core/data.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { UserService } from 'src/app/core/user.service';
import { Product } from 'src/app/models/product';
import { listAnimation } from 'src/app/shared/animations/table-row-animation';
import { EditProductDialogComponent } from 'src/app/shared/dialogs/edit-product-dialog/edit-product-dialog.component';

@Component({
  selector: 'app-manage-organization-information',
  templateUrl: './manage-organization-information.component.html',
  styleUrls: ['./manage-organization-information.component.scss'],
  animations: [listAnimation]
})
export class ManageOrganizationInformationComponent implements OnInit {

  public form!: UntypedFormGroup;
  public loading = false;
  public displayedColumns: string[] =
    ['buttons', 'displayName', 'productLineName', 'auditable'];
  public productLineMap: Map<number, any> = new Map();
  public products: Product[] = [];
  public productLines: any[] = [];
  public dataSource: MatTableDataSource<Product> = new MatTableDataSource();
  public editProductDialogRef!: MatDialogRef<EditProductDialogComponent>;
  public _readOnly = true;

  constructor(public fb: UntypedFormBuilder, public componentService: ComponentService, public dataService: DataService, public dialog: MatDialog,
    public userService: UserService, public snackBar: SnackbarService, public configurationService: ConfigurationService) { }

  ngOnInit(): void {
    this.loading = true;
    this.createForm();

    this.configurationService.getConfiguration().subscribe(() => {
      this.form.get('organizationName')?.setValue(this.configurationService.organizationName);
      this.form.get('organizationId')?.setValue(this.configurationService.organizationId);
      this.form.get('cmsContractNumber')?.setValue(this.configurationService.cmsContractNumber);
    });

    if (this.userService.isDeveloper() || this.userService.isAdministrator() || this.userService.isApprover()) {
      this.readOnly = false;
    } else {
      this.readOnly = true;
    }

    this.refreshProducts();
  }

  saveOrganizationName(): void {
    if (this.form.get('organizationName')?.valid) {
      this.configurationService.organizationName = this.form.get('organizationName')!.value;
      this.dataService.saveOrganizationName(this.configurationService.configuration).subscribe();
    }
  }

  saveOrganizationId(): void {
    if (this.form.get('organizationId')?.valid) {
      this.configurationService.organizationId = this.form.get('organizationId')!.value;
      this.dataService.saveOrganizationId(this.configurationService.configuration).subscribe();
    }
  }


  saveOrganizationContractNumber(): void {
    if (this.form.get('cmsContractNumber')?.valid) {
      this.configurationService.cmsContractNumber = this.form.get('cmsContractNumber')!.value;
      this.dataService.saveOrganizationContractNumber(this.configurationService.configuration).subscribe();
    }
  }


  get readOnly(): boolean {
    return this._readOnly;
  }

  set readOnly(value: boolean) {
    this._readOnly = value;
  }

  refreshProducts(): void {
    this.dataService.getAllProductLines().subscribe(productLines => {
      this.productLines = productLines!;
      productLines?.forEach(productLine => {
        this.productLineMap.set(productLine.id, productLine);
      });
      this.dataService.getAllProducts().subscribe(products => {
        products?.forEach((product: Product) => {
          product.productLineName = this.productLineMap.get(product.productLineId)?.displayName;
        });
        this.products = products!;
        this.dataSource = new MatTableDataSource(this.products);
        this.loading = false;
      });
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      organizationName: ['', [Validators.required, Validators.pattern(/\S/)]],
      organizationId: ['', [Validators.required, Validators.pattern(/\S/)]],
      cmsContractNumber: ['', [Validators.required, Validators.pattern(/\S/)]]
    });
  }

  addNewProduct(selectedProductLineId?: number) {
    let product = new Product();
    if (selectedProductLineId) {
      product.productLineId = selectedProductLineId;
    }
    this.editProductDialogRef = this.dialog.open(EditProductDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        product: product,
        productLines: this.productLines,
        existingProducts: this.products
      }
    });
    this.editProductDialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.dataService.saveProduct(response.product).subscribe((savedProduct) => {
          if (savedProduct) {
            this.products.push(savedProduct);
          }
          this.refreshProducts();
          if (response.saveAndAdd) {
            this.addNewProduct(savedProduct?.productLineId);
          }
        }, (e) => {
          this.snackBar.showNegativeBar(e.message);
        });
      } else {
        this.dataSource = new MatTableDataSource(this.products);
      }
    });
  }

  public toggleActive(product: Product): void {
    product.deleted = !product.deleted;
    this.dataService.saveProduct(product).subscribe();
  }

  public edit(product: Product): void {
    this.editProductDialogRef = this.dialog.open(EditProductDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        product: product,
        productLines: this.productLines,
        existingProducts: this.products.filter(p => p.id !== product.id)
      }
    });
    this.editProductDialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.dataService.saveProduct(response.product).subscribe((savedProduct) => {
          if (savedProduct) {
            product = savedProduct;
          }
          this.refreshProducts();
        }, (e) => {
          this.snackBar.showNegativeBar(e.message);

        });
      } else {
        this.dataSource = new MatTableDataSource(this.products);
      }
    });
  }

  sortData(sort: Sort) {
    this.dataSource = new MatTableDataSource(this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare((a as any)[sort.active], (b as any)[sort.active], isAsc);
    }));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      a = '';
    }
    if (b === null) {
      b = '';
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
