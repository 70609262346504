import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';

@Component({
  selector: 'app-load-fhir',
  templateUrl: './load-fhir.component.html',
  styleUrls: ['./load-fhir.component.scss']
})
export class LoadFhirComponent implements OnInit {

  private loadedCurrentJobSubscription: Subscription;
  @Input() stopRefreshInterval!: Subject<void>;
  @Input() public refreshAnnounced!: Observable<void>;
  public refreshSubscription!: Subscription;

  constructor(public configurationService: ConfigurationService, public loadingService: LoadingService, public jobLogService: JobLogService, public wayfindingService: WayfindingService) {
    this.loadedCurrentJobSubscription = this.jobLogService.loadedCurrentJobAnnounced.subscribe(() => {
      this.initializeState();
    });
  }

  ngOnInit(): void {
    this.subscribeToRefresh();
  }

  subscribeToRefresh() {
    this.refreshSubscription = this.refreshAnnounced.subscribe(() => {
      this.initializeState();
      this.jobLogService.getCurrentLoadDataJobLogEntry(this.configurationService.currentJobLogId!);
    });
    this.stopRefreshInterval.subscribe(() => this.refreshSubscription.unsubscribe());
  }

  initializeState() {
    if (!this.jobLogService.currentEndTime) {
      this.loadingService.loading = true;
    } else {
      this.loadingService.loading = false;
      if (!this.jobLogService.currentJobError) {
        this.jobLogService.setLoadedJobLogFromCurrentJobLog();
      }
    }
    this.wayfindingService.refreshMessaging();
  }


  ngOnDestroy(): void {
    this.loadedCurrentJobSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
  }

}
