import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { TimeoutComponent } from './dialogs/timeout/timeout.component';
import { TimeoutDialogComponent } from './dialogs/timeout-dialog/timeout-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ScrollingModule,
        NgxSkeletonLoaderModule
    ],
    declarations: [
        TimeoutComponent,
        TimeoutDialogComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TimeoutComponent,
        ScrollingModule,
        NgxSkeletonLoaderModule
    ]
})
export class SharedModule {
}
