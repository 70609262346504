import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameAbbreviation'
})
export class NameAbbreviationPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if(value !== undefined && value.length !== 0){
        let split = value.split(' ');
        return `${split[0][0].toUpperCase()}. ${split[split.length - 1]}`;
    }
    return "";
  }

}
