import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  logError(error: any) {
    if (error instanceof HttpErrorResponse) {
      console.error('HTTP error:', error.message, 'Status code:', (<HttpErrorResponse>error).status);
    } else if (error instanceof TypeError) {
      console.error('Type', error);
    } else if (error instanceof Error) {
      console.error('Internal', error);
    } else {
      console.error('Unknown', error);
    }
  }
}
