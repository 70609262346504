<div id="manage-user-cheat-sheet-container">
  <mat-toolbar id="header" class="header">
    <span id="title" class="info-header-icon">
      <img src="assets/images/info-purple.svg" class="icon">
    </span>
    <span id="title">
        Permissions Cheat Sheet
    </span>
  </mat-toolbar>
  <div class="cheat-table-container">
    <mat-table #table [dataSource]="userDataSource">

        <!-- Labels -->
        <ng-container matColumnDef="labels">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell">
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" class="mat-standard-cell label-cell"> {{cell.label}}
            </mat-cell>
        </ng-container>

        <!-- Administrator -->
        <ng-container matColumnDef="administrator">
          <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Administrator
          </mat-header-cell>
          <mat-cell *matCellDef="let cell" class="mat-standard-cell">
            <img *ngIf="cell.administrator" src="assets/images/check-purple.svg" class="icon check-icon">
            <img *ngIf="!cell.administrator" src="assets/images/x-red.svg" class="icon x-icon">
          </mat-cell>
      </ng-container>

        <!-- Approver -->
        <ng-container matColumnDef="approver">
          <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Approver
          </mat-header-cell>
          <mat-cell *matCellDef="let cell" class="mat-standard-cell">
            <img *ngIf="cell.approver" src="assets/images/check-purple.svg" class="icon check-icon">
            <img *ngIf="!cell.approver" src="assets/images/x-red.svg" class="icon x-icon">
          </mat-cell>
      </ng-container>

        <!-- Viewer -->
        <ng-container matColumnDef="viewer">
          <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Viewer
          </mat-header-cell>
          <mat-cell *matCellDef="let cell" class="mat-standard-cell">
            <img *ngIf="cell.viewer" src="assets/images/check-purple.svg" class="icon check-icon">
            <img *ngIf="!cell.viewer" src="assets/images/x-red.svg" class="icon x-icon">
          </mat-cell>
      </ng-container>

        <!-- Developer -->
        <ng-container matColumnDef="developer">
          <mat-header-cell *matHeaderCellDef class="mat-header-cell"> Developer
          </mat-header-cell>
          <mat-cell *matCellDef="let cell" class="mat-standard-cell">
            <img *ngIf="cell.developer" src="assets/images/check-purple.svg" class="icon check-icon">
            <img *ngIf="!cell.developer" src="assets/images/x-red.svg" class="icon x-icon">
          </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
          class="table-cursor">
      </mat-row>
    </mat-table>
  <button mat-stroked-button id="close-btn" (click)="cancel()">
    <span>Close</span>
  </button>
</div>
