import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public getFormattedTimeZone(): string {
    return new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)![1].split(' (')[0].replace(/[A-Z]+/,'');
  }

  public convertUTC(date: any): Date {
    if (date) {
      let d: Date = new Date(date);
      return new Date(d.getTime() + this.minutesToMiliseconds(d.getTimezoneOffset()));
    } else {
      return undefined!;
    }
  }

  private minutesToMiliseconds(minutes: number): number {
    return minutes * 60000;
  } 
}
