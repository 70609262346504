import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  unauthorized: boolean = false;

  constructor(private route: ActivatedRoute, public authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['unauthorized'] === 'true') {
        this.unauthorized = true;
      }
    });
  }

  public login(): void {
    this.authService.login();
  }

}
