import { trigger, sequence, state, animate, transition, style, query, stagger } from '@angular/animations';

export const listAnimation =
    trigger('listAnimation', [
        transition('* <=> *', [
            query(
                ':enter',
                [
                    style({ opacity: 0, transform: 'translateX(-30px)' }),
                    stagger(
                        '30ms',
                        animate(
                            '550ms ease-out',
                            style({ opacity: 1, transform: 'translateY(0px)' })
                        )
                    )
                ],
                { optional: true }
            )
        ])
    ]);

export const rowsAnimation =
    trigger('rowsAnimation', [
        transition('void => *', [
            style({ background: '#D8CDFF', height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
            sequence([
                animate('.35s ease', style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
                animate('.15s ease', style({ height: '*', opacity: '.8', transform: 'translateX(0)', 'box-shadow': 'none' })),
                animate('.35s ease', style({ background: 'white', height: '*', opacity: 1, transform: 'translateX(0)' }))
            ])
        ])
    ]);
