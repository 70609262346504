<div class="container" min-width=600px min-height=1000px id="edit-measure-container">
    <h1 class="header">
        Edit Measure Package Details
    </h1>
    <mat-tab-group dynamicHeight mat-stretch-tabs animationDuration="0ms">
        <mat-tab label="Basic">
            <section class="sectionBy3">
                <div class="column">
                    <section class="section" id="title-section">
                        <span class="label">
                            Measure Title
                        </span>
                        <span class="uneditable-text">
                            {{measure.title}}
                        </span>
                    </section>
                </div>
                <div class="column">
                    <section class="section" id="version-section">
                        <span class="label">Version</span>
                        <span class="uneditable-text">{{measure.version}}</span>
                    </section>
                </div>
                <div class="column">
                    <section class="section" id="upload-section">
                        <span class="label">Upload Date</span>
                        <span class="uneditable-text">{{measure.uploadDate | date: 'MM/dd/yyyy'}}</span>
                    </section>
                </div>
            </section>


            <form [formGroup]="form" novalidate>
                <section>
                    <section class="sectionBy3">
                        <div class="column">
                            <mat-form-field class="mat-form-field">
                                <mat-label>NCQA Measure Abbreviation</mat-label>
                                <input matInput [disabled]="loading" #measureAbbrInput maxlength="128" required (focus)="onFocus($event)" (blur)="onFocus(null)"
                                    formControlName="measureAbbr" [(ngModel)]="measure.measureAbbr" id="measure-abbr">
                                <mat-hint [hidden]="isHidden('measure-abbr')" align="end">{{measureAbbrInput.value.length}} / 128</mat-hint>
                                <mat-error id='mat-error-1'
                                    *ngIf="form.controls['measureAbbr'].errors?.required || form.controls['measureAbbr'].errors?.pattern">
                                    NCQA Measure Abbreviation is required.
                                </mat-error>

                            </mat-form-field>
                        </div>
                        <div class="column">
                            <section>
                                <mat-form-field>
                                    <mat-label>NCQA Certification Date</mat-label>
                                    <input id="certification-date" matInput autocomplete="off" 
                                        [matDatepicker]="certificationDate" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="measure.certificationDate" [disabled]="loading">
                                    <mat-datepicker-toggle matSuffix [for]="certificationDate"
                                        [disabled]="loading"></mat-datepicker-toggle>
                                    <mat-datepicker #certificationDate></mat-datepicker>
                                </mat-form-field>
                            </section>
                        </div>
                        <div class="column">
                            <mat-form-field class="mat-form-field">
                                <mat-label>NCQA Certification ID</mat-label>
                                <input matInput [disabled]="loading" #certificationIdInput maxlength="128" (focus)="onFocus($event)" (blur)="onFocus(null)"
                                    formControlName="certification" [(ngModel)]="measure.certification"
                                    id="measure-certification">
                                <mat-hint [hidden]="isHidden('measure-certification')" align="end">{{certificationIdInput.value.length}} / 128</mat-hint>
                            </mat-form-field>
                        </div>
                    </section>

                    <mat-form-field class="mat-form-field">
                        <mat-label>Display Name</mat-label>
                        <input matInput [disabled]="loading" #displayNameInput maxlength="128" required (focus)="onFocus($event)" (blur)="onFocus(null)"
                            formControlName="displayName" [(ngModel)]="measure.displayName" id="display-name">
                        <mat-hint align="start"><strong>Appears as Display Name in Run Config & Run Results</strong>
                        </mat-hint>
                        <mat-hint [hidden]="isHidden('display-name')" align="end">{{displayNameInput.value.length}} / 128</mat-hint>
                        <mat-error id='mat-error-1'
                            *ngIf="form.controls['displayName'].errors?.required || form.controls['displayName'].errors?.pattern">
                            Measure Display Name is required.
                        </mat-error>
                        <mat-error id='mat-error-2' *ngIf="form.controls['displayName'].errors?.forbiddenValues">
                            Measure Display Name and version combination already in use. Please choose another.
                        </mat-error>
                    </mat-form-field>

                </section>
                <section>
                    <mat-form-field class="mat-form-field">
                        <mat-label>Measurement Year</mat-label>
                        <input matInput [disabled]="loading" #message2 maxlength="16" required (focus)="onFocus($event)" (blur)="onFocus(null)"
                            formControlName="measurementYear" [(ngModel)]="measure.measurementYear" 
                            id="measurement-year">
                        <mat-hint [hidden]="isHidden('measurement-year')" align="end">{{message2.value.length}} / 16</mat-hint>
                        <mat-error id='mat-error-3'
                            *ngIf="form.controls['measurementYear'].errors?.required || form.controls['measurementYear'].errors?.pattern">
                            Measurment Year is required.
                        </mat-error>
                    </mat-form-field>
                </section>

                <section>
                    <mat-form-field>
                        <mat-label>Reportable Product Lines</mat-label>
                        <mat-select required multiple [compareWith]="areRecordsEqual"
                            [(ngModel)]="measure.selectedProductLines" formControlName="defaultProductLines"
                            [disabled]="loading" id="default-product-lines">
                            <mat-option *ngFor="let productLine of productLines"
                                [value]="productLine">{{productLine.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error id='mat-error-4' *ngIf="form.controls['defaultProductLines'].errors?.required">
                            Please select at least one product line.
                        </mat-error>
                    </mat-form-field>
                </section>
            </form>
            <section class="footer-section">
                <div class="visibility">
                    <img id="visible-icon" [hidden]="measure.deleted" class="visible-icon"
                        src="assets/images/visibility_on.svg">
                    <img id="not-visible-icon" [hidden]="!measure.deleted" class="visible-icon"
                        src="assets/images/visibility_off.svg">
                    Visibility
                </div>
                <div class="deactivate-text">
                    You can deactivate a measure package version. Inactive versions won’t be available for new Run
                    Configurations, but they’ll be visible in the table, and you can reactivate them later.
                    For inactive measure packages, results from previous runs will still be visible in the system.
                </div>
                <div>
                    <mat-checkbox id="deactivate-checkbox" [(ngModel)]="measure.deleted" [disabled]="loading">
                        <span class="checkbox-text">
                            Deactivate this version
                        </span>
                    </mat-checkbox>
                </div>
            </section>

        </mat-tab>
        <mat-tab id="advanced-tab" label="Advanced">
            <div>
                <br />
                <mat-checkbox id="event-based-measure-checkbox" [(ngModel)]="measure.hasOutputEvent"
                    [disabled]="loading">
                    <span class="checkbox-text">
                        Event-based Measure
                    </span>
                </mat-checkbox>
            </div>
            <form [formGroup]="formAdvanced" id="form-advanced" novalidate>
                <section>
                    <mat-form-field class="mat-form-field">
                        <mat-label>Output Age</mat-label>
                        <textarea matInput [disabled]="loading" formControlName="outputAge"
                            [(ngModel)]="measure.outputAge" id="output-age">
                        </textarea>
                    </mat-form-field>
                </section>
                <section>
                    <mat-form-field class="mat-form-field">
                        <mat-label>Filter Age</mat-label>
                        <textarea matInput [disabled]="loading" formControlName="filterAge"
                            [(ngModel)]="measure.filterAge" id="filter-age">
                        </textarea>
                    </mat-form-field>
                </section>
                <section>
                    <mat-form-field>
                        <mat-label>Gender</mat-label>
                        <mat-select [(ngModel)]="measure.outputGender" formControlName="gender" [disabled]="loading"
                            id="gender">
                            <mat-option *ngFor="let gender of genders" [value]="gender.value">{{gender.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>
            </form>
            <table mat-table [dataSource]="measure.measureGroupMappings" id="table-group-lables">
                <!-- Add/Remove Button Column -->
                <ng-container matColumnDef="control">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell">
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i=index" class="mat-standard-cell">
                        <button mat-icon-button [disabled]="loading" class="button-add-row" [hidden]="i==0"
                            (click)="removeGroupLabel(element)"><img id="zoom_out"
                                src="assets/images/zoom_out.svg"></button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef> <button mat-icon-button id="AddGroupLabelBtn"
                            class="button-add-row" (click)="addGroupLabel()"><img id="zoom_in"
                                src="assets/images/zoom_in.svg"></button>
                    </mat-footer-cell>
                </ng-container>
                <!-- group Label Column -->
                <ng-container matColumnDef="groupLabel">
                    <mat-header-cell *matHeaderCellDef>Group Label</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <input [disabled]="loading" style="border: none" matInput maxlength="256"
                            [(ngModel)]="element.groupLabel">
                    </mat-cell>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>
                <!-- allow Gap Column -->
                <ng-container matColumnDef="allowGap">
                    <mat-header-cell *matHeaderCellDef>Allow Gap at end of measurement period</mat-header-cell>
                    <mat-cell style="text-align: center; vertical-align: middle;" mat-cell *matCellDef="let element">
                        <mat-checkbox [(ngModel)]="element.allowGapAtEndOfPeriod" [disabled]="loading">
                        </mat-checkbox>
                    </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayMeasureGroupColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayMeasureGroupColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="['control']"></mat-footer-row>
            </table>
        </mat-tab>
    </mat-tab-group>
    <section class="footer-section">
        <div class="button-container">
            <button mat-stroked-button id="cancelBtn" (click)="cancel()" [disabled]="loading">
                Cancel
            </button>
            <button mat-stroked-button id="updateBtn" (click)="update()" [disabled]="loading">
                <span>
                    Update
                    <span class="spinner" *ngIf="loading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                </span>
            </button>
        </div>
    </section>
</div>