import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { SnackbarService } from './snackbar.service';
import { WindowRefService } from './window-ref.service';
declare var require: any

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private csvHeaders: string[] = [];
  private csvSeparator = ','; // This could be configured to a pipe or something else via an environment variable if needed

  constructor(
    public dataService: DataService,
    public windowRefService: WindowRefService, public snackBarService: SnackbarService, public fileService: FileService) { }

  public validateFile(file: File, fileType: string): Observable<any> {
    return new Observable(sub => {
      if (!file) {
        sub.error('Invalid File, Expected - Upload file delimited with , (comma)');
      }
      this.validateCsvFile(file, fileType).subscribe(_ => {
        sub.next();
      }, err => {
        sub.error(err);
      });
    });
  }

  validateCsvFile(file: any, fileType: string): Observable<void> {
    return new Observable(o => {

      let httpRequests: Observable<any>[] = []

      this.csvHeaders = [];
      let chunkSize = this.getChunkSizeByFileType(fileType)
      let maxParallelHttpRequests = environment.uploadThreads;

      // What to do with a single chunk of file that has been read
      let onChunkHandler: Function = (res: any, progress: number) => {
        return new Observable(readCallback => {
          this.snackBarService.showInfoBar('Uploading file: ' + progress + '%');
          if (httpRequests.length <= maxParallelHttpRequests) {
            try {
              let mappedObjects: any[] = this.mapChunkToJsonObjects(res, fileType, this.csvSeparator);
              if (mappedObjects.length > 0) {
                httpRequests.push(this.getHttpRequestByFileType(fileType, mappedObjects));
              }
            } catch (err: any) {
              o.error(err.message);
            }
          }

          this.makeHttpRequests(maxParallelHttpRequests, httpRequests).subscribe(madeRequests => {
            httpRequests = madeRequests ? [] : httpRequests;
            readCallback.next();
          }, () => {
            readCallback.error();
            o.error('There was a problem uploading ' + fileType + ' file. Please try again later.');
          });
        });
      }

      // What to do when the entire file has been read
      let onFinishedReadHandler: Function = () => {
        this.makeHttpRequests(httpRequests.length, httpRequests).subscribe(_ => {
          o.next();
          o.complete();
        }, () => {
          o.error('There was a problem uploading ' + fileType + ' file. Please try again later.');
        });
      }

      // Start reading file
      this.fileService.readFileInChunks(file, chunkSize, onChunkHandler, onFinishedReadHandler);

    });
  }

  getHttpRequestByFileType(fileType: string, json: any[]): Observable<any> {
    switch (fileType) {
      default:
        return this.dataService.uploadFile(JSON.parse(JSON.stringify(json)), fileType);
    }
  }

  getChunkSizeByFileType(fileType: string): number {
    switch (fileType) {
      default:
        return environment.uploadChunkSize;
    }
  }

  mapChunkToJsonObjects(lines: string[], fileType: string, csvSeparator: string): any[] {

    let mappedJsonObjects: any[] = [];
    lines.forEach((line: string) => {
      if (!line.includes(csvSeparator)) {
        throw new Error('Invalid File, Expected - Upload file delimited with , (comma)');
      }

      let delimitedLine = line.split(csvSeparator);

      if (this.csvHeaders.length === 0) {
        this.csvHeaders = delimitedLine;
      } else {
        let jsonObject: any = {};

        for (let index = 0; index < this.csvHeaders.length; index++) {
          jsonObject[this.csvHeaders[index].toLowerCase()] = delimitedLine[index];
        }
        mappedJsonObjects.push(jsonObject);
      }
    });

    return mappedJsonObjects;
  }

  makeHttpRequests(maxParallelHttpRequests: number, httpRequests: any[]): Observable<boolean> {
    return new Observable(o => {
      if (httpRequests.length === maxParallelHttpRequests) {
        // Restart timeout so user does not get kicked off during a large file upload
        this.windowRefService.nativeWindow.agTimeoutCount = environment.timeout.timeoutStart;
        forkJoin(httpRequests)
          .subscribe(_ => {
            o.next(true);
          },
            (err) => {
              o.error(err);
            });
      } else {
        o.next(false);
      }
    });
  }
}



// validateLines(file: any, filetype: string): Observable<any> {
//   return new Observable((sub) => {
//     if (!file) {
//       sub.error(true);
//       return;
//     }
//     let LineNavigator = require('line-navigator');
//     let chunkSize = environment.uploadChunkSize;
//     let options = { 'chunkSize': chunkSize, 'throwOnLongLines':  true};
//     let navigator = new LineNavigator(file, options);
//     let dataService = this.dataService;
//     let snackbarService = this.snackBarService;
//     let windowRefService = this.windowRefService;
//     let pageRequests: any[] = [];
//     let headers: string | any[] = [];
//     let threads = environment.uploadThreads;
//     let iterator = 1;
//     // note: Alternate to readSomeLines is readLines where we can specify exact number of lines to be read.
//     // if pdf files are larger than chunk size (set to 6MB now), it might be better to switch to readLines (to read one line at a time)
//     // to keep request body within allowed limits
//     // https://www.npmjs.com/package/line-navigator
//     navigator.readSomeLines(0, function linesReadHandler(err: any, index: number, lines: string | any[], eof: any, progress: any) {
//       // snackbarService.showInfoBar('Uploading file: ' + progress + '%');
//       if (err) {
//         sub.error(err);
//         return;
//       }
//       let json = [];
//       if (lines) {
//         for (let i = 0; i < lines.length; i++) {
//           if (lines[i]) {
//             if (index === 0 && i === 0) {
//               headers = lines[0].split(',');
//               if (headers.length === 1) {
//                 sub.error(true);
//               }
//             } else {
//               let currentline = lines[i].split(',');
//               if (currentline.length === 1) {
//                 sub.error(true);
//               }
//               let obj: any = {};
//               for (let j = 0; j < headers.length; j++) {
//                 obj[headers[j].toLowerCase()] = currentline[j];
//               }
//               json.push(obj);
//             }
//           }
//         }
//       }
//       if (iterator <= threads) {
//         pageRequests.push(dataService.uploadFile(JSON.parse(JSON.stringify(json)), filetype));
//       }
//       if ((iterator === threads) || eof) {
//       windowRefService.nativeWindow.agTimeoutCount = environment.timeout.timeoutStart;
//         forkJoin(pageRequests).subscribe((result: any) => {
//             if (eof) {
//               sub.next();
//               return;
//             }
//             iterator = 1;
//             pageRequests = [];
//             navigator.readSomeLines(index + lines.length, linesReadHandler);
//           },
//             error => {
//               sub.error(error);
//               return;
//             });
//       } else {
//         iterator++;
//         navigator.readSomeLines(index + lines.length, linesReadHandler);
//       }
//       if (eof && pageRequests.length === 0) {
//         sub.next();
//         return;
//       }
//     });
//   });
// }