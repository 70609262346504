import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class LoggerService {
  baseUrl = `${environment.api.base}${environment.api.endpoints.logging}/`;

  constructor(private http: HttpClient) {
  }

  log(message: string, sendToLoggingService?: boolean) {
    console.log(message);
    if (sendToLoggingService) {
      this.http.put(`${this.baseUrl}debug`, message).subscribe();
    }
  }

  info(message: string, sendToLoggingService?: boolean) {
    console.info(message);
    if (sendToLoggingService) {
      this.http.put(`${this.baseUrl}info`, message).subscribe();
    }
  }

  warn(message: string, sendToLoggingService?: boolean) {
    console.warn(message);
    if (sendToLoggingService) {
      this.http.put(`${this.baseUrl}warn`, message).subscribe();
    }
  }

  error(message: string, sendToLoggingService?: boolean) {
    console.error(message);
    if (sendToLoggingService) {
      this.http.put(`${this.baseUrl}error`, message).subscribe();
    }
  }

}
