import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const COUNT_START = environment.timeout.countDownStart;

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss']
})
export class TimeoutDialogComponent implements OnInit {

  public countDown: Observable<number> | undefined;
  public count = COUNT_START;

  constructor(public dialogRef: MatDialogRef<TimeoutDialogComponent>) {
    this.startCountdown();
  }

  ngOnInit() {
  }

  startCountdown() {
    this.countDown = timer(0, 1000)
      .pipe(
        take(this.count),
        map(() => --this.count),
        tap(() => {
          if(localStorage.getItem('stay') === 'true') {
            this.dialogRef.close(true);
          }
          if (this.count < 1) {
            this.logout();
          }
        }));
  }

  logout() {
    this.dialogRef.close(false);
  }
}
