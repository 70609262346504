import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { ContentValidationService } from 'src/app/core/content-validation.service';
import { DataService } from 'src/app/core/data.service';
import { DateService } from 'src/app/core/date.service';
import { FileService } from 'src/app/core/file.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { RefreshService } from 'src/app/core/refresh.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { ApplicationStateEnum } from 'src/app/models/enums/application-state-enum';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';
import { FileValidation } from 'src/app/models/file-validation';
import { listAnimation } from 'src/app/shared/animations/table-row-animation';

@Component({
  selector: 'app-content-validation',
  templateUrl: './content-validation.component.html',
  styleUrls: ['./content-validation.component.scss'],
  animations: [listAnimation]
})
export class ContentValidationComponent implements OnInit {

  private fileValidationsSubscription: Subscription;

  @Input() stopRefreshInterval!: Subject<void>;
  @Input() public refreshAnnounced!: Observable<void>;
  public refreshSubscription!: Subscription;
  public displayedColumns: string[] =
    ['file_name', 'last_modified', 'total_rows', 'missing_columns', 'missing_required_fields'];
  public fileValidations: FileValidation[] = [];
  public dataSource: MatTableDataSource<FileValidation> = new MatTableDataSource();


  constructor(public loadingService: LoadingService,
    public fileService: FileService,
    public contentValidationService: ContentValidationService,
    public wayfindingService: WayfindingService,
    public dateService: DateService,
    public configurationService: ConfigurationService,
    public userService: UserService,
    public dataService: DataService,
    public refreshService: RefreshService,
    public jobLogService: JobLogService) {
    this.fileValidationsSubscription = this.fileService.fileValidationsAnnounced.subscribe(() => {
      this.initializeDataSource();
    });
  }


  async initializeDataSource(): Promise<void> {
    this.loadingService.initialLoad = true;
    if (this.fileService.fileValidations.filter(f => f.criticalError).length > 0) {
      this.contentValidationService.criticalError = true;
      this.loadingService.validating = false;
    } else if (this.configurationService.applicationState === ApplicationStateEnum.VALIDATE) {
      this.loadingService.validating = true;
    }
    this.dataSource = new MatTableDataSource(this.fileService.fileValidations);
    this.contentValidationService.contentValidationError = this.fileService.isContentValidationNonCriticalError();
    if (this.configurationService.currentJobLogId) {
      await this.jobLogService.getCurrentLoadDataJobLogEntry(this.configurationService.currentJobLogId);
      if (this.jobLogService.isContentValidationDone()) {
        this.loadingService.validating = false;
      }
    }

    this.wayfindingService.refreshMessaging();
  }

  ngOnDestroy(): void {
    this.fileValidationsSubscription.unsubscribe();
    this.refreshSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.loadingService.validating = true;
    if (this.configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_CONTENT) {
      this.subscribeToRefresh();
    }
  }

  subscribeToRefresh() {
    this.refreshSubscription = this.refreshAnnounced.subscribe(() => {
      
      if (this.fileService.refreshCalls() && !this.shouldDisableValidateFhirBundles()) {
        this.refreshSubscription.unsubscribe();
      }
      this.wayfindingService.refreshMessaging();
    });
    this.stopRefreshInterval.subscribe(() => this.refreshSubscription.unsubscribe());

  }
  validateFhirBundles(): void {
    this.configurationService.currentWayfindingStage = WayfindingStageEnum.VALIDATE_FHIR_BUNDLES;
    this.loadingService.validating = true;
    this.dataService.loadTriggerResource(this.configurationService.currentJobLogId).subscribe(() => {
      this.configurationService.getConfiguration().subscribe();
    });
  }

  shouldShowAlert(fileName: String): boolean {
    let validation = this.fileService.fileValidations.filter(f => f.fileName.toLocaleLowerCase() === fileName.toLocaleLowerCase())[0];
    if (validation) {
      if (validation.headerValidationResult) {
        return validation.criticalError;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  shouldShowNonCritical(fileName: String): boolean {
    let validation = this.fileService.fileValidations.filter(f => f.fileName.toLocaleLowerCase() === fileName.toLocaleLowerCase())[0];
    if (validation && validation.missingRequiredFieldsCount) {
      return +validation.missingRequiredFieldsCount > 0;
    } else {
      return false;
    }
  }

  shouldHideValidatingMessage() {
    return this.contentValidationService.criticalError || (this.contentValidationService.contentValidationError && this.fileService.allContentValidated())
      || this.fileService.allContentValidated() && !this.contentValidationService.contentValidationError && !this.contentValidationService.criticalError;
  }

  shouldShowValidatedMessage() {
    return this.fileService.allContentValidated() && this.fileService.hasContentToValidate() && !this.contentValidationService.contentValidationError && !this.contentValidationService.criticalError;
  }

  shouldDisableValidateFhirBundles() {
    return (this.loadingService.validating || this.loadingService.loading) || !this.fileService.allContentValidated() ||
      this.contentValidationService.criticalError || this.configurationService.applicationState === 'failed' ||
      !(this.userService.isAdministrator() || this.userService.isDeveloper()) || this.configurationService.applicationState.toLowerCase() === 'running';
  }

}
