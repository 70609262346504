import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationStateEnum } from '../models/enums/application-state-enum';
import { WayfindingStageEnum } from '../models/enums/wayfinding-stage-enum';
import { ConfigurationService } from './configuration.service';
import { ContentValidationService } from './content-validation.service';
import { FileService } from './file.service';
import { LoadingService } from './loading.service';
import {formatNumber} from '@angular/common';


const DEFAULT_HEIGHT: number = 136;

@Injectable({
  providedIn: 'root'
})
export class WayfindingService {

  private refreshedFilesSubscripiton: Subscription;

  private _message: string = '';
  private _icon: string = 'alert-icon';
  public height: number = DEFAULT_HEIGHT;

  constructor(public fileService: FileService, public configurationService: ConfigurationService, public contentValidationService: ContentValidationService,
    public loadingService: LoadingService) {
    this.refreshedFilesSubscripiton = this.fileService.refreshedFilesAnnounced.subscribe(() => {
      this.refreshMessaging();
    });
  }

  ngOnDestroy(): void {
    this.refreshedFilesSubscripiton.unsubscribe();
  }
  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  public cancel() {
    this.height = DEFAULT_HEIGHT;
    this.icon = 'alert-icon';
    this.message = '';
    this.loadingService.loading = false;
    this.loadingService.validating = false;
  }

  public refreshMessaging() {
    this.height = DEFAULT_HEIGHT;
    if (this.fileService.allFilesValid(this.configurationService.ncqaMode) || this.configurationService.currentWayfindingStage !== WayfindingStageEnum.LOAD_DATA) {
      switch (this.configurationService.currentWayfindingStage) {
        case WayfindingStageEnum.LOAD_DATA:
          this.icon = 'message';
          if (this.fileService.invalidFilesPresent()) {
            this.message = `All required files loaded without error. One or more incorrectly named files will be ignored. Click <b>Start Content Validation</b> to proceed to the next step.`
          } else {
            this.message = `All required files loaded without error. Click <b>Start Content Validation</b> to proceed to the next step.`
          }
          break;
        case WayfindingStageEnum.VALIDATE_CONTENT:
          if (this.configurationService.applicationState === ApplicationStateEnum.FAILED) {
            this.message = this.configurationService.errorMessage;
            this.icon = 'alert-icon';
          } else {
            if (this.contentValidationService.criticalError) {
              this.message = '<b>Error:</b> Content validation cannot proceed. Please cancel and view logs to troubleshoot missing columns, fix all errors, then reload your data.'
              this.icon = 'alert-icon';
            } else if (this.contentValidationService.contentValidationError) {
              this.message = 'We found one or more non-critical errors. You can view logs for troubleshooting information while content validation proceeds, or cancel and fix errors, then reload your data.'
              this.icon = 'non-critical-white';
            } else {
              this.icon = 'checkmark-white'
              this.message = '';
            }
          }
          break;
        case WayfindingStageEnum.VALIDATE_FHIR_BUNDLES:
          if (this.configurationService.applicationState === ApplicationStateEnum.FAILED) {
            this.message = this.configurationService.errorMessage;
            this.icon = 'alert-icon';
          } else if (this.loadingService.validating === false) {
            this.icon = 'message';
            this.message = 'FHIR bundle validation complete with no critical errors. Click <b>Load Bundles</b> to proceed to the next step. This will replace your existing data.'
          } else {
            this.message = '';
          }
          break;
        case WayfindingStageEnum.LOAD_BUNDLES:
          if (this.configurationService.applicationState === ApplicationStateEnum.FAILED) {
            this.message = 'Your data load failed due to unforseeable errors. Consult the logs for more information and contact your Customer Success representative for assistance.'
            this.icon = 'alert-icon';
          } else if (this.loadingService.loading) {
            this.message = 'Depending on how large your files are, this process could take a day or more. You can leave this screen and return any time.';
          } else {
            if (this.configurationService.failedBundles > 0) {
              this.icon = 'non-critical-white'
              this.message = 'Some of your data has been successfully uploaded to the FHIR server. We could not produce ' 
              + this.configurationService.failedBundles + 
              ' expected FHIR bundles out of ' + formatNumber(Number((this.configurationService.failedBundles + this.configurationService.loadedBundles)), 'en-US', '1.0-0') + '; some members\' data may not appear.';
            } else {
              this.icon = 'checkmark-white'
              this.message = 'Your data has been successfully uploaded to the FHIR server. ' + formatNumber(Number(this.configurationService.loadedBundles), 'en-US', '1.0-0') + ' bundles were loaded.';
            }
          }
          break;
        default:
          break;
      }
    } else if (this.configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA && this.configurationService.showWayfinding && !this.configurationService.ncqaMode) {
      this.icon = 'alert-icon';
      this.message = `One or more expected files is missing. Add files from the <b>Expected Files</b> list to the <b>Data Staging Area</b>, then click <b>Refresh</b>.`
      this.height = this.height + 20;
      if (this.fileService.invalidFilesPresent()) {
        this.message = this.message + '&#10; &#10;' + `One or more incorrectly named files are in the staging area. Remove or replace them and click <b>Refresh</b> to proceed.`
        this.height = this.height + 20;
      }
    } else {
      this.message = '';
      this.height = DEFAULT_HEIGHT;
      this.icon = 'checkmark-white';
    }
  }
}
