import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToBitPipe'
})
export class BoolToBitPipe implements PipeTransform {

  transform(value: string): any {
    if(value && value.length !== 0){
      if (value==='true') {return '1' } else return '0';
    }
    return '0';
  }

}
