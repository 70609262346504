<div class="container" id="edit-product-container">
    <div [hidden]="!product.id">
        <h1 class="header" id="edit-product-header">
            Edit Product
        </h1>
    </div>
    <div [hidden]="product.id">
        <h1 class="header" id="add-product-header">
            Add Product
        </h1>
    </div>
    <form [formGroup]="form" novalidate>
        <section>
            <mat-form-field class="mat-form-field">
                <mat-label>Product Name</mat-label>
                <input matInput #message1 maxlength="64" required formControlName="productName"
                    [(ngModel)]="product.displayName" id="product-name" placeholder="Ex. CHIP">
                <mat-hint align="end">{{message1.value.length}} / 64</mat-hint>
                <mat-error id='mat-error-1'
                    *ngIf="form.controls['productName'].errors?.required || form.controls['productName'].errors?.pattern">
                    Product name is required.
                </mat-error>
                <mat-error id='mat-error-2' *ngIf="form.controls['productName'].errors?.forbiddenValues">
                    Name/Product Line combination already in use. Please choose another.
                </mat-error>
            </mat-form-field>
        </section>

        <section>
            <mat-form-field>
                <mat-label>Product Line</mat-label>
                <mat-select required single [(ngModel)]="product.productLineId"
                    formControlName="productLine" id="product-line" (selectionChange)="onProductLineChange($event)">
                    <mat-option *ngFor="let productLine of productLines" [value]="productLine.id">{{productLine.name}}
                    </mat-option>
                </mat-select>
                <mat-error id='mat-error-3' *ngIf="form.controls['productLine'].errors?.required">
                    Product line is required.
                </mat-error>
            </mat-form-field>
        </section>
    </form>

    <section class="footer-section">
        <div>
            <mat-checkbox id="checkbox1" [checked]="product.auditable" (change)="auditableChange($event)">
                <span class="checkbox-text">
                    Auditable
                </span>
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox id="checkbox2" [checked]="!product.deleted" (change)="deletedChange($event)">
                <span class="checkbox-text">
                    Active
                </span>
            </mat-checkbox>
        </div>

        <div class="button-container">
            <button mat-stroked-button id="cancelBtn" (click)="cancel()">
                Cancel
            </button>
            <div [hidden]="!product.id">
                <button mat-stroked-button id="okay-btn" (click)="update(false)">
                    <span>
                        Okay
                    </span>
                </button>
            </div>
            <div [hidden]="product.id">
                <button mat-stroked-button id="add-another-btn" (click)="update(true)">
                    <span>
                        Save & Add Another
                    </span>
                </button>
                <button mat-stroked-button id="okay-btn" (click)="update(false)">
                    <span>
                        Okay
                    </span>
                </button>
            </div>
        </div>
    </section>
</div>