import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalType } from '../models/enums/modal-type-enum';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  openModal(modalType: ModalType, title: string, message: string, primaryBtnText: string,
    secondaryBtnText: string | null = null, primaryBtn: Function | null = null, secondaryBtn: Function | null = null,
    thirdBtnText: string | null = null, thirdBtn: Function | null = null) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      modalType: modalType,
      title: title,
      message: message,
      primaryBtnText: primaryBtnText,
      secondaryBtnText: secondaryBtnText,
      thirdBtnText: thirdBtnText
    };
    dialogConfig.minWidth = 440;

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (+result === 1) {
        if (primaryBtn) {
          primaryBtn();
        }
      }
      if (+result === 2) {
        if (secondaryBtn) {
          secondaryBtn();
        }
      }
      if (+result === 3) {
        if (thirdBtn) {
          thirdBtn();
        }
      }
    });
  }

}
