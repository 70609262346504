import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TimeoutDialogComponent } from '../timeout-dialog/timeout-dialog.component';
import { TimeoutService } from 'src/app/core/timeout.service';
import { environment } from 'src/environments/environment';

const TIMEOUT_START = environment.timeout.timeoutStart;

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html'
})
export class TimeoutComponent implements OnInit, OnDestroy {
  public mouseMoveSubscription: Subscription = new Subscription;
  public timeout = TIMEOUT_START;

  constructor(public dialog: MatDialog,
    private authService: AuthService,
     public timeoutService: TimeoutService) {
    }

  ngOnInit(): void {
    let date = new Date();
    localStorage.setItem('expirationDate', (date.getTime() + TIMEOUT_START*1000).toString());
    this.timeoutService.startTimer();
    this.startMouseMoveListener();
    this.sessionTimeout();
  }

  ngOnDestroy(): void {
    if (this.mouseMoveSubscription) {
      this.mouseMoveSubscription.unsubscribe();
    }
  }

  openDialog(): void {
    localStorage.removeItem('stay');
    let dialogRef = this.dialog.open(TimeoutDialogComponent, {
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(stay => {
      if (stay === false) {
        this.timeoutService.logout();
      }
      else if (stay === true) {
        this.timeoutService.startTimer();
        localStorage.setItem('stay', 'true');
      }
    });
  }

  startMouseMoveListener() {
    this.mouseMoveSubscription = fromEvent(document, 'mousemove')
      .subscribe(e => {
        let date = new Date();
        localStorage.setItem('expirationDate', (date.getTime() + TIMEOUT_START*1000).toString());
        this.timeoutService.startTimer();
      });

  }

  sessionTimeout() {
    this.timeoutService.timeoutExpired.subscribe(n => {
      if(this.authService.isUserLoggedIn()) {
        this.openDialog();
      }
    });
  }

}
