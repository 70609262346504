import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuditService } from '../audit.service';
import { UserService } from '../user.service';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { MsalGuard } from '@azure/msal-angular';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserRole } from '../../models/enums/user-role-enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  private static restrictedUrls: Map<string, Array<string>> = new Map<string, Array<string>>([
    ['/users', [UserRole.ADMINISTRATOR]]
  ]);

  constructor(private router: Router,
    public authService: AuthService,
    private auditService: AuditService,
    public userService: UserService,
    public msalGuard: MsalGuard) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.msalGuard.canActivate(route, state).pipe(switchMap((allowed) => {
      if (!allowed) {
        this.router.navigateByUrl('/login');
        return of(false);
      }
      return this.authService.checkAuthorized()
        .pipe(map((response: any) => {

          if (!response.userRole) {
            return false;
          }

          this.userService.initializeUser(response.userName, response.userRole);

          if (!this.isUserAuthorizedToAccessUrl(state.url)) {
            this.auditService.sendInsufficientPermissionsAuditEvent(state.url);
            this.authService.unauthorizedMessage = 'You do not have permissions to access this module';
            this.router.navigateByUrl('/login?unauthorized=true');
            return false;
          }

          if (!this.authService.loginEventSent || this.authService.loginButtonClicked) {
            this.auditService.sendLoginAuditEntry();
            this.auditService.sendAuthorizedAuditEvent(state.url);
            this.authService.loginEventSent = true;
            this.authService.loginButtonClicked = false;
          }

          return true;

        }), catchError((err) => {
          if (err.status === 403 || err.status === 401) {
            this.authService.handleUnauthorizedAccess()
          }
          return of(false);
        }));
    }));
  }

  isUserAuthorizedToAccessUrl(url: string): boolean {
    if (this.userService.isDeveloper()) {
      // Grant full access to developers
      return true;
    }

    let restrictedUrl = AuthGuard.restrictedUrls.get(url);
    return !restrictedUrl || restrictedUrl.includes(this.userService.userRole);
  }

}
