



    <section id="run-result-summary-table-container">

      <table id="table" mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
        <!-- Product Line -->
        <ng-container matColumnDef="productLine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Product Line</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults">
              {{row.productLine}}
            </div>
          </td>
        </ng-container>

        <!-- Product -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Product</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults">
              {{row.product}}
            </div>
          </td>
        </ng-container>

        <!-- Measure -->
        <ng-container matColumnDef="measure">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell" style="min-width: 150px;">Display Name</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults">
              {{row.measure}}
            </div>
          </td>

        </ng-container>

        <!-- Sub Measure Id -->
        <ng-container matColumnDef="subMeasureId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Submeasure ID</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults">
              {{row.subMeasureId}}
            </div>
          </td>

        </ng-container>

        <!-- Stratification -->
        <ng-container matColumnDef="stratification">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell">Stratification</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults">
              {{row.stratification}}
            </div>
          </td>

        </ng-container>

        <!-- Reported Rate -->
        <ng-container matColumnDef="reportedRate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Reported Rate</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label skeleton-right-align">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults" class="right-align">
              {{row.reportedRate | number:'1.2-2'}}%
            </div>
          </td>

        </ng-container>

        <!-- Initial Population -->
        <ng-container matColumnDef="initialPopulation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Initial Population
          </th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label skeleton-right-align">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults" class="right-align">
              {{row.initialPopulation}}
            </div>
          </td>

        </ng-container>

        <!-- Numerator -->
        <ng-container matColumnDef="numerator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Numerator</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label skeleton-right-align">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults" class="right-align">
              {{row.numerator}}
            </div>
          </td>

        </ng-container>

        <!-- Denominator -->
        <ng-container matColumnDef="denominator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Denominator</th>
          <td mat-cell *matCellDef="let row" class="mat-standard-cell">
            <div *ngIf="loadingRunResults" class="skeleton-label skeleton-right-align">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
            </div>
            <div *ngIf="!loadingRunResults" class="right-align">
              {{row.denominator}}
            </div>
          </td>

          <!-- Denom Excl -->
          <ng-container matColumnDef="denomExcl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-header-cell right-align">Denom Excl</th>
            <td mat-cell *matCellDef="let row" class="mat-standard-cell">
              <div *ngIf="loadingRunResults" class="skeleton-label skeleton-right-align">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </div>
              <div *ngIf="!loadingRunResults" class="right-align">
                {{row.denomExcl}}
              </div>
            </td>

          </ng-container>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true" class="mat-header-cell"></tr>
        <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </section>
    <div class="footer">
      <div *ngIf="loadingRunResults" class="skeleton-footer">
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
      </div>
      <mat-paginator #summaryPaginator *ngIf="!loadingRunResults" [pageSize]="selectedPageSize" [length]="totalRows"
        (page)="onPaginateChange($event)">
      </mat-paginator>
    </div>

 


