<mat-toolbar id="header"><span id="astrata">Astrata&nbsp;</span><span id="emeasure">eMeasure</span>
    <div *ngIf="configurationService.ncqaMode && SHOW_NCQA_MODE_HEADER"><span id="ncqa-mode">NCQA MODE</span></div>
    <button mat-button [matMenuTriggerFor]="menu" class="user-menu-btn">
        <img id="userMenu" src="assets/images/user-menu-icon.svg">
    </button>
    <mat-menu #menu="matMenu" class=user-menu>
        <button mat-menu-item [hidden]="!userService.isDeveloper()" (click)="manageMeasurePackages()">Manage Measure
            Packages</button>
        <button mat-menu-item (click)="manageOrganizationInformation()">Manage Organization Details</button>
        <button mat-menu-item [hidden]="!userService.isDeveloper() && !userService.isAdministrator()" (click)="manageUsers()">Manage Users</button>
        <button mat-menu-item (click)="logout()">Logout</button>
        <button mat-menu-item class="button" [hidden]="!userService.isDeveloper()" type="submit" id="upload-mmdf-btn"
            (click)="onUploadClick(mmdFileInput);">
            Upload mmdf file</button>
        <input hidden id="mmdf-file-input" (change)="uploadMmdfFile($event)" #mmdFileInput type="file" multiple>
        <button mat-menu-item class="button" [hidden]="!userService.isDeveloper()" type="submit" id="upload-lishist-btn"
            (click)="onUploadClick(lishistFileInput);">
            Upload lishist file</button>
        <input hidden id="lishist-file-input" (change)="uploadLishistFile($event)" #lishistFileInput type="file" multiple>
    </mat-menu>
</mat-toolbar>
