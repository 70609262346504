import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { ContentValidationService } from 'src/app/core/content-validation.service';
import { FileService } from 'src/app/core/file.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { RefreshService } from 'src/app/core/refresh.service';
import { UserService } from 'src/app/core/user.service';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { ApplicationStateEnum } from 'src/app/models/enums/application-state-enum';
import { LoadDataJobLogEntry } from 'src/app/models/load-data-job-log-entry';
import { ErrorLogComponent } from '../../error-log/error-log.component';
import { WayfindingStageEnum } from 'src/app/models/enums/wayfinding-stage-enum';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {

  public errorLogRef!: MatDialogRef<ErrorLogComponent>;
  public refreshAnnounced!: Observable<void>;
  public stopRefreshInterval: Subject<void> = new Subject();
  public refreshSubscription!: Subscription;
  public get WayfindingStageEnum(): typeof WayfindingStageEnum {
    return WayfindingStageEnum;
  }

  constructor(public jobLogService: JobLogService, public configurationService: ConfigurationService, public fileService: FileService,
    public wayfindingService: WayfindingService, public userService: UserService, public dialog: MatDialog, public contentValidationService: ContentValidationService,
    public refreshService: RefreshService) { }

  ngOnInit(): void {
    this.initRefresh();
  }

  ngOnDestroy() {
    this.refreshService.stopInterval();
    this.refreshSubscription?.unsubscribe();
  }

  async initRefresh() {
    await this.configurationService.getConfiguration().toPromise();
    this.refreshService.startEmitting(this.configurationService.fileRefreshSeconds);
    this.refreshAnnounced = this.refreshService.getObservable();
    this.fileService.getFileValidations();
    this.subscribeToRefresh();
    return Promise.resolve();
  }

  subscribeToRefresh() {
    this.refreshSubscription?.unsubscribe();
    this.refreshSubscription = this.refreshAnnounced?.subscribe(() => {
      this.configurationService.getConfiguration().subscribe();
    });
  }

  shouldDisableNewLoad() {
    return !(this.userService.isAdministrator() || this.userService.isDeveloper()) || this.configurationService.cancelingValidation;
  }

  loadNewData(): void {
    this.contentValidationService.criticalError = false;

    this.jobLogService.createNewJobLogEntry().subscribe((newJobLogEntry: LoadDataJobLogEntry) => {
      this.jobLogService.loadingJobLogEntry = false;
      if (newJobLogEntry) {
        this.jobLogService.clearCurrentJobLog();
        this.configurationService.newLoad(newJobLogEntry.id);
        this.fileService.refreshIncomingFiles();
      }
    })
  }

  viewLoadedLogs(): void {
    this.errorLogRef = this.dialog.open(ErrorLogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'error-log-view'
    });
    this.errorLogRef.componentInstance.loadDataJobLogId = this.configurationService.loadedJobLogId;
  }


  viewCurrentLoadLogs(): void {
    this.errorLogRef = this.dialog.open(ErrorLogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'error-log-view'
    });
    if (this.configurationService.showWayfinding) {
      this.errorLogRef.componentInstance.loadDataJobLogId = this.configurationService.currentJobLogId;
    } else {
      this.errorLogRef.componentInstance.loadDataJobLogId = this.configurationService.canceledJobLogId;
    }
  }

  shouldShowCurrentJob(): boolean {
    return this.configurationService.canceledJobLogId !== null || (this.configurationService.showWayfinding && (!this.jobLogService.currentLoadFinished || this.configurationService.applicationState === ApplicationStateEnum.FAILED));
  }

}
