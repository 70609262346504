<div id="container" [class.container-wayfinding]="configurationService.showWayfinding"
    [class.container-job-log]="configurationService.canceledJobLogId">
    <!-- Loaded Data Header -->
    <mat-toolbar class="loaded-data-details header" [class.wayfinding]=configurationService.showWayfinding
        [class.flex]="configurationService.canceledJobLogId" [ngStyle] = "{'height.px': wayfindingService.height}">
        <span class="load-info">
            <div class="loaded-header load-info-spacing">
                <span id="loaded-data-title" class="info-title">LOADED DATA DETAILS &nbsp;|</span>&nbsp;
                <span id="loaded-data-view-logs-btn" class="loaded-view-logs-btn info-title" (click)="viewLoadedLogs()">VIEW LOGS</span>
            </div>
            <div class="loaded-start load-info-spacing">
                <span id="loaded-started-title" class="info-title">Started: </span>
                <div [class.hidden]="!jobLogService.loadingLoadedJobLogEntry"
                    [hidden]="!jobLogService.loadingLoadedJobLogEntry" class="placeholder animated">
                </div>
                <span id="loaded-started-value" [class.hidden]="jobLogService.loadingLoadedJobLogEntry"
                    class="info-detail">{{jobLogService.loadStarted}}</span>
            </div>
            <div class="loaded-finished load-info-spacing">
                <span id="loaded-finished-title" class="info-title">Finished: </span>
                <div [class.hidden]="!jobLogService.loadingLoadedJobLogEntry"
                    [hidden]="!jobLogService.loadingLoadedJobLogEntry" class="placeholder animated">
                </div>
                <span id="loaded-finished-value" [class.hidden]="jobLogService.loadingLoadedJobLogEntry"
                    class="info-detail">{{jobLogService.loadFinished}}</span>
            </div>
            <div class="loaded-validation-duration load-info-spacing">
                <span id="loaded-validation-duration-title" class="info-title">Validation Duration: </span>
                <div [class.hidden]="!jobLogService.loadingLoadedJobLogEntry"
                    [hidden]="!jobLogService.loadingLoadedJobLogEntry" class="placeholder animated">
                </div>
                <span id="loaded-validation-duration-value" [class.hidden]="jobLogService.loadingLoadedJobLogEntry"
                    class="info-detail">{{jobLogService.validationDuration}}</span>
            </div>
            <div class="loaded-fhir-duration load-info-spacing">
                <span id="loaded-fhir-duration-title" class="info-title">FHIR Server Load Duration: </span>
                <div [class.hidden]="!jobLogService.loadingLoadedJobLogEntry"
                    [hidden]="!jobLogService.loadingLoadedJobLogEntry" class="placeholder animated">
                </div>
                <span id="loaded-fhir-duration-value" [class.hidden]="jobLogService.loadingLoadedJobLogEntry"
                    class="info-detail">{{jobLogService.fhirServerLoadDuration}}</span>
            </div>
        </span>
    </mat-toolbar>
    <div *ngIf="shouldShowCurrentJob(); then currentLoadHeader">
    </div>

    <div *ngIf="configurationService.showWayfinding; then showWayfinding else load"></div>
    <!-- Load Data Button -->
    <ng-template #load>
        <mat-toolbar class="load-header header">
            <button mat-stroked-button id="load-new-data-btn" class="load-data-btn" (click)="loadNewData()"
            [disabled]="shouldDisableNewLoad()">
                <span [hidden]="configurationService.cancelingValidation">Load New Data</span>
                <span [hidden]="!configurationService.cancelingValidation">Canceling ...</span>
            </button>
        </mat-toolbar>
    </ng-template>
    <!-- Wayfinding -->
    <ng-template #showWayfinding>
        <app-wayfinding [stopRefreshInterval]="stopRefreshInterval"></app-wayfinding>
    </ng-template>
</div>
<div>
    <app-data-staging *ngIf="configurationService.showWayfinding && configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_DATA"></app-data-staging>
    <app-content-validation *ngIf="configurationService.showWayfinding && configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_CONTENT && refreshAnnounced"
    [stopRefreshInterval]="stopRefreshInterval"
    [refreshAnnounced]="refreshAnnounced"></app-content-validation>
    <app-fhir-validation *ngIf="configurationService.showWayfinding && configurationService.currentWayfindingStage === WayfindingStageEnum.VALIDATE_FHIR_BUNDLES && refreshAnnounced"
    [stopRefreshInterval]="stopRefreshInterval"
    [refreshAnnounced]="refreshAnnounced"></app-fhir-validation>
    <app-load-fhir *ngIf="configurationService.showWayfinding && configurationService.currentWayfindingStage === WayfindingStageEnum.LOAD_BUNDLES && refreshAnnounced"
    [stopRefreshInterval]="stopRefreshInterval"
    [refreshAnnounced]="refreshAnnounced"></app-load-fhir>
</div>

<!-- Current Load Header -->
<ng-template #currentLoadHeader>
    <mat-toolbar class="new-load-details new-load-header" [ngStyle] = "{'height.px': wayfindingService.height}">
        <span class="load-info">
            <div class="loaded-header load-info-spacing">
                <span [hidden]="!configurationService.showWayfinding">
                    <span id="new-load-data-title" class="info-title"><span [hidden]="!jobLogService.currentJobError">FAILED</span><span [hidden]="jobLogService.currentJobError">NEW</span> LOAD DETAILS &nbsp;|</span>&nbsp;
                    <span class="loaded-view-logs-btn info-title" (click)="viewCurrentLoadLogs()">VIEW LOGS</span>
                </span>
                <span [hidden]="!configurationService.canceledJobLogId">
                    <span id="canceled-load-data-title" class="info-title"><span [hidden]="!jobLogService.currentJobError">FAILED</span><span [hidden]="jobLogService.currentJobError">CANCELED</span> LOAD DETAILS &nbsp;|</span>&nbsp;
                    <span
                        id="current-view-logs-btn" class="loaded-view-logs-btn info-title" (click)="viewCurrentLoadLogs()">VIEW LOGS</span>
                </span>
            </div>
            <div class="loaded-start load-info-spacing">
                <span id="current-started-title" class="info-title">Started: </span>
                <div [class.hidden]="!jobLogService.loadingJobLogEntry" [hidden]="!jobLogService.loadingJobLogEntry"
                    class="placeholder animated">
                </div>
                <span id="current-started-value" [class.hidden]="jobLogService.loadingJobLogEntry"
                    class="info-detail">{{jobLogService.currentLoadStarted}}</span>
            </div>
            <div class="loaded-finished load-info-spacing">
                <span id="current-finished-title" class="info-title">Finished: </span>
                <div [class.hidden]="!jobLogService.loadingJobLogEntry" [hidden]="!jobLogService.loadingJobLogEntry"
                    class="placeholder animated">
                </div>
                <span id="current-finished-value" [class.hidden]="jobLogService.loadingJobLogEntry"
                    class="info-detail">{{jobLogService.currentLoadFinished}}</span>
            </div>
            <div class="loaded-validation-duration load-info-spacing">
                <span id="current-validation-duration-title" class="info-title">Validation Duration: </span>
                <div [class.hidden]="!jobLogService.loadingJobLogEntry" [hidden]="!jobLogService.loadingJobLogEntry"
                    class="placeholder animated">
                </div>
                <span id="current-validation-duration-value" [class.hidden]="jobLogService.loadingJobLogEntry"
                    class="info-detail">{{jobLogService.currentValidationDuration}}</span>
            </div>
            <div class="loaded-fhir-duration load-info-spacing">
                <span id="current-fhir-duration-title" class="info-title">FHIR Server Load Duration: </span>
                <div [class.hidden]="!jobLogService.loadingJobLogEntry" [hidden]="!jobLogService.loadingJobLogEntry"
                    class="placeholder animated">
                </div>
                <span id="current-fhir-duration-value" [class.hidden]="jobLogService.loadingJobLogEntry"
                    class="info-detail">{{jobLogService.currentFhirServerLoadDuration}}</span>
            </div>
        </span>
    </mat-toolbar>
</ng-template>