import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuditEntry } from '../../models/audit-entry';
import { MsalService } from '@azure/msal-angular';
import { WindowRefService } from '../window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user!: string;
  // tslint:disable-next-line:max-line-length
  public unauthorizedMessage = 'The system doesn’t recognize your credentials.';
  token = null;
  constructor(
    private http: HttpClient,
    public msalService: MsalService,
    private windowRefService: WindowRefService) {
    }

  set userName(username: string) {
    this._user = username;
  }

  get userName(): string {
    return this._user;
  }

  login() {
    this.loginButtonClicked = true;
    return this.msalService.loginRedirect();
  }

  get loginEventSent() {
    return !!localStorage.getItem('sentLoginEvent');
  }

  set loginEventSent(value: boolean) {
    if (value) {
      localStorage.setItem('sentLoginEvent', value.toString());
    } else {
      localStorage.removeItem('sentLoginEvent');
    }
  }

  get loginButtonClicked() {
    return !!localStorage.getItem('clickedloginButton');
  }

  set loginButtonClicked(value: boolean) {
    if (value) {
      localStorage.setItem('clickedloginButton', value.toString());
    } else {
      localStorage.removeItem('clickedloginButton');
    }
  }

  public checkAuthorized(): Observable<any> {
    return this.http.get(`${environment.api.base}${environment.api.endpoints.login}/authorized`);
  }

  public handleUnauthorizedAccess() {
    if (this.windowRefService.nativeWindow.agTimeoutCount !== 0) {
      this.msalService.instance.getConfiguration().auth.postLogoutRedirectUri = `${location.origin}/login?unauthorized=true`
    }
    this.msalLogout();
  }

  public logout(): void {
    this.loginButtonClicked = false
    this.loginEventSent = false;
    this.sendLogoutAuditEntry(this.userName).subscribe(() => {
        this.logoutApi().subscribe(() => {
            this.msalLogout();
        }, () => {
            this.msalLogout();
        });
    });
  }

  public msalLogout() {
    // Needed for silent logout
    // Azure Portal -> App Registration -> Token Configuration -> Add Optional Claim -> ID -> login_hint
    this.msalService.logoutRedirect({ logoutHint: this.msalService.instance.getAllAccounts()[0].idTokenClaims!.login_hint }).subscribe();
  }


  acquireToken(): Observable<any> {
    const authReq = {
        scopes: [`${environment.auth.clientId}/.default`],
        account: this.msalService.instance.getAllAccounts()[0],
    };
    return from(this.msalService.instance.acquireTokenSilent(authReq));
  }

  isUserLoggedIn(): Boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  private sendLogoutAuditEntry(username: string): Observable<AuditEntry> {
    let auditEntry = new AuditEntry();
    auditEntry.user = username;
    auditEntry.event = 'Logout';

    const url = `${environment.api.base}${environment.api.endpoints.audit}`;
    let options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.http.post<any>(url, auditEntry, options);
}

  private logoutApi(): Observable<any> {
    const url = `${environment.api.base}${environment.api.endpoints.logout}`;
    return this.http.post<any>(url, { responseType: 'text' });
  }

}
