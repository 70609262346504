import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-run-name-dialog',
  templateUrl: './edit-run-name-dialog.component.html',
  styleUrls: ['./edit-run-name-dialog.component.scss']
})
export class EditRunNameDialogComponent implements OnInit {

  public runName: String = '';
  public savedRunNames: Set<String> = new Set([]);
  public editForm!: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditRunNameDialogComponent>,
    private fb: UntypedFormBuilder) {
    this.runName = data.runName;
    this.savedRunNames = data.savedRunNames;
    this.createForm();
  }

  private isForbidden(forbiddenValues: Set<String>): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (forbiddenValues && c.value && forbiddenValues.has(c.value.trim().toUpperCase())) {
        return { 'forbiddenValues': true };
      }
      return null;
    };
  }
  createForm() {
    this.editForm = this.fb.group({
      name: [this.runName, [Validators.required, Validators.maxLength(256), this.isForbidden(this.savedRunNames)]]
    });
  }

  ngOnInit() {
  }

  okay(): void {
    this.editForm.markAllAsTouched();
    if (this.editForm.status === 'VALID') {
      this.closeDialog(true);
    }
  }

  closeDialog(result: boolean) {
    this.dialogRef.close({ result: result, runName: this.runName });
  }

}

