import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatHeaderCell, MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { UserService } from 'src/app/core/user.service';
import { User } from 'src/app/models/user';
import { ComparatorService } from 'src/app/shared/comparator.service';
import { ManageUserCheatSheetComponent } from '../manage-user-cheat-sheet/manage-user-cheat-sheet.component';
import { rowsAnimation, listAnimation } from '../../shared/animations/table-row-animation';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataService } from 'src/app/core/data.service';
import { ManageUserDialogComponent } from '../manage-user-dialog/manage-user-dialog.component';
import { ViewerPipe } from 'src/app/shared/pipes/viewer.pipe';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  animations: [rowsAnimation, listAnimation]
})
export class ManageUsersComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll!: CdkVirtualScrollViewport;

  public cheatSheetDialogRef!: MatDialogRef<ManageUserCheatSheetComponent>;
  public displayedColumns: string[] =
    ['first_name', 'last_name', 'email_id', 'user_id', 'role', 'last_login', 'active'];
  public users: User[] = [];
  public skeletonList: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  public userDataSource: MatTableDataSource<User> = new MatTableDataSource(this.skeletonList);
  manageUsersRef!: MatDialogRef<ManageUserDialogComponent>;
  public newUser!: User;
  public viewerPipe: ViewerPipe = new ViewerPipe();
  public loadingTable: boolean = false;
  constructor(public userService: UserService, public snackBar: SnackbarService, public dialog: MatDialog, public comparatorService: ComparatorService, public dataService: DataService) { }

  ngOnInit(): void {
    this.loadingTable = true;
    this.refreshSource();
  }

  defaultSort(users: User[]) {
    this.users = users.filter(u => u.role !== "Developer").sort((a: User, b: User) => { return a.first_name.localeCompare(b.first_name)})
    .concat(users.filter(u => u.role === "Developer").sort((a: User, b: User) => { return a.first_name.localeCompare(b.first_name)}));
    this.userDataSource.data = this.users;
  }

  refreshSource() {
    this.dataService.getUsers().subscribe(users => {
      if(users !== null) {
        this.defaultSort(users);
      }
      this.loadingTable = false;
    });
  }


  addUsers(): void {
    let newUser = new User();
    newUser.active = true;
    newUser.created_at = new Date();
    newUser.first_name = '';
    newUser.last_name = '';
    newUser.editable = true;
    this.manageUsersRef = this.dialog.open(ManageUserDialogComponent, {
      autoFocus: false,
      disableClose: true
    });
    this.manageUsersRef.componentInstance.user = newUser;
    this.manageUsersRef.componentInstance.title = 'Add a User';
    this.manageUsersRef.componentInstance.emails = this.users.map(function (a) { return a.email_id; });
    this.manageUsersRef.componentInstance.userIds = this.users.map(function (a) { return a.user_id; });
    this.manageUsersRef.afterClosed().subscribe((user) => {
      if (user) {
        this.newUser = user;
        this.dataService.updateUsers([user]).subscribe((users) => {
          this.users.unshift(users[0]);
          this.userDataSource.data = this.users;
          this.userDataSource.filter = '';
        }, () => {
          this.snackBar.showNegativeBar('There was a problem saving users. Please try again.');
        });
      }
    });
  }

  sortData(sort: Sort) {
    this.userDataSource = this.comparatorService.sortUserData(sort, this.userDataSource);
  }

  editUser(row: User) {
    this.manageUsersRef = this.dialog.open(ManageUserDialogComponent, {
      autoFocus: false,
      disableClose: true
    });
    let editUser = Object.assign({}, JSON.parse(JSON.stringify(row)));
    editUser.editable = false;
    this.manageUsersRef.componentInstance.user = editUser;
    this.manageUsersRef.componentInstance.title = 'Edit User';
    this.manageUsersRef.componentInstance.emails = this.users.filter(u =>
      u.email_id !== editUser.email_id).map(function (a) { return a.email_id; });
    this.manageUsersRef.componentInstance.userIds = this.users.filter(u =>
      u.user_id !== editUser.user_id).map(function (a) { return a.user_id; });
      this.manageUsersRef.afterClosed().subscribe((user) => {
        if (user) {
          this.dataService.updateUsers([user]).subscribe((users) => {
            this.users = this.users.map(u => {
              if(u.user_id === users[0].user_id) {
                return users[0]
              } else {
                return u
              }
            });
            this.userDataSource.data = this.users;
            this.userDataSource.filter = '';
          }, () => {
            this.snackBar.showNegativeBar('There was a problem saving the user. Please try again.');
          });
        }
      });
  }

  isNewUser(user: User) {
    return user === this.newUser;
  }

  public cheatSheet(): void {
    this.cheatSheetDialogRef = this.dialog.open(ManageUserCheatSheetComponent, {
      autoFocus: false,
      disableClose: true,
    });
  }

}
