export class User {
    id!: number;
    user_id: string = '';
    first_name: string = '';
    last_name: string = '';
    full_name: string = '';
    email_id: string = '';
    role: string = '';
    created_at: Date = new Date();
    active: boolean = false;
    last_login = null;
    editable: boolean = true;
}
