import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ComponentService } from 'src/app/core/component.service';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DataService } from 'src/app/core/data.service';
import { FileUploadService } from 'src/app/core/file-upload.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { UserService } from 'src/app/core/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  SHOW_NCQA_MODE_HEADER: boolean = environment.showNCQAModeHeader;

  constructor(public authService: AuthService, public userService: UserService, public componentService: ComponentService,
    public fileUploadService: FileUploadService, public snackBarService: SnackbarService, public dataService: DataService, public configurationService: ConfigurationService) { }

  ngOnInit(): void {
  }

  logout() {
    localStorage.removeItem('navOpen');
    localStorage.removeItem('selectedComponent');
    this.authService.logout();
  }

  manageMeasurePackages() {
    this.componentService.displayMangeOrganizationInformationComponent = false;
    this.componentService.displayManageUsersComponent = false;
    this.componentService.displayManageMeasurePackageComponent = true;
  }

  manageOrganizationInformation() {
    this.componentService.displayManageMeasurePackageComponent = false;
    this.componentService.displayManageUsersComponent = false;
    this.componentService.displayMangeOrganizationInformationComponent = true;
  }

  manageUsers() {
    this.componentService.displayManageMeasurePackageComponent = false;
    this.componentService.displayMangeOrganizationInformationComponent = false;
    this.componentService.displayManageUsersComponent = true;
  }

  // Upload pilot files

  // TODO: Improve UX - add progress bar, messaging, upload sub-menu, etc
  onUploadClick(fileInput: HTMLElement) {
    fileInput.click();
  }

  uploadMmdfFile(event: any) {
    this.uploadFile(event, 'mmdf');
  }

  uploadLishistFile(event: any) {
    this.uploadFile(event, 'lishist');
  }

  async uploadFile(event: any, filetype: string) {
    let fileList: FileList = event.target.files;
    if (!fileList || fileList.length === 0 ) {
      return;
    }
    for (let index = 0; index < fileList.length; index++) {
      if (fileList[index].size === 0) {
        this.snackBarService.showInfoBar('File: ' + fileList[index].name + ' did not contain any data. Please modify your selection and try the upload again.')
        return;
      }
    }
      for (let index = 0; index < fileList.length; index++) {
        console.log('Uploading ' + filetype + ' file ' + (index + 1) + ' of ' + fileList.length);
        await this.validateUploadedFile(fileList[index], filetype);
      }
  }

  async validateUploadedFile(file: File, filetype: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (file && file.name) {
        this.fileUploadService.validateFile(file, filetype).subscribe(_ => {
          resolve();
        }, err => {
          this.snackBarService.showInfoBar(err);
          reject();
        });
      } else {
        this.snackBarService.showInfoBar('Please upload at lease one file');
        reject();
      }
    });
  }
}
