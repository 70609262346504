<div *ngIf="failedValidation; then failed else upload"></div>

<ng-template #upload>
    <h1 class="title">Upload Measure Package</h1>

    <button mat-stroked-button type="button" (click)="fileInput.click()" id="inputBtn" [hidden]="uploading">
        <img id="uploadIcon" src="../../../../assets/images/upload.svg" class="icon">
        <span [hidden]="selectedFile">Choose a measure package file (.zip)</span>
        <span id="filename" [hidden]="!selectedFile">{{selectedFile?.name}}</span>
        <input id="filename-input" #fileInput type="file" (change)="onChange($event.target!)" style="display:none;" />
    </button>

    <mat-spinner [hidden]="!uploading" id="matSpinner"></mat-spinner>

    <div class="button-container">
        <button mat-stroked-button id="cancelBtn" (click)="cancel()">
            Cancel
        </button>
        <button mat-stroked-button id="uploadBtn" [hidden]="uploading" (click)="uploadFile()"
            [disabled]="!selectedFile">
            <span>Upload</span>
        </button>
    </div>
</ng-template>

<ng-template #failed>
    <div class="failed-container">
        <h1 id="error-title">
            <img id="errorIcon" src="../../../../assets/images/alert-icon.svg">
            Upload Failed
        </h1>
        <p id="error-msg">
            The measure package upload failed validation due to the following error(s):
        </p>
        <div *ngFor="let error of validationErrors">
            <li id="validation-errors">{{error}}</li>
        </div>
        <button mat-stroked-button id="okayBtn" (click)="failedValidation = false;">
            Okay
        </button>
    </div>
</ng-template>