export class ConfigurationEntry {
    id: number;
    client: string;
    organizationName: string = '';
    organizationId: string = '';
    cmsContractNumber: string = '';
    executingRunJobLogId: number | null;
    loadedJobLogId: number | null;
    applicationState: string;
    currentWayfindingStage: string;
    showWayfinding: boolean;
    cancelingValidation!: boolean;
    canceledJobLogId: number | null;
    currentJobLogId: number | null;
    errorMessage!: string;
    clientTimezone!: string;
    totalResources!: number;
    processedResources!: number;
    loadedBundles!: number;
    failedBundles!: number;
    bundlesToLoad!: number;
    ncqaMode: boolean;
    fileRefreshSeconds: number;
    executeRunRefreshSeconds: number;

    constructor(id: number, client: string, executingRunJobLogId: number | null, loadedJobLogId: number | null, 
        applicationState: string, currentWayfindingStage: string, 
        showWayfinding: boolean, canceledJobLogId: number | null, currentJobLogId: number | null,
        errorMessage: string, clientTimezone: string, totalResources: number, processedResources: number, loadedBundles: number, failedBundles: number,
        ncqaMode: boolean, fileRefreshSeconds:number, executeRunRefreshSeconds:number) {
        this.id = id;
        this.client = client;
        this.executingRunJobLogId = executingRunJobLogId;
        this.loadedJobLogId = loadedJobLogId;
        this.applicationState = applicationState;
        this.currentWayfindingStage = currentWayfindingStage;
        this.showWayfinding = showWayfinding;
        this.canceledJobLogId = canceledJobLogId;
        this.currentJobLogId = currentJobLogId;
        this.errorMessage = errorMessage;
        this.clientTimezone = clientTimezone;
        this.totalResources = totalResources;
        this.processedResources = processedResources;
        this.loadedBundles = loadedBundles;
        this.failedBundles = failedBundles;
        this.ncqaMode = ncqaMode;
        this.fileRefreshSeconds = fileRefreshSeconds;
        this.executeRunRefreshSeconds = executeRunRefreshSeconds;
    }
}