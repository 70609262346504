import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }


  exportArrayAsCSV(data: any[], fileName: string, overrideHeader?: string[]) {
    fileName = fileName.replace(/\s/g, '');
    let blob;
    if (data.length > 0) {
      const replacer = (_key: any, value: any) => value = this.parseTerm(value);
      let header = Object.keys(data[0]);
      if (overrideHeader) {
        header = overrideHeader;
      }
      const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');
      blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    } else {
      blob = new Blob([], { type: 'text/csv;charset=utf-8' });
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  exportXmlFile(data: string, fileName: string) {
    fileName = fileName.replace(/\s/g, '');
    let blob;
    if (data && data.length > 0) {
      blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
    } else {
      blob = new Blob([], { type: 'text/xml;charset=utf-8' });
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  parseTerm(term: any) {
    if (term === undefined || term === null) {
      term = '';
    }
    if (term.type) {
      term = term.type;
    } else if (term.full_name) {
      term = term.full_name;
    } else if (term.status) {
      term = term.status;
    }
    return term;
  }
}
