import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { JobLogService } from 'src/app/core/job-log.service';
import { LoadingService } from 'src/app/core/loading.service';
import { FileService } from 'src/app/core/file.service';
import { ComponentEnum } from 'src/app/models/enums/component-enum';
import { WayfindingService } from 'src/app/core/wayfinding.service';
import { ComponentService } from 'src/app/core/component.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(public jobLogService: JobLogService, public configurationService: ConfigurationService, 
    public loadingService: LoadingService, public wayfindingService: WayfindingService, public componentService: ComponentService) { }

  ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe(() => {
      this.jobLogService.getLoadedDataJobLogEntry();
      
      if (this.configurationService.currentJobLogId){
        this.jobLogService.loadingJobLogEntry = true;
        this.jobLogService.getCurrentLoadDataJobLogEntry(this.configurationService.currentJobLogId);
      } else if (this.configurationService.canceledJobLogId) {
        this.jobLogService.loadingJobLogEntry = true;
        this.jobLogService.getCurrentLoadDataJobLogEntry(this.configurationService.canceledJobLogId);
      }
      this.wayfindingService.refreshMessaging();
    });
    this.componentService.initializeComponentState();
  }

  public shouldDisplaySpinner(): boolean {
    return this.loadingService.shouldShowLoadingElements() || this.loadingService.validating
  }

}
