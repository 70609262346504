import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WayfindingStageEnum } from '../models/enums/wayfinding-stage-enum';
import { HeaderValidationResponse } from '../models/header-validation-response';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { JobLogService } from './job-log.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ContentValidationService {

  public criticalError = false;
  public contentValidationError = false;

  constructor(public dataService: DataService, public jobLogService: JobLogService, public configurationService: ConfigurationService,
    public snackbar: SnackbarService) { }

  public validateHeader(): Observable<any> {
    return new Observable(s => {
      this.dataService.validateHeader(this.configurationService.currentJobLogId!).subscribe((responses) => {
        this.configurationService.getConfiguration().subscribe(() => {
          responses!.forEach((response: HeaderValidationResponse) => {
            if (response.missing_columns > 0) {
              this.criticalError = true;
            }
          }, (error: any) => {
            this.snackbar.showNegativeBar(error);
            s.error(error);
          });
          s.next()
        });
      });
    });
  }
}
