import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RunConfiguration } from 'src/app/models/run-configuration';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/core/data.service';
import { ExecuteRunRow } from 'src/app/models/execute-run-row';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeasureRunSnackbarComponent } from 'src/app/shared/snackbars/measure-run-snackbar/measure-run-snackbar.component';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { ApplicationStateEnum } from 'src/app/models/enums/application-state-enum';
import { RunConfigService } from 'src/app/core/run-config.service';
import { MeasureExecution } from 'src/app/models/measure-execution';
import { DateService } from 'src/app/core/date.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ModalType } from 'src/app/models/enums/modal-type-enum';
import { LogTypeEnum } from 'src/app/models/enums/log-type-enum';
import { ErrorLogResponse } from 'src/app/models/error-log-response';
import { ExportService } from 'src/app/core/export.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { UserService } from 'src/app/core/user.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-execute-run',
  templateUrl: './execute-run.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./execute-run.component.scss']
})
export class ExecuteRunComponent implements OnInit {

  @Input() public runConfig!: RunConfiguration;
  @Input() public refreshAnnounced!: Observable<void>;
  
  public refreshSubscription!: Subscription;
  public displayedColumns = ['lineOfBusiness', 'measureName', 'productLine', 'spacer'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public loading: boolean = false;
  public running: boolean = false;
  public canceling: boolean = false;


  constructor(public dataService: DataService, private _snackBar: MatSnackBar, public configurationService: ConfigurationService,
    private changeDetectorRef: ChangeDetectorRef, public runConfigService: RunConfigService, public dateService: DateService,
    public dialogService: DialogService, public exportService: ExportService, public snackBarService: SnackbarService,
    public userService: UserService) { }

  openSnackBar() {
    this._snackBar.openFromComponent(MeasureRunSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 7000
    });
  }



  ngOnInit(): void {
    this.runConfigService.loadFilterOptions();
    this.changeDetectorRef.markForCheck();
    this.getRunMeasureStatus();
    this.subscribeToRefresh();
    if (this.runConfig.executeRunJobLogEntityList.filter(f => f.id === this.configurationService.executingRunJobLogId)[0]) {
      this.running = true;
    }
  }

  subscribeToRefresh() {
    this.refreshSubscription = this.refreshAnnounced.subscribe(async () => {
      await this.configurationService.getConfiguration().toPromise();
      if (this.isFinished()) {
        this.running = false;
        this.refreshSubscription.unsubscribe();
      } else {
      this.getRunMeasureStatus();
      }
    });
  }

  ngOnDestroy(){
    this.refreshSubscription.unsubscribe();
  }

  isFinished(){
    return this.configurationService.executingRunJobLogId === null && this.configurationService.applicationState !== ApplicationStateEnum.RUNNING;
  }
  getRunMeasureStatus() {
    this.loading = true;
    this.dataService.getRunMeasureStatus(this.runConfig.id, this.configurationService.executingRunJobLogId!).subscribe((measureExecutions: MeasureExecution[] | null) => {
      let rows: ExecuteRunRow[] = [];
      this.dataService.getExecuteRunTable(this.runConfig.id).subscribe(response => {
        response?.forEach(row => {
          Object.keys(row['Products']).forEach(productKey => {
            if (row['Products'][productKey].length > 0) {
              row['Products'][productKey].forEach((product: string) => {
                let executeRow = new ExecuteRunRow(row.measure_name, productKey, product);
                let measureId = this.runConfigService.measureIdMap.get(row.measure_name);
                let productId = this.runConfigService.productIdMap.get(product);
                let measureExecution = measureExecutions?.find(m => +m.measureRecordId === +measureId! && +m.productRecordId === +productId!);
                if (measureExecution) {
                  executeRow.error = measureExecution.error;
                  executeRow.finished = measureExecution.finished;
                }
                rows.push(executeRow);
              });
            }
          });
        });
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      });
      this.dataSource = new MatTableDataSource(rows);
    });
  }


  shouldDisableRunMeasuresButton() {
    return (!this.configurationService.loadedJobLogId ||
      this.configurationService.applicationState === ApplicationStateEnum.FAILED || this.configurationService.applicationState === ApplicationStateEnum.LOAD_BUNDLES
      || this.configurationService.applicationState === ApplicationStateEnum.RUNNING) && !this.running;
  }

  toggleRunMeasures(): void {
    if (!this.running) {
      this.running = true;
      this.openSnackBar();
      this.dataService.executeRun(this.runConfig.id).subscribe(() => {
        this.configurationService.getConfiguration().subscribe();
        this.refreshSubscription?.unsubscribe();
        this.subscribeToRefresh();
      });
    } else {
      let modalTitle = 'Cancel Run?';
      let modalMessage = 'You\'re about to cancel this run. if you proceed, the system will discard all records related to this run. If you\'d like to retain data on this run for troubleshooting, download logs now. Changed your mind? Click <b>Don\'t cancel</b> to return to the Execute Run screen.'
      let primaryBtnText = 'Proceed and download logs';
      let secondaryBtnText = 'Proceed';
      let thirdBtnText = 'Don\'t cancel';
      this.dialogService.openModal(ModalType.WARN, modalTitle, modalMessage, primaryBtnText, secondaryBtnText, () => {
        this.canceling = true;
        this.running = false;
        this.changeDetectorRef.markForCheck();
        this.dataService.cancelRun(this.runConfig.id).subscribe(() => {
          this.configurationService.executingRunJobLogId = null;
          this.downloadRunLogFiles(this.configurationService.executingRunJobLogId);
          this.changeDetectorRef.markForCheck();
          this.configurationService.getConfiguration().subscribe(() => {
            this.runConfigService.executeRun = false;
            this.runConfigService.loadRunConfigurations();
          }, () => {
            this.runConfigService.executeRun = false;
            this.runConfigService.loadRunConfigurations();
          });
        }, () => {
          this.canceling = false;
          this.running = true;
          this.snackBarService.showNegativeBar('Error canceling run. Please wait 30 seconds and try again.');
          this.changeDetectorRef.markForCheck();
        });
      }, () => {
        this.canceling = true;
        this.running = false;
        this.changeDetectorRef.markForCheck();
        this.dataService.cancelRun(this.runConfig.id).subscribe(() => {
          this.configurationService.executingRunJobLogId = null;
          this.changeDetectorRef.markForCheck();
          this.configurationService.getConfiguration().subscribe(() => {
            this.runConfigService.executeRun = false;
            this.runConfigService.loadRunConfigurations();
          }, () => {
            this.runConfigService.executeRun = false;
            this.runConfigService.loadRunConfigurations();
          });
        }, () => {
          this.canceling = false;
          this.running = true;
          this.snackBarService.showNegativeBar('Error canceling run. Please wait 30 seconds and try again.');
          this.changeDetectorRef.markForCheck();
        });
      }, thirdBtnText, () => {
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  downloadRunLogFiles(executeRunJobLogId: number | null): void {
    this.dataService.downloadErrorLogs(executeRunJobLogId, LogTypeEnum.EXECUTE_RUN).subscribe((response: ErrorLogResponse[] | null) => {
      let totalLogs = 0;
      if (response && response.length) {
        const logs: any[] = [];
        totalLogs = response[0].total_record_count;
        response.forEach((res) => {
          res.error_logs.forEach((log) => {
            logs.push({ 'Error Logged At': log.createdAt, 'Member ID': log.identifier, 'Measure': log.measure, 'Error Message': log.errorMessage });
          });
        });
        if (logs.length > 0) {
          this.exportService.exportArrayAsCSV(logs, 'ErrorLogs');
        }
      }
    });
  }

  sortData(sort: Sort) {
    this.dataSource = new MatTableDataSource(this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    }));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      a = '';
    }
    if (b === null) {
      b = '';
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


}
