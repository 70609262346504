import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorLogComponent } from './error-log/error-log.component';
import { ManageMeasurePackageComponent } from './manage-measure-package/manage-measure-package.component';
import { ManageOrganizationInformationComponent } from './manage-organization-information/manage-organization-information.component';

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: '', component: ManageMeasurePackageComponent },
      { path: '', component: ManageOrganizationInformationComponent },
      { path: '', component: ErrorLogComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrameworkRoutingModule {
}
