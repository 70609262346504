import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { SnackbarService } from 'src/app/core/snackbar.service';
import { MemberLevelResult } from 'src/app/models/member-level-result';
import { ComparatorService } from 'src/app/shared/comparator.service';

@Component({
  selector: 'app-run-results-member-level',
  templateUrl: './run-results-member-level.component.html',
  styleUrls: ['./run-results-member-level.component.scss']
})
export class RunResultsMemberLevelComponent implements OnInit {
  @ViewChild("summaryPaginator") summaryPaginator!: MatPaginator;


  @Input() selectedRunLog: any;
  @Input() exportClicked!: EventEmitter<any>;

  @Output() exportData = new EventEmitter<any>();

  skeletonList: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

  loadingRunResults: boolean = true;
  memberLevelDataSource: MatTableDataSource<MemberLevelResult> = new MatTableDataSource(this.skeletonList);
  memberLevelDisplayedColumns: string[] = ["memberId", "age", "gender", "productLine", "product", "measure", "subMeasureId", "measureStratification", "race", "ethnicity", "recordIdentifier", "runId", "birthDate", "payerStratification", "event", "eventDate", "eligiblePopulation", "spokenLanguage", "numerator", "denominator", "requiredExclusion", "optionalExclusion", "initialPopulation", "continuousEnrollment", "participationPeriodStart", "participationPeriodEnd", "hedisYear", "numeratorInclusionDate", "numeratorInclusionFhirId", "requiredExclusionDate", "requiredExclusionFhirId"];
  memberLevelTotalRows?: number;
  memberLevelSelectedPageNumber: number = 1;
  memberLevelSelectedPageSize: number = 100;
  memberLevelSort?: Sort;
  exportSubscription?: Subscription;

  constructor(public dataService: DataService, public comparatorService: ComparatorService, public snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.getMemberLevelData(this.selectedRunLog.id, this.memberLevelSelectedPageNumber, this.memberLevelSelectedPageSize);

    this.exportSubscription = this.exportClicked?.subscribe(() => this.onExportData());
  }

  ngOnDestroy() {
    this.exportSubscription?.unsubscribe();
  }
 
  onExportData(): any {
    return this.exportData?.emit();
  }

  onMemberLevelDataPaginateChange(event: PageEvent): void {
    this.memberLevelDataSource = new MatTableDataSource(this.skeletonList);
    if (event) {
      this.memberLevelSelectedPageNumber = event.pageIndex + 1;
      this.memberLevelSelectedPageSize = event.pageSize;
    }
    this.getMemberLevelData(this.selectedRunLog.id, this.memberLevelSelectedPageNumber, this.memberLevelSelectedPageSize);
  }

  getMemberLevelData(jobId: number, pageNumber: number, pageSize: number) {
    this.dataService.getMemberLevelData(jobId, pageNumber, pageSize).subscribe({
      next: (data) => {
        this.memberLevelDataSource = new MatTableDataSource(data!.runResultMemberLevelRecords);
        this.memberLevelTotalRows = data!.totalRows;
        this.loadingRunResults = false;
      },
      error: () => {
        this.loadingRunResults = false;
        this.memberLevelDataSource = new MatTableDataSource();
        this.snackbarService.showNegativeBar("There was a problem getting member level results. Please try again.");
      }

    });
  }

  sortMemberLevelData(sort: Sort) {
    this.memberLevelSort = sort;
    this.memberLevelDataSource = new MatTableDataSource(this.memberLevelDataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.comparatorService.compare(a[sort.active as keyof MemberLevelResult], b[sort.active as keyof MemberLevelResult], isAsc);
    }));
  }

}
